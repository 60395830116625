import Api from "./Api";
import JsonApi from "./JsonApi";
import authHeader from "./AuthHeader";

const getCardsData = () => {
  return JsonApi().get("/auth/getAdminDashboardCardData/", {
    headers: authHeader(),
  });
};

const AdminService = { getCardsData };

export default AdminService;
