import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import UserService from "../../../../services/UserService";
import { snackBarAction } from "../../../../store/features/snackBarSlice";
import { authAction } from "../../../../store/features/authSlice";
import UserProfileCard from "../../../../components/card/UserProfileCard";

import { Button, TextField } from "@mui/material";

const schema = yup.object({
  mobileNumber: yup
    .string()
    .required("Required field")
    .matches(/^\d{10}$/, "Invalid phone number"),
});

type FormData = yup.InferType<typeof schema>;

const ContactInformation = (props: any) => {
  const { user, data, getData } = props;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const [isShown, setIsShown] = useState(false);

  const handleClick = () => {
    setIsShown(!isShown);
    reset();
  };

  const handleUpdateUserContact = handleSubmit(async (data) => {
    try {
      await UserService.updateUserMobileNumber(data.mobileNumber);
      getData();
      handleClick();
      dispatch(
        snackBarAction.open({
          message: "Contact infomation has been successfully updated",
          severity: "success",
        })
      );
    } catch (e: any) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  });

  useEffect(() => {
    if (data) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          mobileNumber: data.mobileNumber,
        })
      );
      dispatch(
        authAction.update({
          user: {
            ...user,
            mobileNumber: data.mobileNumber,
          },
        })
      );
    }
  }, [data]);

  return (
    data && (
      <UserProfileCard
        title={
          <React.Fragment>
            <span className="card-heading">Contact Information</span>
            <Button onClick={handleClick}>
              <i className="bx bxs-edit"></i>
            </Button>
          </React.Fragment>
        }
        body={
          <React.Fragment>
            <p>Mobile: {data.mobileNumber}</p>
          </React.Fragment>
        }
        body_2={
          <React.Fragment>
            {isShown && (
              <div className="c_card mb-5">
                <div className="card-body">
                  <form
                    className="w-full mt-2"
                    onSubmit={handleUpdateUserContact}
                  >
                    <TextField
                      fullWidth
                      margin="normal"
                      label="Mobile Number"
                      autoComplete="off"
                      defaultValue={data.mobileNumber}
                      {...register("mobileNumber")}
                    />
                    {errors.mobileNumber && (
                      <p className="text-red-700 mb-2">
                        * {errors.mobileNumber?.message}
                      </p>
                    )}
                    <div>
                      <Button type="submit" className="profile_button">
                        Save
                      </Button>
                      <Button onClick={handleClick} className="profile_button">
                        Cancel
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </React.Fragment>
        }
      />
    )
  );
};

export default ContactInformation;
