import OrgAccountDetails from "./OrgAccountDetails";
import ProfileLayout from "../../../../layout/Profile/ProfileLayout";

const OrgAccount = () => {
  return (
    <ProfileLayout>
      <OrgAccountDetails />
    </ProfileLayout>
  );
};

export default OrgAccount;
