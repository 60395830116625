import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ProgressBar from "@ramonak/react-progress-bar";

import { RootState } from "../../../../store";
import { profileCompletionAction } from "../../../../store/features/profileCompletionSlice";
import CoachDetails from "./CoachDetails";
import ProfileLayout from "../../../../layout/Profile/ProfileLayout";
import CoachService from "../../../../services/CoachService";
import UserProfileCard from "../../../../components/card/UserProfileCard";

import "../../Profile.css";

const CoachProfile = () => {
  const dispatch = useDispatch();
  const profileCompletion = useSelector(
    (state: RootState) => state.profileCompletion.profileCompletion
  );

  const getProfileCompletionPercent = async () => {
    const response = await CoachService.getProfileCompletionPercent();
    dispatch(profileCompletionAction.set(response.data));
    localStorage.setItem("profileCompletion", JSON.stringify(response.data));
  };

  useEffect(() => {
    getProfileCompletionPercent();
  }, []);

  return (
    <ProfileLayout>
      <UserProfileCard
        title={<span className="card-heading">Profile Completion</span>}
        body={
          <React.Fragment>
            <p className="mb-2 mt-2 mb-4">
              Your profile is {profileCompletion}% complete.
            </p>
            <ProgressBar completed={profileCompletion} bgColor="#1976d2" />
          </React.Fragment>
        }
      />

      <CoachDetails getProfileCompletionPercent={getProfileCompletionPercent} />
    </ProfileLayout>
  );
};

export default CoachProfile;
