import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Dropzone from "react-dropzone";
import { Button, Switch } from "@mui/material";
import moment from "moment";

import {
  isValidDocumentFileUploaded,
  isValidDocumentFileSize,
} from "../../../../components/file/FileValidation";
import { snackBarAction } from "../../../../store/features/snackBarSlice";
import UserProfileCard from "../../../../components/card/UserProfileCard";

const ProfileDocument = (props: any) => {
  const {
    sectionName,
    data,
    getDocument,
    getProfileCompletionPercent,
    fileType,
    uploadDocument,
    downloadDocument,
    deleteFile,
    deleteDocument,
    makeDefaultDocument,
  } = props;

  const [isTool, setIsTool] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isUpload, setIsUpload] = useState(true);
  // const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [listFiles, setListFiles] = useState<any>();
  // const [splitValue, setSplitValue] = useState("");

  const dispatch = useDispatch();

  const handleFileInput = (e: any) => {
    setSelectedFile(e[0]);
  };

  const ShowTool = () => {
    setIsTool(!isTool);
    setIsSave(true);
    setIsUpload(!isUpload);
  };

  const Cancel = () => {
    setIsUpload(!isUpload);
    setIsTool(!isTool);
    setIsSave(false);
    setSelectedFile(null);
  };

  const uploadFile = async (file: any) => {
    if (!selectedFile) {
      dispatch(
        snackBarAction.open({
          message: "Please select file to upload",
          severity: "error",
        })
      );
      return;
    }

    if (!isValidDocumentFileUploaded(file)) {
      dispatch(
        snackBarAction.open({
          message: "Invalid File Format",
          severity: "error",
        })
      );
      return;
    }

    if (!isValidDocumentFileSize(file)) {
      dispatch(
        snackBarAction.open({
          message: "File size should not be greater than 5MB",
          severity: "error",
        })
      );
      return;
    }

    try {
      await uploadDocument(data.uuid, file, fileType);
      setIsUpload(!isUpload);
      setIsTool(!isTool);
      setIsSave(false);
      setSelectedFile(null);
      getData();
      getProfileCompletionPercent();
      dispatch(
        snackBarAction.open({
          message: `${sectionName} has been successfully added`,
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e,
            severity: "error",
          })
        );
      }
    }
  };

  const handleDownload = async (docName: string) => {
    const response = await downloadDocument(data.uuid, docName, fileType);

    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", docName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const handleDelete = async (file: any) => {
    try {
      await deleteFile(file.uuid);
      await deleteDocument(data.uuid, file.docName, fileType);

      getData();
      getProfileCompletionPercent();
      dispatch(
        snackBarAction.open({
          message: `${sectionName} has been successfully deleted`,
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        var message = e.message;
        if (e.message == "Request failed with status code 403") {
          message = `Cannot delete default ${sectionName}`;
        }
        dispatch(
          snackBarAction.open({
            message: message,
            severity: "error",
          })
        );
      }
    }
  };

  const handleSwitchChange = async (document: any) => {
    if (document.isDefault) return;

    const newData = listFiles.map((obj: any) => {
      if (obj.uuid === document.uuid) {
        return {
          ...obj,
          isDefault: true,
        };
      } else {
        return {
          ...obj,
          isDefault: false,
        };
      }
    });

    setListFiles(newData);

    try {
      await makeDefaultDocument(document.uuid);
      dispatch(
        snackBarAction.open({
          message: `${sectionName} has been successfully set as default`,
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const getData = async () => {
    const response = await getDocument(data.uuid);
    setListFiles(response.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <UserProfileCard
      title={
        <React.Fragment>
          <span className="card-heading">{sectionName}</span>
        </React.Fragment>
      }
      body={
        <React.Fragment>
          <ul className="list-group">
            {listFiles &&
              listFiles.map((item: any, index: number) => (
                <li
                  className="list-group-item grid grid-cols-12 gap-4"
                  key={index}
                >
                  <div className="col-span-4 pl-0">
                    <div
                      className={`file_list cursor_pointer self-center ${
                        item.isDefault && "text-white bg-[#1976d2]"
                      }`}
                      onClick={() => handleDownload(item.docName)}
                    >
                      {item.docName}
                    </div>
                  </div>

                  <div className="date_field col-span-3">
                    <div className="inline-flex items-center justify-between w-full">
                      <React.Fragment>
                        <p className={`${item.isDefault && "text-[#1976d2]"}`}>
                          {item.isDefault ? "Default" : "Set as default"}
                        </p>
                        <Switch
                          checked={item.isDefault}
                          onChange={() => handleSwitchChange(item)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </React.Fragment>
                    </div>
                  </div>

                  <p className="date_field col-span-4">
                    Uploaded: {moment(item.dateStart).format("DD/MM/YYYY")}
                  </p>
                  <span className="trash_icon col-span-1 flex justify-end pr-0">
                    <i
                      className="bx bx-trash cursor_pointer"
                      onClick={() => handleDelete(item)}
                    ></i>
                  </span>
                </li>
              ))}
          </ul>
          <div>
            {/* Drag Drop Tool  */}
            {isTool && (
              <Dropzone onDrop={handleFileInput}>
                {({ getRootProps, getInputProps }) => (
                  <div className="drop_tool">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input className="input-zone" {...getInputProps()} />
                      <div className="text-center">
                        {selectedFile && selectedFile.name ? (
                          <p className="dropzone-content">
                            {selectedFile && selectedFile.name}
                          </p>
                        ) : (
                          <p className="dropzone-content">
                            Drag and drop some files here, or click to select
                            files
                          </p>
                        )}
                        <Button className="browse_button">Browse</Button>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            )}
            {/* Add Document Button  */}
            {isUpload && (
              <Button className="profile_button" onClick={ShowTool}>
                Add {sectionName}
              </Button>
            )}
            {isSave && (
              <React.Fragment>
                <Button
                  variant="contained"
                  component="label"
                  className="profile_button"
                  onClick={() => uploadFile(selectedFile)}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  component="label"
                  className="profile_button"
                  onClick={() => Cancel()}
                >
                  Cancel
                </Button>
                {/* <LinearProgress
                  variant="determinate"
                  className="mt-2"
                  value={progress}
                /> */}
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      }
    />
  );
};

export default ProfileDocument;
