import Api from "./Api";
import authHeader from "./AuthHeader";

const registerSport = (sport: any) => {
  return Api().post("/api/sport/register", sport, {
    headers: authHeader(),
  });
};

const getAllSports = () => {
  return Api().get("/api/sport/getAll", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getActiveSports = () => {
  return Api().get("/api/sport/getActive", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const updateSport = (sport: any) => {
  return Api().put(`/api/sport/update/${sport.uuid}`, sport, {
    headers: authHeader(),
  });
};

const deleteSport = (id: any) => {
  return Api().delete(`/api/sport/delete/${id}`, {
    headers: authHeader(),
  });
};

const restoreSport = (id: string) => {
  return Api().put(`/api/sport/restore/${id}`, null, {
    headers: authHeader(),
  });
};

const SportService = {
  registerSport,
  getAllSports,
  getActiveSports,
  updateSport,
  deleteSport,
  restoreSport,
};

export default SportService;
