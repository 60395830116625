import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-[calc(100vh-364px)] flex flex-col justify-center items-center py-16">
      <p className="text-9xl font-[900] tracking-widest text-zinc-400">404</p>
      <p className="text-3xl font-[500] mt-8">Page not found</p>
      <p className="text-lg font-[400] mt-6 w-96 text-center">
        Looks like the page you are looking for was not found or no longer
        exists.
      </p>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2, width: "10rem" }}
        className="xxs:text-xl  xs:text-2xl"
        onClick={() => navigate(-1)}
      >
        BACK
      </Button>
    </div>
  );
};

export default NotFound;
