import Api from "./Api";
import authHeader from "./AuthHeader";

const addOrgJobPostPlan = (data: any) => {
  return Api().post("/api/org/addOrgJobPostPlan", data, {
    headers: authHeader(),
  });
};
const changeOrgJobPostPlan = (orgId: any, newJobPostPlanID: any) => {
  return Api().put(`/api/org/changeOrgJobPostPlan/${orgId}`, null, {
    headers: authHeader(),
    params: {
      newJobPostPlanID: newJobPostPlanID,
    },
  });
};

const changeOrgJobPostPlanStatus = (
  jobPostPlanID: string,
  orgJobPostPlanStatus: string
) => {
  return Api().put(`/api/org/changeOrgJobPostPlanStatus/${jobPostPlanID}`, {
    headers: authHeader(),
    params: {
      orgJobPostPlanStatus: orgJobPostPlanStatus,
    },
  });
};

const jobPostPlanService = {
  addOrgJobPostPlan,
  changeOrgJobPostPlan,
  changeOrgJobPostPlanStatus,
};

export default jobPostPlanService;
