import React from "react";
import { useSelector } from "react-redux";
import StatusCard from "../../../components/status-card/StatusCard";
import analyticsCard from "../../../assets/JsonData/analytics-card-data.json";
import { RootState } from "../../../store/index";

const Analytics = () => {
  const themeReducer = useSelector((state: RootState) => state.mode.theme);

  return (
    <div>
      <div className="row">
        <div className="col-9">
          <div className="row">
            {analyticsCard.map((item, index) => (
              <div className="col-6" key={index}>
                <StatusCard
                  icon={item.icon}
                  count={item.count}
                  title={item.title}
                  link={""}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
