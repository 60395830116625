import React from "react";
import { useNavigate } from "react-router-dom";

import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { Button, Typography } from "@mui/material";

const EmailReceived = () => {
  const navigate = useNavigate();
  return (
    <div className="sm:min-h-[calc(100vh-25rem)] pt-16 pb-24 main-page">
      <div className="xxs:w-5/6 xx:max-w-sm mx-auto">
        <div className="flex flex-col items-center">
          <div className="bg-secondary text-white w-10 h-10 rounded-full flex items-center justify-center mb-2">
            <DoneOutlineIcon />
          </div>
          <Typography component="h1" variant="h5">
            Please check your email to find the latest password reset link.
          </Typography>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 5, mb: 5, width: "30rem" }}
            className="xxs:text-xl  xs:text-2xl"
            onClick={() => navigate("/signin")}
          >
            Back to SmartSportHR
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmailReceived;
