import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../../store";
import UserService from "../../../../services/UserService";
import ProfileInfomation from "../../Components/Account/ProfileInfomation";
import ContactInformation from "../../Components/Account/ContactInformation";
import LoginInformation from "../../Components/Account/LoginInfomation/LoginInformation";

const SuperAdminAccountDetails = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const [admin, setAdmin] = useState<any>();

  const getAdmin = async () => {
    const response = await UserService.getUserDetails(user.email);
    setAdmin(response.data);
  };

  useEffect(() => {
    getAdmin();
  }, []);

  return (
    <React.Fragment>
      <ProfileInfomation user={user} data={admin} getData={getAdmin} />
      <ContactInformation user={user} data={admin} getData={getAdmin} />
      <LoginInformation data={admin} />
    </React.Fragment>
  );
};

export default SuperAdminAccountDetails;
