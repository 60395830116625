import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { RootState } from "../../../store/index";
import JobService from "../../../services/JobService";
import { snackBarAction } from "../../../store/features/snackBarSlice";
import DashBoardCard from "../../../components/card/DashBoardCard";

import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Box,
  Typography,
} from "@mui/material";

import companyLogo from "../../../assets/images/example-logo.png";
import "./ViewJob.css";
import CoachService from "../../../services/CoachService";

const ViewJob = () => {
  const dispatch = useDispatch();
  const { jobId } = useParams();
  const [job, setJob] = useState<any>();
  const [appliedJob, setAppliedJob] = useState<any>();

  const applyJob = async () => {
    try {
      await JobService.applyJob({
        jobApplyStatus: "ONBOARDING",
        jobPostID: job.uuid,
      });

      dispatch(
        snackBarAction.open({
          message: "Apply Successful!",
          severity: "success",
        })
      );

      getJob();
      getAppliedJob();
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const getJob = async () => {
    const response = await JobService.getJobById(jobId);
    setJob(response.data[0]);
  };

  const getAppliedJob = async () => {
    const response = await CoachService.getAllAppliedJobPost();
    setAppliedJob(response.data);
  };

  useEffect(() => {
    getJob();
    getAppliedJob();
  }, []);

  return (
    job &&
    appliedJob && (
      <DashBoardCard
        body={
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Container maxWidth="lg" className="content-header">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  {job.role}
                </Typography>
                <Link to="/" className="org-name-header">
                  <Typography>{job.organization.companyName}</Typography>
                </Link>
                <Typography>
                  {job.city} {job.state}
                </Typography>
                <Typography>
                  {job.jobPostRoleTypes.map((item: any) => (
                    <React.Fragment key={item.uuid}>{item.name}</React.Fragment>
                  ))}
                </Typography>
                <div className="button-header">
                  {appliedJob.find(
                    (item: any) => item.jobPost.uuid === job.uuid
                  ) ? (
                    <Button variant="contained" size="large" disabled>
                      Applied on{" "}
                      {moment(
                        appliedJob.find(
                          (item: any) => item.jobPost.uuid === job.uuid
                        ).dateApplied
                      ).format("DD/MM/YYYY")}
                    </Button>
                  ) : (
                    <Button variant="contained" size="large" onClick={applyJob}>
                      Apply now
                    </Button>
                  )}

                  <Button
                    sx={{ ml: 2, height: "100%" }}
                    variant="outlined"
                    size="large"
                  >
                    <i className="bx bx-heart"></i>
                    Like
                  </Button>
                </div>
              </Container>
              <Container maxWidth="lg">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  Job details
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>Job Type</Typography>
                <Typography>
                  {job.jobPostRoleTypes.map((item: any) => (
                    <React.Fragment key={item.uuid}>{item.name}</React.Fragment>
                  ))}
                </Typography>
              </Container>
              <Divider sx={{ my: 2 }} />
              <Container maxWidth="lg">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  Full Job Description
                </Typography>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Job Description
                </Typography>
                <Typography sx={{ my: 1 }}>
                  Location: {job.city} {job.state}
                </Typography>
                <Typography sx={{ my: 1 }}>
                  Job type:{" "}
                  {job.jobPostRoleTypes.map((item: any) => (
                    <React.Fragment key={item.uuid}>{item.name}</React.Fragment>
                  ))}
                </Typography>
                <Typography sx={{ my: 1 }}>
                  Organisation: {job.organization.companyName}
                </Typography>
                <Typography sx={{ my: 1 }}>Salary: -</Typography>
                <Typography sx={{ my: 1 }}>Occupation: -</Typography>
                <Typography sx={{ my: 1 }}>Reference: -</Typography>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Selection Criteria
                </Typography>
                <Typography sx={{ my: 1 }}>SC1</Typography>
                <Typography sx={{ my: 1 }}>SC2</Typography>
                <Typography sx={{ my: 1 }}>SC3</Typography>
                <Typography sx={{ my: 1 }}>SC4</Typography>
                <Typography sx={{ my: 1 }}>SC5</Typography>
                <Typography sx={{ my: 1 }}>SC6</Typography>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Role
                </Typography>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Role and Responsibilities
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={4}>
              <Container maxWidth="lg" sx={{ pt: 2 }}>
                <Card>
                  <CardHeader title="Company Info" />
                  <Divider />
                  <CardContent className="company-info-content">
                    <img src={companyLogo} alt="" />
                    <Button variant="outlined" size="small" sx={{ mt: 2 }}>
                      Follow
                    </Button>
                    <Typography sx={{ mt: 2 }}>
                      Get job updates from {job.organization.companyName}
                    </Typography>
                  </CardContent>
                </Card>
              </Container>
            </Grid>
          </Grid>
        }
      ></DashBoardCard>
    )
  );
};

export default ViewJob;
