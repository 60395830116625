import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import AuthService from "../../services/AuthenticationService";

import Button from "@mui/material/Button";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const ConfirmAccount = () => {
  const { token } = useParams();
  const [tokenData, setTokenData] = useState<any>();
  const [loginLink, setLoginLink] = useState<any>();

  const getTokenData = async () => {
    try {
      const jobs = await AuthService.validateConfirmAccountToken(token);
      if (jobs.data) {
        setTokenData(true);
        if (
          jobs.data.role === "ROLE_ORGUSER" ||
          jobs.data.role === "ROLE_ORGADMIN"
        ) {
          setLoginLink("/org/signin");
        } else {
          setLoginLink("/signin");
        }
      } else {
        setTokenData(false);
      }
    } catch (e) {
      setTokenData(false);
    }
  };

  useEffect(() => {
    getTokenData();
  }, []);

  return (
    <>
      {tokenData === false && (
        <div className="errorBlock">
          <div className="bg-secondary text-white w-10 h-10 rounded-full flex items-center justify-center mb-2">
            <LockOutlinedIcon />
          </div>
          <div className="border-l-4 border-red-800 rounded-l-md">
            <p className="text-red-800 bg-red-200/75 p-5 rounded-r-md font-[500]">
              Invalid/Expired Token
            </p>
          </div>
        </div>
      )}

      {tokenData === true && (
        <div className="successBlock">
          <span className="successIcon">
            <i className="bx bx-check-circle"></i>
          </span>
          <h1 className="successTitle">Account Confirmation Successfull!</h1>
          <p className="confirmationTitle">
            Thank you for confirming your account.
          </p>
          <div className="redirectContainer">
            <Link to={typeof loginLink !== "undefined" && loginLink}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "20rem" }}
                className="xxs:text-xl  xs:text-2xl"
              >
                Login to SmartSportHR
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmAccount;
