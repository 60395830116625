import React from "react";

import {
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const PostingDate = (props: any) => {
  const { postDate, setPostDate, register, setValue, errors, trigger } = props;

  const handleChange = (e: any) => {
    let value;
    if (e.target.name === "saveCard") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    if (e.target.name === "noofcandidates") {
      if (!value.match(/^(\s*|\d+)$/)) {
        return;
      }
    }
    setPostDate({
      ...postDate,
      [e.target.name]: value,
    });
    setValue(e.target.name, value);

    trigger(e.target.name);
  };

  return (
    <React.Fragment>
      {/* <Typography variant="h6" gutterBottom>
        Payment method
      </Typography> */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="filldate"
            InputLabelProps={{ shrink: true }}
            type={"date"}
            label="When do you need this position filled?"
            fullWidth
            autoComplete="filldate"
            variant="standard"
            {...register("filldate")}
            onChange={handleChange}
          />
          {errors.filldate && (
            <p className="text-red-700">* {errors.filldate.message}</p>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography gutterBottom>
            When do you want this job post to go live?
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="startdate"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            type={"date"}
            fullWidth
            autoComplete="startdate"
            variant="standard"
            {...register("startdate")}
            onChange={handleChange}
          />
          {errors.startdate && (
            <p className="text-red-700">* {errors.startdate.message}</p>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="enddate"
            label="End date"
            InputLabelProps={{ shrink: true }}
            type={"date"}
            fullWidth
            autoComplete="enddate"
            variant="standard"
            {...register("enddate")}
            onChange={handleChange}
          />
          {errors.enddate && (
            <p className="text-red-700">* {errors.enddate.message}</p>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="noofcandidates"
            label="No of Candidates Required"
            fullWidth
            autoComplete="No of Candidates Required"
            variant="standard"
            {...register("noofcandidates")}
            onChange={handleChange}
            value={postDate.noofcandidates}
          />
          {errors.noofcandidates && (
            <p className="text-red-700">* {errors.noofcandidates.message}</p>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={postDate.saveCard}
                onChange={handleChange}
                // value={postDate.saveCard}
                name="saveCard"
              />
            }
            label="Do you want to include the organisation's philosophy?"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PostingDate;
