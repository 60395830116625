import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, blueGrey, red } from "@mui/material/colors";
import {
  Button,
  Grid,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Edit, Delete, Restore } from "@mui/icons-material";
import { snackBarAction } from "../../../store/features/snackBarSlice";
import { TableHead, TableBody } from "../../../types/types";
import DashBoardCard from "../../../components/card/DashBoardCard";
import Table from "../../../components/table/Table";
import TransitionsModal from "../../../components/modal/TransitionsModal";
import SportService from "../../../services/SportService";

const SportTableHead = ["Type of Sport", "Status", ""];

const theme = createTheme({
  palette: {
    primary: red,
    secondary: blue,
    info: blueGrey,
  },
});

const renderHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const Sports = () => {
  const dispatch = useDispatch();

  const [sports, setSports] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<any>();
  const [sport, setSport] = useState<any>({
    name: "",
  });

  const handleOpen = async (
    e: React.MouseEvent<HTMLButtonElement>,
    item?: any
  ) => {
    if (e.currentTarget.name !== "add") {
      setSport({
        name: item.name,
        uuid: item.uuid,
        dateDeleted: item.dateDeleted,
      });
    }
    setModal(e.currentTarget.name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSport({
      name: "",
    });
  };

  const handlechange = (e: any) => {
    setSport({ ...sport, name: e.target.value });
  };

  const handleSave = async () => {
    try {
      let response;
      if (modal === "add") {
        response = await SportService.registerSport(sport);
      } else if (modal === "edit") {
        response = await SportService.updateSport(sport);
      }

      if (response) {
        getSports();
        handleClose();

        if (modal === "add") {
          dispatch(
            snackBarAction.open({
              message: "Sport has successfully added",
              severity: "success",
            })
          );
        } else if (modal === "edit") {
          dispatch(
            snackBarAction.open({
              message: "Sport has successfully edited",
              severity: "success",
            })
          );
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (sport.uuid) {
        const response = await SportService.deleteSport(sport.uuid);
        if (response) {
          getSports();
          handleClose();
          dispatch(
            snackBarAction.open({
              message: "Sport has successfully deleted",
              severity: "success",
            })
          );
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const handleRestore = async () => {
    try {
      if (sport.uuid) {
        await SportService.restoreSport(sport.uuid);
        getSports();
        handleClose();
        dispatch(
          snackBarAction.open({
            message: "Sport has successfully restored",
            severity: "success",
          })
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const renderBody = (item: TableBody, index: number) => (
    <tr key={index}>
      <td className="w-[37.5%]">{item.name}</td>
      <td className="w-[37.5%]">
        {!item.dateDeleted
          ? "Active"
          : `Deleted on ${moment(item.dateDeleted).format("DD/MM/YYYY")}`}
      </td>
      <td className="w-[25%] text-end">
        {!item.dateDeleted ? (
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<Edit />}
                    onClick={(e) => handleOpen(e, item)}
                    name="edit"
                  >
                    edit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="small"
                    color="info"
                    startIcon={<Delete />}
                    onClick={(e) => handleOpen(e, item)}
                    name="delete"
                  >
                    delete
                  </Button>
                </Grid>
              </Grid>
            </ThemeProvider>
          </React.Fragment>
        ) : (
          <Button
            variant="contained"
            size="small"
            startIcon={<Restore />}
            onClick={(e) => handleOpen(e, item)}
            name="restore"
          >
            restore
          </Button>
        )}
      </td>
    </tr>
  );

  const getSports = async () => {
    const response = await SportService.getAllSports();
    setSports(response.data);
    // setSports([{ name: "Example" }, { name: "Example 2" }]);
  };

  useEffect(() => {
    getSports();
  }, []);

  return (
    sports && (
      <React.Fragment>
        <div className="flex justify-between items-center">
          <h2 className="page-header">Coaches</h2>
          <ThemeProvider theme={theme}>
            <Button
              className="tier-add-button"
              name="add"
              variant="contained"
              size="large"
              color="primary"
              startIcon={<Add />}
              onClick={handleOpen}
            >
              Add
            </Button>
          </ThemeProvider>
        </div>
        <div className="row tier-page">
          <div className="col-12">
            <DashBoardCard
              body={
                <Table
                  limit="10"
                  headData={SportTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={sports}
                  renderBody={(item, index) => renderBody(item, index)}
                />
              }
            />
          </div>
        </div>
        <TransitionsModal
          open={open}
          handleClose={handleClose}
          children={
            <React.Fragment>
              <Typography
                id="transition-modal-add-tier"
                variant="h6"
                component="h2"
              >
                {modal === "edit"
                  ? "Edit"
                  : modal === "delete"
                  ? "Delete"
                  : modal === "restore" && "Restore"}{" "}
                Sport
              </Typography>
              <Typography id="modal-add-tier" sx={{ mt: 2, mb: 1 }}>
                {modal === "add"
                  ? "Please enter a new sport"
                  : modal === "delete"
                  ? `Are you sure you want to delete "${sport.name}"?`
                  : modal === "restore" &&
                    `Are you sure you want to restore "${sport.name}"?`}
              </Typography>
              <FormControl fullWidth className="add-tier">
                {(modal === "add" || modal === "edit") && (
                  <React.Fragment>
                    <TextField
                      label="Name"
                      variant="outlined"
                      className="!mb-4"
                      value={sport.name}
                      onChange={handlechange}
                    />
                  </React.Fragment>
                )}
                <div
                  className={`flex justify-end ${
                    (modal === "delete" || modal === "restore") && "mt-6"
                  }`}
                >
                  <button
                    className="mr-2 cancel-tier-button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="ml-2 action-tier-button"
                    onClick={
                      modal === "delete"
                        ? handleDelete
                        : modal === "restore"
                        ? handleRestore
                        : modal === "add" || modal === "edit"
                        ? handleSave
                        : undefined
                    }
                  >
                    {modal === "delete"
                      ? "Delete"
                      : modal === "restore"
                      ? "Restore"
                      : modal === "add" || modal === "edit"
                      ? "Save"
                      : undefined}
                  </button>
                </div>
              </FormControl>
            </React.Fragment>
          }
        ></TransitionsModal>
      </React.Fragment>
    )
  );
};

export default Sports;
