import Api from "./Api";
import JsonApi from "./JsonApi";
import authHeader from "./AuthHeader";

type Job = any;
type SearchCriteria = any;

//Attached Token into the header and send a request for user content
//Create job post
const createJob = (job: Job) => {
  return Api().post("/api/jobPost/saveJob", job, {
    headers: authHeader(),
  });
};
//Get all job including deleted jobs
const getJob = () => {
  return Api().get("/api/jobPost/getActive", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};
//Get all job excluding deleted jobs
const getActiveJob = () => {
  return Api().get("/api/jobPost/getActive", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};
//Get all active jobs by logged organization
const getAllActiveByOrg = () => {
  return Api().get("/api/jobPost/getAllActiveByOrg", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};
//Get all active jobs
const getAllNotExpired = () => {
  return Api().get("/api/jobPost/getAllNotExpired", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};
//Get all expired jobs by logged organization
const getAllExpiredByOrg = () => {
  return Api().get("/api/jobPost/getAllExpiredByOrg", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};
//Get all expired jobs
const getAllExpired = () => {
  return Api().get("/api/jobPost/getAllExpired", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};
//Get single job with UUID of job post
const getJobById = (jobId: string | undefined) => {
  return Api().get("/api/jobPost/profile/" + jobId, {
    headers: authHeader(),
  });
};

//Get single job with UUID of job post
const getAppliedJobsbyId = (applyJobId: string | undefined) => {
  return Api().get("/api/jobPost/profile/" + applyJobId, {
    headers: authHeader(),
  });
};

// Where is it being used?
const getAppliedJobsByJobIdAndUserId = (
  jobId: string | undefined,
  userId: string | undefined
) => {
  return JsonApi().get(`/auth/getAppliedJobs/${jobId}/${userId}`, {
    headers: authHeader(),
  });
};

//Searches from job posts
//Pass search criteria object
// {
//   what: "",
//   where: "",
// }
const searchJobs = (searchCriteria: SearchCriteria) => {
  return Api().post("/api/jobPost/search", searchCriteria, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Searches from active job posts by org
//Pass search criteria object
// {
//   what: "",
//   where: "",
// }
const searchActiveJobsByOrg = (searchCriteria: SearchCriteria) => {
  return Api().post("/api/jobPost/searchActiveJobPostsByOrg", searchCriteria, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Searches from active job posts
//Pass search criteria object
// {
//   what: "",
//   where: "",
// }
const searchActiveJobs = (searchCriteria: SearchCriteria) => {
  return Api().post("/api/jobPost/searchActiveJobPosts", searchCriteria, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Searches from expired job posts by org
//Pass search criteria object
// {
//   what: "",
//   where: "",
// }
const searchExpiredJobsByOrg = (searchCriteria: SearchCriteria) => {
  return Api().post("/api/jobPost/searchExpiredJobPostsByOrg", searchCriteria, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Searches from expired job posts by org
//Pass search criteria object
// {
//   what: "",
//   where: "",
// }
const searchExpiredJobs = (searchCriteria: SearchCriteria) => {
  return Api().post("/api/jobPost/searchExpiredJobPosts", searchCriteria, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Gets matched jobs for organization
const getOrgCoachMatchedJobs = () => {
  return Api().get("/api/jobPost/getCoachMatchedJobPosts", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Gets matched jobs for coaches
const getCoachMatchedJobs = () => {
  return Api().get("/api/coach/getMatchedJobPostsForCoach", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Searches from coach matched job posts
//Pass search criteria object
// {
//   what: "",
//   where: "",
// }
const searchCoachMatchedJobPosts = (searchCriteria: SearchCriteria) => {
  return Api().post("/api/coach/searchCoachMatchedJobPosts", searchCriteria, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Get single job with UUID of job post
const getCoachMatchedJobsbyId = (matchedJobId: string | undefined) => {
  return Api().get("/api/jobPost/profile/" + matchedJobId, {
    headers: authHeader(),
  });
};

//Applies to job post
//Pass the following object
// {
//   jobApplyStatus: "ONBOARDING", //SUCCESSFUL, ONBOARDING, UNSUCCESSFUL, WITHDRAWN
//   jobPostID: "",
// }
const applyJob = (jobData: any) => {
  return Api().post("/api/coach/addCoachToJobPost", jobData, {
    headers: authHeader(),
  });
};

//Likes job post
//Pass the following object
// {
//   jobPostID: "",
// }
const likeJob = (jobData: any) => {
  return Api().post("/api/coach/addCoachLikedJobPost", jobData, {
    headers: authHeader(),
  });
};

//Unlikes job post
//Pass the following object job post uuid
const unlikeJob = (jobId: string | undefined) => {
  return Api().delete("/api/coach/deleteCoachLikedJobPost/" + jobId, {
    headers: authHeader(),
  });
};

//Get liked job posts
const getLikedJobs = () => {
  return Api().get("/api/coach/getCoachLikedJobPosts", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Add Job Post To Sport
const addJobPostToSport = (jobPostId: string, sportId: string) => {
  return Api().post(
    `/api/jobPost/addJobPostToSport/${jobPostId}/${sportId}`,
    null,
    {
      headers: authHeader(),
    }
  );
};

//Get All Active Jobs By Sport
const getAllActiveJobBySport = (sportName: string) => {
  return Api().get(`/api/jobPost/getAllActiveBySport/${sportName}`, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Get All  Jobs By Sport
const getAllJobBySport = (sportName: string) => {
  return Api().get(`/api/jobPost/getAllBySport/${sportName}`, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getRecentApplicants = () => {
  return Api().get("api/jobPost/getRecentApplicants/", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getRecentApplicantsByOrg = () => {
  return Api().get("api/jobPost/getRecentApplicantsByOrg/", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getLatestJobs = () => {
  return Api().get("api/jobPost/getLatestJobs/", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getLatestJobsForCoach = () => {
  return Api().get("api/jobPost/getLatestJobsForCoach/", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 5,
    },
  });
};

const getLatestJobsByOrg = () => {
  return Api().get("api/jobPost/getLatestJobsByOrg/", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const JobService = {
  createJob,
  getJob,
  getActiveJob,
  getAllActiveByOrg,
  getAllNotExpired,
  getAllExpiredByOrg,
  getAllExpired,
  getJobById,
  getAppliedJobsbyId,
  getAppliedJobsByJobIdAndUserId,
  searchJobs,
  searchActiveJobsByOrg,
  searchActiveJobs,
  searchExpiredJobsByOrg,
  searchExpiredJobs,
  getOrgCoachMatchedJobs,
  getCoachMatchedJobs,
  searchCoachMatchedJobPosts,
  getCoachMatchedJobsbyId,
  applyJob,
  likeJob,
  unlikeJob,
  getLikedJobs,
  addJobPostToSport,
  getAllActiveJobBySport,
  getAllJobBySport,
  getRecentApplicants,
  getRecentApplicantsByOrg,
  getLatestJobs,
  getLatestJobsByOrg,
  getLatestJobsForCoach,
};

export default JobService;
