import { configureStore } from "@reduxjs/toolkit";
import modeReducer from "./features/modeSlice";
import authReducer from "./features/authSlice";
import snackBarReducer from "./features/snackBarSlice";
import profileCompletionReducer from "./features/profileCompletionSlice";
import profilePictureReducer from "./features/profilePictureSlice";

const store = configureStore({
  reducer: {
    mode: modeReducer,
    auth: authReducer,
    snackBar: snackBarReducer,
    profileCompletion: profileCompletionReducer,
    profilePicture: profilePictureReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
