import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import UserService from "../../../../../services/UserService";
import AuthService from "../../../../../services/AuthenticationService";
import { snackBarAction } from "../../../../../store/features/snackBarSlice";
import TransitionsModal from "../../../../../components/modal/TransitionsModal";
import { authAction } from "../../../../../store/features/authSlice";

import { Button, TextField, Typography } from "@mui/material";

const schema = yup.object({
  newEmail: yup
    .string()
    .required("Required field")
    .email("Invalid email address"),
});

type FormData = yup.InferType<typeof schema>;

const ChangeEmail = (props: any) => {
  const { handleClick } = props;

  const [open, setOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    trigger,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const handleHide = () => {
    handleClick();
    reset();
  };

  const handleOpen = async () => {
    const result = await trigger(["newEmail"]);
    if (!result) {
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateUserEmail = handleSubmit(async (data) => {
    try {
      await UserService.updateUserEmail(data.newEmail);
      AuthService.signout();
      dispatch(authAction.logout());
      navigate("/signin");
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  });

  return (
    <React.Fragment>
      <div className="mt-4 text-lg font-semibold">Change Email Address</div>
      <form
        id="change-email"
        className="w-full"
        onSubmit={(e: any) => handleUpdateUserEmail(e)}
      >
        <TextField
          type="text"
          margin="normal"
          fullWidth
          id="new-email"
          label="New Email"
          autoComplete="off"
          {...register("newEmail")}
        />
        {errors.newEmail && (
          <p className="text-red-700 mb-2">* {errors.newEmail?.message}</p>
        )}

        <div>
          <Button onClick={handleOpen} className="profile_button">
            Save
          </Button>
          <Button onClick={handleHide} className="profile_button">
            Cancel
          </Button>
        </div>
        <TransitionsModal
          open={open}
          handleClose={handleClose}
          children={
            <React.Fragment>
              <Typography variant="h6" component="h2">
                Are you sure you want to change your email?
              </Typography>
              <Typography sx={{ mt: 2, mb: 1 }}>
                You will be logged out from the system after you change your
                email, please log in again.
              </Typography>
              <div className="flex justify-end mt-6">
                <button className="mr-2 cancel-button" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  form="change-email"
                  type="submit"
                  className="ml-2 action-button"
                >
                  Yes
                </button>
              </div>
            </React.Fragment>
          }
        />
      </form>
    </React.Fragment>
  );
};

export default ChangeEmail;
