import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import Chart from "react-apexcharts";
import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";

import StatusCard from "../../../components/status-card/StatusCard";
import Table from "../../../components/table/Table";
import Badge from "../../../components/badge/Badge";
import statusCards from "../../../assets/JsonData/status-card-data.json";
import { ApexOptions } from "apexcharts";
import { TableHead, TableBody } from "../../../types/types";
import { RootState } from "../../../store/index";
import JobService from "../../../services/JobService";

const chartOptions = {
  series: [
    {
      name: "Jobs",
      data: [40, 70, 20, 90, 36, 80, 30, 91, 60],
    },
    {
      name: "Matched Applicants",
      data: [40, 30, 70, 80, 40, 16, 40, 20, 51],
    },
  ],
  options: {
    colors: ["#6ab04c", "#2980b9"],
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    legend: {
      position: "top",
    },
    grid: {
      show: false,
    },
  },
};

const ApplicantTableHead = ["user", "role", "status"];

const renderApplicantHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const JobTableHead = ["role", "# matched", "start date", "end date"];

const renderJobTableHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const applicantStatus = {
  OPEN: "primary",
  INTERVIEWING: "warning",
  OFFERED: "success",
  ACCPTED: "primary",
  ONBOARDING: "success",
};

const OrgDashboard = () => {
  const themeReducer = useSelector((state: RootState) => state.mode.theme);
  const profileCompletion = useSelector(
    (state: RootState) => state.profileCompletion.profileCompletion
  );

  const [recentApplicants, setRecentApplicants] = useState<any>();
  const [latestJobs, setLatestJobs] = useState<any>();

  const renderApplicantBody = (item: TableBody, index: number) => (
    <tr key={index}>
      <td>{item.username}</td>
      <td>{item.role}</td>
      <td>
        <Badge
          type={
            item.status !== undefined &&
            applicantStatus[item.status as keyof typeof applicantStatus]
          }
          content={item.status}
        />
      </td>
    </tr>
  );

  const renderJobBody = (item: TableBody, index: number) => (
    <tr key={index}>
      <td>{item.role}</td>
      <td>{item.matched}</td>
      <td>{moment(item.dateStart).format("DD/MM/YYYY")}</td>
      <td>{moment(item.dateEnd).format("DD/MM/YYYY")}</td>
    </tr>
  );

  const getRecentApplicantsByOrg = async () => {
    const allRecentApplicants = await JobService.getRecentApplicantsByOrg();
    setRecentApplicants(allRecentApplicants.data);
  };

  useEffect(() => {
    getRecentApplicantsByOrg();
  }, []);

  const getLatestJobsByOrg = async () => {
    const allLatestJobs = await JobService.getLatestJobsByOrg();
    setLatestJobs(allLatestJobs.data);
  };

  useEffect(() => {
    getLatestJobsByOrg();
  }, []);

  return (
    <div>
      {/* <h2 className="page-header">Dashboard</h2> */}
      <div className="row">
        <div className="col-6">
          <div className="row">
            {statusCards.map((item, index) => (
              <div className="col-6 mb-7" key={index}>
                <StatusCard
                  icon={item.icon}
                  count={item.count}
                  title={item.title}
                  link={""}
                />
              </div>
            ))}

            <div className="col-6 mb-7">
              <Link to="/org/profile">
                <div className="status-card h-full">
                  <div className="h-full flex items-center justify-between">
                    <div className="status-card__icon">
                      <CircularProgressbar
                        value={profileCompletion}
                        text={`${profileCompletion}%`}
                        strokeWidth={10}
                      ></CircularProgressbar>
                    </div>
                    <div className="status-card__info">
                      {/* <p>{`${String(profileCompletion)}%`}</p> */}
                      <span>Profile Completion</span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card full-height">
            {/* chart */}
            <Chart
              options={
                themeReducer === "theme-mode-dark"
                  ? ({
                      ...chartOptions.options,
                      theme: { mode: "dark" },
                    } as ApexOptions)
                  : ({
                      ...chartOptions.options,
                      theme: { mode: "light" },
                    } as ApexOptions)
              }
              series={chartOptions.series}
              type="line"
              height="100%"
            />
          </div>
        </div>
        <div className="col-4">
          <div className="card">
            <div className="card__header">
              <h3>recent applicants</h3>
            </div>
            <div className="card__body">
              <Table
                headData={ApplicantTableHead}
                renderHead={(item: TableHead, index: number) =>
                  renderApplicantHead(item, index)
                }
                bodyData={recentApplicants}
                renderBody={(item: TableBody, index: number) =>
                  renderApplicantBody(item, index)
                }
              />
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="card">
            <div className="card__header">
              <h3>latest jobs</h3>
            </div>
            <div className="card__body">
              <Table
                headData={JobTableHead}
                renderHead={(item: TableHead, index: number) =>
                  renderJobTableHead(item, index)
                }
                bodyData={latestJobs}
                renderBody={(item: TableBody, index: number) =>
                  renderJobBody(item, index)
                }
              />
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgDashboard;
