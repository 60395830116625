import React, { useState } from "react";
import "./InputField.css";

interface props {
  onSearch: any;
}

const InputField: React.FC<props> = ({ onSearch }) => {
  const [what, setWhat] = useState("");
  const [where, setWhere] = useState("");

  return (
    <form
      className="flex items-end"
      onSubmit={(e) => {
        e.preventDefault();
        const searchCriteria = {
          what: what,
          where: where,
        };
        onSearch(searchCriteria);
      }}
    >
      <div className="w-[46.5%]">
        <h3 className="ml-5 font-[600] text-lg text-primary">What</h3>
        <input
          type="text"
          placeholder="Job title,keywords, or company"
          onChange={(e) => setWhat(e.target.value)}
          className="input__box"
        />
      </div>
      <div className="w-[46.5%] pl-[5%]">
        <h3 className="ml-5 font-[600] text-lg text-primary">Where</h3>
        <input
          type="text"
          placeholder="city,state/territory or postcode"
          onChange={(e) => setWhere(e.target.value)}
          className="input__box"
        />
      </div>
      <div className="ml-auto">
        <button type="submit" className="input_submit">
          GO
        </button>
      </div>
    </form>
  );
};

export default InputField;
