import React, { useState } from "react";

import { Button } from "@mui/material";

const AccountSetting = () => {
  const [isShown, setIsShown] = useState(false);

  const handleClick = () => {
    setIsShown(!isShown);
  };

  return (
    <React.Fragment>
      <div className="c_card mb-5">
        <div className="card-title profile_title">
          <span className="card-heading">Account Setting</span>
          <Button type="submit" onClick={handleClick}>
            <i className="bx bxs-edit"></i>
          </Button>
        </div>
        <div className="card-body">
          <div className="profile_title">
            <div className="profile_title">
              <i className="bx bxs-key login_key"></i>
              <p>Deactivate Account</p>
            </div>
          </div>
        </div>
      </div>
      {/* edit card  */}
      {isShown && (
        <div className="c_card mb-5">
          <div className="card-body">
            <form className="w-full mt-2">
              <p>Are you sure you wish to Deactivate your account?</p>
              <div>
                <Button className="profile_button">Deactivate</Button>
                <Button onClick={handleClick} className="profile_button">
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AccountSetting;
