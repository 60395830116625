import React from "react";

const DashBoardCard = (props: any) => {
  return (
    <div className="card">
      <div className="card__body">{props.body}</div>
    </div>
  );
};

export default DashBoardCard;
