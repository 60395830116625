import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Box,
  Typography,
  Container,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { snackBarAction } from "../../../../store/features/snackBarSlice";
import JobService from "../../../../services/JobService";
import RoleDescription from "./RoleDescription";
import SessionDetails from "./SessionDetails";
import PostingDate from "./PostingDate";
import Review from "../../../../components/Review";
import DashBoardCard from "../../../../components/card/DashBoardCard";

const steps: string[] = [
  "Role description",
  "Qualifications and Prerequisites",
  "Posting date",
];

const schema = yup.object({
  // Role
  role: yup.string().required("Required field"),
  values: yup.array().min(1, "Please add at least one value"),
  philisophies: yup.array().min(1, "Please add at least one philisophy"),
  sport: yup.string().required("Required field"),
  roletype: yup.string().required("Required field"),
  salarymin: yup.string().required("Required field"),
  salarymax: yup.string().required("Required field"),
  salarytype: yup.string().required("Required field"),
  city: yup.string().required("Required field"),
  state: yup.string().required("Required field"),
  moreinfo: yup.string().required("Required field"),
  // Session
  minimumqualification: yup.string().required("Required field"),
  experienceyears: yup.string().required("Required field"),
  travelrequirements: yup.string().required("Required field"),
  traveldocument: yup.boolean(),
  workingwithchildren: yup.boolean(),
  trainingdayandtime: yup
    .array()
    .min(1, "Please add at least one day and time."),
  mandatorychecks: yup.string().required("Required field"),
  // Posting Date
  filldate: yup.string().required("Required field"),
  startdate: yup.string().required("Required field"),
  enddate: yup.string().required("Required field"),
  noofcandidates: yup.string().required("Required field"),
  saveCard: yup.boolean(),
});

type FormData = yup.InferType<typeof schema>;

export default function Checkout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);

  const {
    register,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const [role, setRole] = useState({
    role: "",
    values: [],
    philisophies: [],
    sport: "",
    roletype: "",
    salarymin: "",
    salarymax: "",
    salarytype: "",
    city: "",
    state: "",
    moreinfo: "",
  });

  const [session, setSession] = useState({
    minimumqualification: "",
    experienceyears: "",
    travelrequirements: "",
    traveldocument: false,
    workingwithchildren: false,
    trainingdayandtime: [],
    mandatorychecks: "",
  });

  const [postDate, setPostDate] = useState({
    filldate: "",
    startdate: "",
    enddate: "",
    noofcandidates: "",
    saveCard: false,
  });

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <RoleDescription
            role={role}
            setRole={setRole}
            register={register}
            setValue={setValue}
            errors={errors}
            trigger={trigger}
          />
        );
      case 1:
        return (
          <SessionDetails
            session={session}
            setSession={setSession}
            register={register}
            setValue={setValue}
            errors={errors}
            trigger={trigger}
          />
        );
      case 2:
        return (
          <PostingDate
            postDate={postDate}
            setPostDate={setPostDate}
            register={register}
            setValue={setValue}
            errors={errors}
            trigger={trigger}
          />
        );
      case 3:
        return (
          <Review role={role} session={session} postDate={postDate}></Review>
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handleNext = async () => {
    if (activeStep === 0) {
      if (role.values.length === 0) {
        setValue("values", []);
      }
      if (role.philisophies.length === 0) {
        setValue("philisophies", []);
      }

      const result = await trigger([
        "role",
        "values",
        "philisophies",
        "sport",
        "roletype",
        "salarymin",
        "salarymax",
        "salarytype",
        "city",
        "state",
        "moreinfo",
      ]);
      if (!result) {
        return;
      }
    }

    if (activeStep === 1) {
      if (session.trainingdayandtime.length === 0) {
        setValue("trainingdayandtime", []);
      }

      const result = await trigger([
        "minimumqualification",
        "experienceyears",
        "travelrequirements",
        "traveldocument",
        "workingwithchildren",
        "trainingdayandtime",
        "mandatorychecks",
      ]);
      if (!result) {
        return;
      }
    }
    if (activeStep === 2) {
      const result = await trigger([
        "filldate",
        "startdate",
        "enddate",
        "noofcandidates",
        "saveCard",
      ]);
      if (!result) {
        return;
      }
    }
    if (activeStep === 3) {
      try {
        await JobService.createJob({
          roleDescription: {
            ...role,
            salarymax: parseFloat(role.salarymax),
            salarymin: parseFloat(role.salarymin),
          },
          sessionDetails: {
            ...session,
            experienceyears: Number(session.experienceyears),
          },
          postingDate: {
            ...postDate,
            noofcandidates: Number(postDate.noofcandidates),
          },
        });

        // if (response.data.alert) {
        //   dispatch(
        //     snackBarAction.open({
        //       message: response.data.alert,
        //       severity: "warning",
        //     })
        //   );
        // }

        dispatch(
          snackBarAction.open({
            message: "The job has successfully posted",
            severity: "success",
          })
        );
        reset();
        navigate("/org/joblist");
      } catch (e: any) {
        dispatch(
          snackBarAction.open({
            message: e.response.data.message,
            severity: "error",
          })
        );
        return;
      }
    }

    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <DashBoardCard
      body={
        <Container
          component="main"
          maxWidth="sm"
          sx={{ mb: 4 }}
          className="pt-10"
        >
          <Typography component="h1" variant="h4" align="center">
            Create a Job Post
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {/* {activeStep === steps.length ? (
            <Review
              role={role}
              session={session}
              postDate={postDate}
            ></Review>
          ) : ( */}
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length
                    ? "Save"
                    : activeStep === steps.length - 1
                    ? "Review"
                    : "Next"}
                </Button>
              </Box>
            </React.Fragment>
            {/* )} */}
          </React.Fragment>
        </Container>
      }
    ></DashBoardCard>
  );
}
