import React from "react";

import { Typography, Box, Link, Toolbar, AppBar } from "@mui/material";

const Header = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            <Link
              key="home-route"
              variant="h6"
              underline="none"
              color="inherit"
              href="/"
              sx={{ fontSize: 24, fontWeight: 400, marginLeft: 11.5 }}
            >
              {process.env.REACT_APP_APP_NAME === ""
                ? "SmartSportHR"
                : process.env.REACT_APP_APP_NAME}
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
