import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, blueGrey, red } from "@mui/material/colors";
import { Button, Grid, FormControl, Typography } from "@mui/material";
import { Add, Edit, Delete, Restore } from "@mui/icons-material";

import { snackBarAction } from "../../../../store/features/snackBarSlice";
import { TableHead, TableBody } from "../../../../types/types";
import DashBoardCard from "../../../../components/card/DashBoardCard";
import Table from "../../../../components/table/Table";
import TransitionsModal from "../../../../components/modal/TransitionsModal";
import EmailService from "../../../../services/EmailService";

import "./EmailTemplate.css";

const EmailTableHead = ["Templates", "Status", " "];

const theme = createTheme({
  palette: {
    primary: red,
    secondary: blue,
    info: blueGrey,
  },
});

const renderHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const EmailTemplate = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [modal, setModal] = useState<any>();
  const [emailTemplates, setEmailTemplates] = useState<any>();
  const [emailTemplate, setEmailTemplate] = useState<any>({
    title: "",
  });

  const [editEmailTemplate, setEditEmailTemplate] = useState({
    title: "",
    subject: "",
    body: "",
    active: "",
    dateModified: "",
  });

  const handleOpen = async (
    e: React.MouseEvent<HTMLButtonElement>,
    item?: any
  ) => {
    if (e.currentTarget.name !== "add") {
      setEmailTemplate({
        title: item.title,
        uuid: item.uuid,
        dateDeleted: item.dateDeleted,
      });
    }
    setModal(e.currentTarget.name);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailTemplate({
      title: "",
    });
  };

  const handleDelete = async () => {
    try {
      if (emailTemplate.uuid) {
        const response = await EmailService.deleteEmailTemplate(
          emailTemplate.uuid
        );
        if (response) {
          getEmailTemplates();
          handleClose();
          dispatch(
            snackBarAction.open({
              message: "Email Template has successfully deleted",
              severity: "success",
            })
          );
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const handleEdit = async (e: any) => {
    setEditEmailTemplate({
      ...editEmailTemplate,
      [e.target.name]: e.target.value,
    });
  };

  const handleRestore = async () => {
    try {
      if (emailTemplate.uuid) {
        await EmailService.restoreEmailTemplate(emailTemplate.uuid);
        getEmailTemplates();
        handleClose();
        dispatch(
          snackBarAction.open({
            message: "Email Template has successfully restored",
            severity: "success",
          })
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const renderBody = (item: TableBody, index: number) => (
    <tr key={index}>
      <td className="w-[45%]">{item.title}</td>
      <td className="w-[30%]">{!item.dateDeleted ? "Active" : "Inactive"}</td>
      <td className="w-[25%] text-end action-column">
        {!item.dateDeleted ? (
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<Edit />}
                    onChange={(e) => handleEdit(e)}
                  >
                    <Link
                      to={`/admin/systemsettings/emailtemplate/edit/${item.uuid}`}
                    >
                      Edit
                    </Link>
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="small"
                    color="info"
                    startIcon={<Delete />}
                    onClick={(e) => handleOpen(e, item)}
                    name="delete"
                  >
                    delete
                  </Button>
                </Grid>
              </Grid>
            </ThemeProvider>
          </React.Fragment>
        ) : (
          <Button
            variant="contained"
            size="small"
            startIcon={<Restore />}
            onClick={(e) => handleOpen(e, item)}
            name="restore"
          >
            restore
          </Button>
        )}
      </td>
    </tr>
  );

  const getEmailTemplates = async () => {
    const response = await EmailService.getAllEmailTemplate();
    // const response = await EmailService.getActiveEmailTemplate();
    setEmailTemplates(response.data);
  };

  useEffect(() => {
    getEmailTemplates();
  }, []);

  return (
    emailTemplates && (
      <div className="email-template">
        <div className="flex justify-between items-center">
          <h2 className="page-header">Email Template</h2>
          <ThemeProvider theme={theme}>
            <Button
              className="tier-add-button email-template-add-button"
              name="add"
              variant="contained"
              size="large"
              color="primary"
              startIcon={<Add />}
            >
              <Link to={`/admin/systemsettings/emailtemplate/add`}>Add</Link>
            </Button>
          </ThemeProvider>
        </div>
        <div className="row tier-page">
          <div className="col-12">
            <DashBoardCard
              body={
                <Table
                  limit="10"
                  headData={EmailTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={emailTemplates}
                  renderBody={(item, index) => renderBody(item, index)}
                />
              }
            />
          </div>
        </div>
        <TransitionsModal
          open={open}
          handleClose={handleClose}
          children={
            <React.Fragment>
              <Typography
                id="transition-modal-add-tier"
                variant="h6"
                component="h2"
              >
                {modal === "delete"
                  ? "Delete"
                  : modal === "restore" && "Restore"}{" "}
                Email Template
              </Typography>
              <Typography id="modal-add-tier" sx={{ mt: 2, mb: 1 }}>
                {modal === "delete"
                  ? `Are you sure you want to delete "${emailTemplate.title}"?`
                  : modal === "restore" &&
                    `Are you sure you want to restore "${emailTemplate.title}"?`}
              </Typography>
              <FormControl fullWidth className="add-tier">
                <div
                  className={`flex justify-end ${
                    (modal === "delete" || modal === "restore") && "mt-6"
                  }`}
                >
                  <button
                    className="mr-2 cancel-tier-button"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="ml-2 action-tier-button"
                    onClick={
                      modal === "delete"
                        ? handleDelete
                        : modal === "restore"
                        ? handleRestore
                        : undefined
                    }
                  >
                    {modal === "delete"
                      ? "Delete"
                      : modal === "restore"
                      ? "Restore"
                      : undefined}
                  </button>
                </div>
              </FormControl>
            </React.Fragment>
          }
        ></TransitionsModal>
      </div>
    )
  );
};

export default EmailTemplate;
