import { useState, ChangeEvent } from "react";
import { Container, Tabs, Tab, Grid, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import ActiveJob from "./ActiveJob";
import ExpiredJob from "./ExpiredJob";
import DashBoardCard from "../../../components/card/DashBoardCard";

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

function JobList() {
  const [currentTab, setCurrentTab] = useState<string>("active");

  const tabs = [
    { value: "active", label: "Active" },
    { value: "expire", label: "Expired" },
  ];

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <DashBoardCard
      body={
        <div>
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12}>
                <TabsWrapper
                  onChange={handleTabsChange}
                  value={currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </TabsWrapper>
              </Grid>

              <Card className="!overflow-visible w-full">
                <CardContent>
                  <Grid item xs={12}>
                    {currentTab === "active" && <ActiveJob />}
                    {currentTab === "expire" && <ExpiredJob />}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Container>
        </div>
      }
    ></DashBoardCard>
  );
}

export default JobList;
