import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { snackBarAction } from "../../../store/features/snackBarSlice";
import CoachService from "../../../services/CoachService";
import userImage from "../../../assets/images/user.png";
import DashBoardCard from "../../../components/card/DashBoardCard";
import UserDetailSection from "../../../components/card/UserDetailSection";

const ViewCoach = () => {
  const dispatch = useDispatch();

  const { coachId } = useParams();
  const [coach, setCoach] = useState<any>();

  const getCoach = async () => {
    try {
      const response = await CoachService.getCoachById(coachId);
      setCoach(response.data);
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  useEffect(() => {
    getCoach();
  }, []);

  return (
    coach && (
      <div>
        <h2 className="page-header">Coach Directory</h2>
        <div className="row">
          <div className="col-12">
            <DashBoardCard
              body={
                <React.Fragment>
                  <img src={userImage} alt="" width="200px" className="mb-8" />
                  <div>
                    <div className="text-2xl font-semibold mb-1">
                      {coach.coachUser.map((item: any) => (
                        <React.Fragment key={item.uuid}>
                          {item.firstName} {item.lastName}
                        </React.Fragment>
                      ))}
                    </div>
                    <div>Melbourne, Victoria, Australia</div>
                  </div>
                  {/* <div className="text-lg font-medium">About</div> */}
                </React.Fragment>
              }
            />

            <DashBoardCard
              body={
                <UserDetailSection header="Coach Overview">
                  <div className="grid grid-cols-4 gap-2 mb-6">
                    <div className="font-semibold">Website</div>
                    <div className="col-span-3">
                      <a
                        href="https://www.smartsporthr.com/"
                        className="text-[#fb0b12] hover:underline
                "
                      >
                        https://www.smartsporthr.com/
                      </a>
                    </div>

                    <div className="font-semibold">Location</div>
                    <div className="col-span-3">Melbourne, Australia</div>
                  </div>

                  <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc in augue non magna malesuada lobortis sit amet sit amet
                    neque. Nam ut pellentesque urna, id imperdiet ante. Ut
                    varius dui nec nunc lobortis, ac ornare eros blandit. Nulla
                    lacinia nibh quis tellus aliquam, at vehicula magna
                    efficitur.
                  </div>
                </UserDetailSection>
              }
            />

            <DashBoardCard
              body={
                <UserDetailSection header="Experience">
                  Suspendisse hendrerit ante ut mi rutrum, ut lobortis sapien
                  interdum. Praesent quis nisl accumsan nisl porta ornare at eu
                  dui. Phasellus at dignissim felis. Nullam elit leo, tempor id
                  nulla et, vestibulum tincidunt est. Nullam ornare imperdiet
                  lectus vel dapibus. Nunc feugiat dolor ac facilisis
                  consectetur.
                </UserDetailSection>
              }
            />

            <DashBoardCard
              body={
                <UserDetailSection header="Education">
                  In sit amet blandit dolor. Praesent at arcu sagittis, blandit
                  lectus nec, ullamcorper lectus. Etiam felis neque, lacinia a
                  magna sed, vulputate vestibulum sapien. Phasellus tempus
                  ligula sit amet ante commodo, non auctor magna tincidunt.
                  Morbi blandit consectetur finibus. Donec maximus tellus ut
                  quam tincidunt semper. Aenean urna augue, posuere eget feugiat
                  sed, rhoncus sed eros. Morbi volutpat malesuada augue, ut
                  porta mi semper at.
                </UserDetailSection>
              }
            />

            <DashBoardCard
              body={
                <UserDetailSection header="Skills">
                  Pellentesque pretium iaculis diam eu malesuada. Lorem ipsum
                  dolor sit amet, consectetur adipiscing elit. Aliquam erat
                  volutpat. Sed molestie dui ipsum, at blandit mauris semper
                  sed. Donec porttitor, lorem non efficitur volutpat, ex urna
                  interdum nunc, nec euismod risus leo eget urna.
                </UserDetailSection>
              }
            />
          </div>
        </div>
      </div>
    )
  );
};

export default ViewCoach;
