import React, { useEffect, useState } from "react";

import OrganizationSummary from "./OrganizationSummary";
import OrganizationService from "../../../../services/OrganizationService";
import ProfilePicture from "../../Components/Profile/ProfilePicture";
import ProfileDetailCard from "../../Components/Profile/ProfileDetailCard";

const OrgDetails = (props: any) => {
  const { getProfileCompletionPercent } = props;

  const [organization, setOrganization] = useState<any>();

  const getOrgUser = async () => {
    const response = await OrganizationService.getLoggedInOrgProfile();
    setOrganization(response.data);
  };

  useEffect(() => {
    getOrgUser();
  }, []);

  return (
    organization && (
      <React.Fragment>
        <OrganizationSummary
          organization={organization}
          getOrgUser={getOrgUser}
          getProfileCompletionPercent={getProfileCompletionPercent}
        />
        <ProfilePicture
          sectionName="Logo"
          data={organization}
          subData="logo"
          getData={getOrgUser}
          getProfileCompletionPercent={getProfileCompletionPercent}
          fileType="logo"
          uploadPicture={OrganizationService.uploadLogo}
          downloadPicture={OrganizationService.downloadLogo}
          deletePicture={OrganizationService.deleteLogo}
          getPictureLink={OrganizationService.getLogoLink}
        />
        <ProfileDetailCard
          sectionName="value"
          add={OrganizationService.addOrgValue}
          get={OrganizationService.getAllOrgValues}
          update={OrganizationService.updateOrgValues}
          updateOne={OrganizationService.updateOrgValue}
          remove={OrganizationService.deleteOrgValue}
          getProfileCompletionPercent={getProfileCompletionPercent}
        />
        <ProfileDetailCard
          sectionName="philosophy"
          add={OrganizationService.addOrgPhilosophy}
          get={OrganizationService.getAllOrgPhilosophies}
          update={OrganizationService.updateOrfPhilosophies}
          updateOne={OrganizationService.updateOrgPhilosophy}
          remove={OrganizationService.deleteOrgPhilosophy}
          getProfileCompletionPercent={getProfileCompletionPercent}
        />
      </React.Fragment>
    )
  );
};

export default OrgDetails;
