import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PulseLoader from "react-spinners/PulseLoader";

import AuthService from "../../../services/AuthenticationService";
import { snackBarAction } from "../../../store/features/snackBarSlice";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const phoneRegExp = /^\d{10}$/;

const schema = yup.object({
  firstName: yup.string().required("Required field"),
  lastName: yup.string().required("Required field"),
  mobileNumber: yup
    .string()
    .required("Required field")
    .matches(phoneRegExp, "Invalid phone number"),
  email: yup.string().required("Required field").email("Invalid email address"),
  password: yup
    .string()
    .required("Required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Required field")
    .oneOf([yup.ref("password")], "Passwords must match"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "You need to accept the terms and conditions"),
});

type FormData = yup.InferType<typeof schema>;

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [signUpLoading, setSignUpLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setSignUpLoading(true);

    const user = {
      firstName: data.firstName,
      lastName: data.lastName,
      mobileNumber: data.mobileNumber,
      email: data.email,
      password: data.password,
      termsAndConditions: data.termsAndConditions,
    };

    try {
      const response = await AuthService.signup(user);
      if (response.status == 201) {
        dispatch(
          snackBarAction.open({
            message: "Coach Registration Successful!",
            severity: "success",
          })
        );

        setTimeout(() => {
          setSignUpLoading(false);
          reset();
          navigate("/success");
        }, 1000);
      } else {
        setSignUpLoading(false);
        dispatch(
          snackBarAction.open({
            message: "Coach Registration Failed!",
            severity: "error",
          })
        );
        return;
      }
    } catch (e: any) {
      console.log(JSON.parse(e.config.data).email);

      setTimeout(() => {
        setSignUpLoading(false);
        if (e.response.data.status === 500) {
          dispatch(
            snackBarAction.open({
              message: `${
                JSON.parse(e.config.data).email
              } is already taken. Please login to skip this step or use another email.`,
              severity: "error",
            })
          );
        } else {
          dispatch(
            snackBarAction.open({
              message: e.message,
              severity: "error",
            })
          );
        }
      }, 750);

      return;
    }
  });

  return (
    <div className="pb-24 main-page">
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Coach Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  className={`!mb-2 ${errors.firstName && "input-invalid"}`}
                  {...register("firstName")}
                />
                {errors.firstName && (
                  <p className="text-red-700">* {errors.firstName?.message}</p>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  autoComplete="family-name"
                  className={`!mb-2 ${errors.lastName && "input-invalid"}`}
                  {...register("lastName")}
                />
                {errors.lastName && (
                  <p className="text-red-700">* {errors.lastName?.message}</p>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="mobileNumber"
                  label="Mobile Number"
                  autoComplete="mobile"
                  className={`!mb-2 ${errors.mobileNumber && "input-invalid"}`}
                  {...register("mobileNumber", { required: true })}
                />
                {errors.mobileNumber && (
                  <p className="text-red-700">
                    * {errors.mobileNumber?.message}
                  </p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  autoComplete="email"
                  className={`!mb-2 ${errors.email && "input-invalid"}`}
                  {...register("email")}
                />
                {errors.email && (
                  <p className="text-red-700">* {errors.email?.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  className={`${errors.password && "input-invalid"}`}
                  {...register("password")}
                />
                {errors.password && (
                  <p className="text-red-700">* {errors.password?.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="confirm-password"
                  className={`${errors.confirmPassword && "input-invalid"}`}
                  {...register("confirmPassword")}
                />
                {errors.confirmPassword && (
                  <p className="text-red-700">
                    * {errors.confirmPassword?.message}
                  </p>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="termsAndConditions"
                      color="primary"
                      className={`${
                        errors.termsAndConditions && "input-invalid"
                      }`}
                      {...register("termsAndConditions")}
                    />
                  }
                  label="I agree to the terms and conditions set forth by SmartSportHR."
                />
                {errors.termsAndConditions && (
                  <p className="text-red-700">
                    * {errors.termsAndConditions?.message}
                  </p>
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {!signUpLoading ? (
                "Sign Up"
              ) : (
                <PulseLoader
                  color="#fff"
                  size={9}
                  className="padding-spinner"
                />
              )}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin">Already have an account? Sign in</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default SignUp;
