import { createSlice } from "@reduxjs/toolkit";

const initialThemeState = {
  theme: null,
  color: null,
};

const modeSlice = createSlice({
  name: "mode",
  initialState: initialThemeState,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload;
    },
    setColor(state, action) {
      state.color = action.payload;
    },
  },
});

export const modeAction = modeSlice.actions;

export default modeSlice.reducer;
