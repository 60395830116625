import { createSlice } from "@reduxjs/toolkit";
import user_image from "../../assets/images/user.png";

const profilePicture = JSON.parse(
  localStorage.getItem("profilePicture") as string
);

const initialProfilePictureState = {
  profilePicture: profilePicture ? profilePicture : user_image,
};

const profilePictureSlice = createSlice({
  name: "profilePicture",
  initialState: initialProfilePictureState,
  reducers: {
    set(state, action) {
      state.profilePicture = action.payload;
    },
  },
});

export const profilePictureAction = profilePictureSlice.actions;

export default profilePictureSlice.reducer;
