import React, { FC } from "react";

import SignUp from "../pages/Main/Coach/SignUp";
import SignIn from "../pages/Main/Coach/SignIn";
import OrganisationSignUp from "../pages/Main/Organisation/OrganisationSignUp";
import OrganisationSignIn from "../pages/Main/Organisation/OrganisationSignIn";
import SuperAdminSignIn from "../pages/Main/SuperAdmin/SuperAdminSignIn";

import Dashboard from "../pages/Dashboard/Coach/Dashboard";

import OrgDashboard from "../pages/Dashboard/Organisation/Dashboard";
import OrgCustomers from "../pages/Dashboard/Organisation/Customers";
import OrgProducts from "../pages/Dashboard/Organisation/Products";
import OrgCreateJob from "../pages/Dashboard/Organisation/PostAJob/CreateJob";
import OrgJobList from "../pages/Dashboard/Organisation/JobList";
import OrgLinkedUsers from "../pages/Dashboard/Organisation/LinkedUsers/LinkedUsers";
import Job from "../pages/Dashboard/Organisation/Job";
import OrgProfile from "../pages/Profile/Organization/OrgProfile/OrgProfile";
import OrgAccount from "../pages/Profile/Organization/OrgAccount/OrgAccount";

import SuperAdminDashboard from "../pages/Dashboard/SuperAdmin/Dashboard";
import SuperAdminOrganizations from "../pages/Dashboard/SuperAdmin/Organizations";
import SuperAdminCoaches from "../pages/Dashboard/SuperAdmin/Coaches";
import SuperAdminViewOrganization from "../pages/Dashboard/SuperAdmin/ViewOrganization";
import SuperAdminViewCoach from "../pages/Dashboard/SuperAdmin/ViewCoach";
import SuperAdminTier from "../pages/Dashboard/SuperAdmin/Tier";
import SuperAdminJobList from "../pages/Dashboard/SuperAdmin/JobList";
import SuperAdminJob from "../pages/Dashboard/SuperAdmin/Job";
import SuperAdminSports from "../pages/Dashboard/SuperAdmin/Sports";
import SuperAdminAccount from "../pages/Profile/SuperAdmin/SuperAdminAccount/SuperAdminAccount";

import CoachProfile from "../pages/Profile/Coach/CoachProfile/CoachProfile";
import CoachAccount from "../pages/Profile/Coach/CoachAccount/CoachAccount";
import AppliedJobs from "../pages/Dashboard/Coach/AppliedJobs";
import SearchJobs from "../pages/Dashboard/Coach/SearchJobs";
import MatchedJobs from "../pages/Dashboard/Coach/MatchedJobs";
import ViewJob from "../pages/Dashboard/Coach/ViewJob";
import AppliedViewJob from "../pages/Dashboard/Coach/AppliedViewJob";
import EmailTemplate from "../pages/Dashboard/SuperAdmin/SystemSettings/EmailTemplate";
import EditEmailTemplate from "../pages/Dashboard/SuperAdmin/SystemSettings/EditEmailTemplate";
import AddEmailTemplate from "../pages/Dashboard/SuperAdmin/SystemSettings/AddEmailTemplate";
import Child2 from "../pages/Dashboard/SuperAdmin/SystemSettings/Child2";

import ForgotPassword from "../pages/Main/Coach/ForgotPassword";
import OrganisationForgotPassword from "../pages/Main/Organisation/OrganisationForgotPassword";
import SuperAdminForgotPassword from "../pages/Main/SuperAdmin/SuperAdminForgotPassword";
import EmailReceived from "../pages/Main/EmailReceived";
import CoachSuccess from "../pages/Main/Coach/CoachSuccess";
import OrgSuccess from "../pages/Main/Organisation/OrgSuccess";
import ResetPassword from "../pages/Main/ResetPassword";
import ConfirmAcccount from "../pages/Main/ConfirmAccount";
import Analytics from "../pages/Dashboard/Organisation/Analytics";

interface Route {
  key?: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
}

export const orgRoutes: Array<Route> = [
  {
    key: "orgsignup-route",
    title: "SignUp",
    path: "/org/signup",
    enabled: true,
    component: OrganisationSignUp,
  },
  {
    key: "orgsignin-route",
    title: "SignIn",
    path: "/org/signin",
    enabled: true,
    component: OrganisationSignIn,
  },
];

// Super Admin Routes
export const superAdminRoutes: Array<Route> = [
  {
    key: "superadminsignin-route",
    title: "Super Admin SignIn",
    path: "/admin/signin",
    enabled: true,
    component: SuperAdminSignIn,
  },
];

export const coachRoutes: Array<Route> = [
  {
    key: "coachsignup-route",
    title: "Coach SignUp",
    path: "/signup",
    enabled: true,
    component: SignUp,
  },
  {
    key: "coachsignin-route",
    title: "Coach SignIn",
    path: "/signin",
    enabled: true,
    component: SignIn,
  },
];

export const passwordResetRoutes: Array<Route> = [
  {
    key: "password-route",
    title: "Forgot password",
    path: "/forgotpassword",
    enabled: true,
    component: ForgotPassword,
  },
  {
    key: "orgpassword-route",
    title: "Forgot password",
    path: "/org/forgotpassword",
    enabled: true,
    component: OrganisationForgotPassword,
  },
  {
    key: "orgpassword-route",
    title: "Forgot password",
    path: "/admin/forgotpassword",
    enabled: true,
    component: SuperAdminForgotPassword,
  },
  {
    key: "emailresetreceived-route",
    title: "Email Received",
    path: "/email-reset-received",
    enabled: true,
    component: EmailReceived,
  },
  {
    key: "resetpassword-route",
    title: "Reset Password",
    path: "/resetpassword/:token",
    enabled: true,
    component: ResetPassword,
  },
  {
    key: "confirmaccount-route",
    title: "Confirm Account",
    path: "/confirmAccount/:token",
    enabled: true,
    component: ConfirmAcccount,
  },
  {
    key: "coachsuccess-route",
    title: "Coach Success",
    path: "/success",
    enabled: true,
    component: CoachSuccess,
  },
  {
    key: "orgsuccess-route",
    title: "Org Success",
    path: "/orgsuccess",
    enabled: true,
    component: OrgSuccess,
  },
];

export const dashboardRoutes: Array<Route> = [
  {
    key: "dashboard-route",
    title: "Dashboard",
    path: "/user/dashboard",
    enabled: true,
    component: Dashboard,
  },
  {
    key: "appliedjobs-route",
    title: "Applied Jobs",
    path: "/user/appliedjobs",
    enabled: true,
    component: AppliedJobs,
  },
  {
    key: "coachprofile-route",
    title: "CoachProfile",
    path: "/user/coachprofile",
    enabled: true,
    component: CoachProfile,
  },
  {
    key: "accountsettings-route",
    title: "AccountSettings",
    path: "/user/accountsettings",
    enabled: true,
    component: CoachAccount,
  },
  // View Job Page
  {
    key: "viewjob-route",
    title: "View Job",
    path: "/user/viewjob/:jobId",
    enabled: true,
    component: ViewJob,
  },
  // Applied View Job Page
  {
    key: "appliedviewjob-route",
    title: "Applied View Job",
    path: "/user/appliedviewjob/:applyJobId",
    enabled: true,
    component: AppliedViewJob,
  },
  {
    key: "searchjobs-route",
    title: "Search For Jobs",
    path: "/user/searchjobs",
    enabled: true,
    component: SearchJobs,
  },
  {
    key: "matchedjobs-route",
    title: "Matched Jobs",
    path: "/user/matchedjobs",
    enabled: true,
    component: MatchedJobs,
  },
];

export const orgDashboardRoutes: Array<Route> = [
  {
    key: "orgdashboard-route",
    title: "Org Dashboard",
    path: "/org/dashboard",
    enabled: true,
    component: OrgDashboard,
  },
  {
    key: "customers-route",
    title: "Customer",
    path: "/org/customers",
    enabled: true,
    component: OrgCustomers,
  },
  {
    key: "products-route",
    title: "Products",
    path: "/org/products",
    enabled: true,
    component: OrgProducts,
  },
  {
    key: "createjob-route",
    title: "Post a Job",
    path: "/org/createjob",
    enabled: true,
    component: OrgCreateJob,
  },
  {
    key: "joblist-route",
    title: "Job List",
    path: "/org/joblist",
    enabled: true,
    component: OrgJobList,
  },
  {
    key: "analytics-route",
    title: "Analytcs",
    path: "/org/analytics",
    enabled: true,
    component: Analytics,
  },
  {
    key: "job-route",
    title: "Job",
    path: "/org/joblist/:jobId",
    enabled: true,
    component: Job,
  },
  {
    key: "linked-users-route",
    title: "Linked Users",
    path: "/org/linked_users",
    enabled: true,
    component: OrgLinkedUsers,
  },
  {
    key: "orgprofile-route",
    title: "Organization Profile",
    path: "/org/profile",
    enabled: true,
    component: OrgProfile,
  },
  {
    key: "orgaccountsettings-route",
    title: "AccountSettings",
    path: "/org/accountsettings",
    enabled: true,
    component: OrgAccount,
  },
];

export const superAdminDashboardRoutes: Array<Route> = [
  {
    key: "superadminuserdashboard-route",
    title: "Super Admin User Dashboard",
    path: "/admin/dashboard",
    enabled: true,
    component: SuperAdminDashboard,
  },
  {
    key: "superadminuserorganizations-route",
    title: "Super Admin User Organizations",
    path: "/admin/organizations",
    enabled: true,
    component: SuperAdminOrganizations,
  },
  {
    key: "superadminuservieworganization-route",
    title: "Super Admin User View Organization",
    path: "/admin/organization/:organizationId",
    enabled: true,
    component: SuperAdminViewOrganization,
  },
  {
    key: "superadminusercoaches-route",
    title: "Super Admin User Coaches",
    path: "/admin/coaches",
    enabled: true,
    component: SuperAdminCoaches,
  },
  {
    key: "superadminuserviewcoach-route",
    title: "Super Admin User View Coach",
    path: "/admin/coach/:coachId",
    enabled: true,
    component: SuperAdminViewCoach,
  },
  {
    key: "superadminusertier-route",
    title: "Super Admin User Tier",
    path: "/admin/tier/",
    enabled: true,
    component: SuperAdminTier,
  },
  {
    key: "superadminjoblist-route",
    title: "Super Admin Job List",
    path: "/admin/joblist",
    enabled: true,
    component: SuperAdminJobList,
  },
  {
    key: "superadminjob-route",
    title: "Super Admin Job",
    path: "/admin/joblist/:jobId",
    enabled: true,
    component: SuperAdminJob,
  },
  {
    key: "systemsettings-emailtemplate-route",
    title: "Email Template",
    path: "/admin/systemsettings/emailtemplate",
    enabled: true,
    component: EmailTemplate,
  },
  {
    key: "systemsettings-editemailtemplate-route",
    title: "Edit Email Template",
    path: "/admin/systemsettings/emailtemplate/edit/:templateId",
    enabled: true,
    component: EditEmailTemplate,
  },
  {
    key: "systemsettings-addemailtemplate-route",
    title: "Add Email Template",
    path: "/admin/systemsettings/emailtemplate/add",
    enabled: true,
    component: AddEmailTemplate,
  },
  {
    key: "systemsettings-child2-route",
    title: "Child 2",
    path: "/admin/systemsettings/child2",
    enabled: true,
    component: Child2,
  },
  {
    key: "superadminsports-route",
    title: "Sports",
    path: "/admin/sports",
    enabled: true,
    component: SuperAdminSports,
  },
  {
    key: "superadminaccountsettings-route",
    title: "AccountSettings",
    path: "/admin/accountsettings",
    enabled: true,
    component: SuperAdminAccount,
  },
];
