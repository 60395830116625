import React from "react";
import Table from "../../../../components/table/Table";
import customerList from "../../../../assets/JsonData/customers-list.json";
import { TableHead, TableBody } from "../../../../types/types";

import "./LinkedUsers.css";

const customerTableHead = ["", "name", "email", "phone", "remove user"];

const renderHead = (item: TableHead, index: number) => {
  if (item === "remove user") {
    return (<th className="remove-user" key={index}>remove user</th>);
  } else {
    return (<th key={index}>{item}</th>);
  }
};

const renderBody = (item: TableBody, index: number) => (
  <tr key={index}>
    <td>{item.id}</td>
    <td>{item.name}</td>
    <td>{item.email}</td>
    <td>{item.phone}</td>
    <td className="remove-user">
      <i className="bx bxs-user-x"></i>
    </td>
  </tr>
);

const LinkedUsers = () => {
  return (
    <div>
      <h2 className="page-header">Linked Users</h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <Table
                limit="10"
                headData={customerTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={customerList}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedUsers;
