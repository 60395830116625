import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import InputField from "../../../components/Fields/InputField";
import { Link } from "react-router-dom";

import JobService from "../../../services/JobService";

export default function Album() {
  const [jobs, setJobs] = useState<any>();

  const getJobData = async () => {
    const response = await JobService.getAllExpired();
    setJobs(response.data);
  };

  const searchJobData = async (searchCriteria: any) => {
    const response = await JobService.searchExpiredJobs(searchCriteria);
    setJobs(response.data);
  };
  
  useEffect(() => {
    getJobData();
  }, []);

  return (
    jobs && (
    <div>
      <main>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "20px",
            fontWeight: "bold",
            color: "black",
            height: "10vh",
          }}
        >
          <h1>Expired Job Lists</h1>
        </div>

        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container>
            <div className="pt-8">
              <InputField onSearch={searchJobData}/>
            </div>
          </Container>
        </Box>

        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {jobs &&
                jobs.map((job: any) => (
              <Grid item key={job.uuid} xs={12} sm={6} md={4}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {job.role}
                    </Typography>
                    <Typography>{job.organization.companyName}</Typography>
                    <Typography>Job Type:{" "}
                          {job.jobPostRoleTypes.map((item: any) => (
                            <React.Fragment key={item.uuid}>
                              {item.name}
                            </React.Fragment>
                          ))}</Typography>
                    <Typography>Location: {job.city}</Typography>
                  </CardContent>
                  <CardActions>
                  <Link to={"/org/joblist/" + job.uuid}>
                    <Button size="small">View</Button>
                  </Link>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </div>
    )
  );
}
