import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../../store";
import OrganizationService from "../../../../services/OrganizationService";
import ProfileInfomation from "../../Components/Account/ProfileInfomation";
import ContactInformation from "../../Components/Account/ContactInformation";
import LoginInformation from "../../Components/Account/LoginInfomation/LoginInformation";
import AccountSetting from "../../Components/Account/AccountSetting";

const OrgAccountDetails = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const [org, setOrg] = useState<any>();

  const getOrg = async () => {
    const response = await OrganizationService.getLoggedInOrgProfile();
    setOrg(response.data.orgUser[0]);
  };

  useEffect(() => {
    getOrg();
  }, []);

  return (
    <React.Fragment>
      <ProfileInfomation user={user} data={org} getData={getOrg} />
      <ContactInformation user={user} data={org} getData={getOrg} />
      <LoginInformation data={org} />
      <AccountSetting />
    </React.Fragment>
  );
};

export default OrgAccountDetails;
