import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Table from "../../../components/table/Table";
import { TableHead, TableBody } from "../../../types/types";
import CoachService from "../../../services/CoachService";
import DashBoardCard from "../../../components/card/DashBoardCard";

const CoachTableHead = ["first name", "last name", "email"];

const renderHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const Coaches = () => {
  const navigate = useNavigate();

  const [coaches, setCoaches] = useState<any>();

  // Navitage to View Coach Page and pass organization id
  const clickHandler = (coachId: string | number | undefined) => {
    navigate(`/admin/coach/${coachId}`);
  };

  const renderBody = (item: TableBody, index: number) => (
    <tr key={index} onClick={() => clickHandler(item.uuid)}>
      <td>{item.coachUser[0].firstName}</td>
      <td>{item.coachUser[0].lastName}</td>
      <td>{item.coachUser[0].email}</td>
    </tr>
  );

  const getCoaches = async () => {
    const allCoaches = await CoachService.getAllCoaches();
    setCoaches(allCoaches.data);
  };

  useEffect(() => {
    getCoaches();
  }, []);

  return (
    coaches && (
      <React.Fragment>
        <h2 className="page-header">Coaches</h2>
        <div className="row">
          <div className="col-12">
            <DashBoardCard
              body={
                <Table
                  limit="10"
                  headData={CoachTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={coaches}
                  renderBody={(item, index) => renderBody(item, index)}
                />
              }
            />
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default Coaches;
