import Api from "./Api";
import authHeader from "./AuthHeader";


  const registerEmailTemplate = (emailTemplate: any) => {
    return Api().post("/api/emailTemplate/register", emailTemplate, {
      headers: authHeader(),
    });
  };

  const getAllEmailTemplate = () => {
    return Api().get("/api/emailTemplate/getAll",{
      headers: authHeader(),
      params: {
        page: 0,
        size: 10,
      },
    });
  }

  const getActiveEmailTemplate = () => {
    return Api().get("/api/emailTemplate/getActive", {
      headers: authHeader(),
      params: {
        page: 0,
        size: 10,
      },
    });
  };

  const getEmailTemplateById = (templateId: any) => {
    return Api().get(`/api/emailTemplate/${templateId}`, {
      headers: authHeader(),
    });
  };

  const updateEmailTemplate = (emailTemplate: any) => {
    return Api().put(`/api/emailTemplate/update/${emailTemplate.uuid}`, emailTemplate, {
      headers: authHeader(),
    });
  };
  
  const deleteEmailTemplate = (id: any) => {
    return Api().delete(`/api/emailTemplate/delete/${id}`, {
      headers: authHeader(),
    });
  };
  
  const restoreEmailTemplate = (id: string) => {
    return Api().put(`/api/emailTemplate/restore/${id}`, null, {
      headers: authHeader(),
    });
  };

  const EmailService = {
      getAllEmailTemplate,
      registerEmailTemplate,
      getActiveEmailTemplate,
      getEmailTemplateById,
      updateEmailTemplate,
      deleteEmailTemplate,
      restoreEmailTemplate
    };

  export default EmailService;





