import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import { Button, Checkbox, FormGroup, FormControlLabel } from "@mui/material";

import TextField from "../../../../components/Fields/TextField";
import CoachService from "../../../../services/CoachService";
import { snackBarAction } from "../../../../store/features/snackBarSlice";
import UserProfileCard from "../../../../components/card/UserProfileCard";

const CareerHistory = () => {
  const dispatch = useDispatch();
  const [showSave, setShowSave] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [allData, setAllData] = useState<any>();
  const [originalCareerItems, setOriginalCareerItems] = useState<any>();
  const [updateOneData, setUpdateOneData] = useState<any>();
  const [data, setData] = useState({
    jobTitle: "",
    company: "",
    dateStart: "",
    dateEnd: "",
  });

  const schema = yup.object({
    jobTitle: yup.string().required("Required field"),
    company: yup.string().required("Required field"),
    dateStart: yup.date().typeError("Invalid date").required("Required field"),
    dateEnd: checked
      ? yup.date().nullable().typeError("Invalid date")
      : yup.date().typeError("Invalid date").required("Required field"),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema as any),
  });

  const handleClick = () => {
    if (showUpdate) {
      setShowSave(false);
    } else {
      setShowSave(!showSave);
    }
    setChecked(false);
    setData({
      jobTitle: "",
      company: "",
      dateStart: "",
      dateEnd: "",
    });
    getAllData();
  };

  const handleCheck = (e: any) => {
    setChecked(!checked);
    setData({ ...data, dateEnd: "" });
    setValue("dateEnd", null);
  };

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setValue(e.target.name, e.target.value);
  };

  const handleSave = async () => {
    if (!showUpdate) {
      const result = await trigger([
        "jobTitle",
        "company",
        "dateStart",
        "dateEnd",
      ]);
      if (!result) {
        return;
      }

      try {
        await CoachService.addCareerHistory(data);
        getAllData();
        handleClick();
        dispatch(
          snackBarAction.open({
            message: "Career history has been successfully added",
            severity: "success",
          })
        );
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            snackBarAction.open({
              message: e.message,
              severity: "error",
            })
          );
        }
      }
    } else if (showUpdate) {
      let updateData = allData.map((obj: any) => {
        return {
          jobTitle: obj.jobTitle,
          company: obj.company,
          dateStart: obj.dateStart,
          dateEnd: obj.dateEnd ? obj.dateEnd : "",
        };
      });
      try {
        await CoachService.updateCareerHistories(updateData);
        getAllData();
        setShowUpdate(false);
        dispatch(
          snackBarAction.open({
            message: "Career history has been successfully updated",
            severity: "success",
          })
        );
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            snackBarAction.open({
              message: e.message,
              severity: "error",
            })
          );
        }
      }
    }
  };

  const handleShowUpdate = () => {
    setShowSave(false);
    setShowUpdate(!showUpdate);
    setUpdateOneData(null);
    getAllData();
  };

  const updateInput = (e: any, careerItem: any) => {
    const newData = allData.map((obj: any) => {
      if (obj.uuid === careerItem.uuid) {
        if (e.target.name === "dateEnd" && e.target.value === "") {
          setChecked(true);
        }
        return {
          ...obj,
          [e.target.name]: e.target.value,
        };
      }
      return obj;
    });
    setAllData(newData);

    if (updateOneData) {
      if (e.target.name === "dateEnd" && e.target.value === "") {
        setValue("dateEnd", null);
      } else {
        setValue(e.target.name, e.target.value);
      }

      trigger();
    }
  };

  const handleUpdate = (item: any, index: number) => {
    setShowSave(false);
    setUpdateOneData(item.uuid);
    getAllData();
    reset();
    setValue("jobTitle", item.jobTitle);
    setValue("company", item.company);
    setValue("dateStart", new Date(item.dateStart));

    if (!item.dateEnd) {
      setChecked(true);
      setValue("dateEnd", null);
    } else {
      setChecked(false);
      setValue("dateEnd", new Date(item.dateEnd));
    }
  };

  const handleCanncelUpdateOne = () => {
    setUpdateOneData(null);
    getAllData();
    reset();
  };

  const handleSaveUpdateOne = async (item: any) => {
    const result = await trigger([
      "jobTitle",
      "company",
      "dateStart",
      "dateEnd",
    ]);
    if (!result) {
      return;
    }

    try {
      await CoachService.updateCareerHistory(item.uuid, item);
      getAllData();
      setUpdateOneData(null);
      reset();
      dispatch(
        snackBarAction.open({
          message: "Career history has been successfully updated",
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const updateCheck = (careerItem: any, index: number) => {
    if (!allData[index].dateEnd) {
      const newData = allData.map((obj: any) => {
        if (obj.uuid === careerItem.uuid) {
          return {
            ...obj,
            dateEnd: originalCareerItems[index].dateEnd
              ? originalCareerItems[index].dateEnd
              : Date.now(),
          };
        }
        return obj;
      });

      setAllData(newData);
    } else {
      const newData = allData.map((obj: any) => {
        if (obj.uuid === careerItem.uuid) {
          return { ...obj, dateEnd: "" };
        }
        return obj;
      });
      setAllData(newData);
    }

    if (allData[index].dateEnd) {
      setChecked(true);
      setValue("dateEnd", null);
    } else {
      setChecked(false);
      if (originalCareerItems[index].dateEnd) {
        setValue("dateEnd", originalCareerItems[index].dateEnd);
      } else {
        setValue("dateEnd", new Date());
      }
    }
  };

  const handleDelete = async (careerHistoryId: string) => {
    try {
      await CoachService.deleteCareerHistory(careerHistoryId);
      getAllData();
      dispatch(
        snackBarAction.open({
          message: "Career history has been successfully deleted",
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const getAllData = async () => {
    //get user career history
    const response = await CoachService.getAllCareerHistory();
    setAllData(response.data);
    setOriginalCareerItems(response.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <UserProfileCard
      title={
        <React.Fragment>
          <span className="card-heading">Career History</span>
          {/* <Button onClick={handleShowUpdate}>
            <i className="bx bxs-edit"></i>
          </Button> */}
        </React.Fragment>
      }
      body={
        <React.Fragment>
          {allData &&
            allData.map((careerItem: any, index: number) => (
              <React.Fragment key={index}>
                {!showUpdate ? (
                  <React.Fragment>
                    {updateOneData === careerItem.uuid ? (
                      <div className="flex">
                        <div className="w-[95%] flex justify-between">
                          <div className="w-[78.5%] flex justify-between">
                            <div className="w-[38.5%]">
                              <TextField
                                required
                                margin="normal"
                                fullWidth
                                label="Job Title"
                                name="jobTitle"
                                autoComplete="given-title"
                                autoFocus
                                value={careerItem.jobTitle}
                                onChange={(e) => {
                                  updateInput(e, careerItem);
                                }}
                              />
                              {errors.jobTitle && (
                                <p className="text-red-700">
                                  * {errors.jobTitle.message}
                                </p>
                              )}
                              <TextField
                                required
                                margin="normal"
                                fullWidth
                                name="company"
                                label="Company Name"
                                autoComplete="given-company"
                                autoFocus
                                value={careerItem.company}
                                onChange={(e) => {
                                  updateInput(e, careerItem);
                                }}
                              />
                              {errors.company && (
                                <p className="text-red-700">
                                  * {errors.company.message}
                                </p>
                              )}
                            </div>
                            <div className="w-[58.5%]">
                              <div className="inline-flex  justify-between w-full">
                                <div className="w-[47.5%]">
                                  <TextField
                                    required
                                    label="Start Date"
                                    type={"date"}
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    name="dateStart"
                                    autoComplete="startdate"
                                    variant="standard"
                                    value={moment(careerItem.dateStart).format(
                                      "YYYY-MM-DD"
                                    )}
                                    onChange={(e) => updateInput(e, careerItem)}
                                  />
                                  {errors.dateStart && (
                                    <p className="text-red-700 w-full">
                                      * {errors.dateStart.message}
                                    </p>
                                  )}
                                </div>
                                {!careerItem.dateEnd ? (
                                  <div className="!w-[47.5%] self-center">
                                    - Present
                                  </div>
                                ) : (
                                  <div className="w-[47.5%]">
                                    <TextField
                                      required
                                      id="enddate"
                                      label="End Date"
                                      type={"date"}
                                      InputLabelProps={{ shrink: true }}
                                      fullWidth
                                      name="dateEnd"
                                      autoComplete="enddate"
                                      variant="standard"
                                      value={moment(careerItem.dateEnd).format(
                                        "YYYY-MM-DD"
                                      )}
                                      onChange={(e) =>
                                        updateInput(e, careerItem)
                                      }
                                    />
                                    {errors.dateEnd && (
                                      <p className="text-red-700 w-full">
                                        * {errors.dateEnd.message}
                                      </p>
                                    )}
                                  </div>
                                )}
                              </div>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        !careerItem.dateEnd ? true : false
                                      }
                                      onChange={() =>
                                        updateCheck(careerItem, index)
                                      }
                                    />
                                  }
                                  label="Current Role"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          <div className="w-[21.5%] flex justify-end items-start pt-[22.5px]">
                            <Button
                              className="profile_button"
                              onClick={() => handleSaveUpdateOne(careerItem)}
                            >
                              Save
                            </Button>
                            <Button
                              className="profile_button"
                              onClick={handleCanncelUpdateOne}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <span className="text-[31px] flex justify-end w-[5%] pt-[28px]">
                          <i
                            className="bx bx-trash cursor_pointer"
                            onClick={() => handleDelete(careerItem.uuid)}
                          ></i>
                        </span>
                      </div>
                    ) : (
                      <div className="flex justify-between">
                        <div className="w-[65.5%]">
                          <p>{careerItem.jobTitle}</p>
                          <p>{careerItem.company}</p>
                        </div>
                        <span className="w-[27.5%]">
                          {moment(careerItem.dateStart).format("DD/MM/YYYY")} -{" "}
                          {careerItem.dateEnd
                            ? moment(careerItem.dateEnd).format("DD/MM/YYYY")
                            : "Present"}
                        </span>
                        <span className="text-[17.5px] flex justify-end w-[3.5%] pt-[2px] items-start">
                          <i
                            className="bx bxs-pencil cursor_pointer"
                            onClick={() => handleUpdate(careerItem, index)}
                          ></i>
                        </span>
                        <span className="text-[20px] flex justify-end w-[3.5%]">
                          <i
                            className="bx bx-x cursor_pointer"
                            onClick={() => handleDelete(careerItem.uuid)}
                          ></i>
                        </span>
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <div className="flex justify-between">
                    <div className="w-[90%] flex justify-between">
                      <div className="w-[45%]">
                        <TextField
                          required
                          margin="normal"
                          fullWidth
                          label="Job Title"
                          name="jobTitle"
                          autoComplete="given-title"
                          autoFocus
                          value={careerItem.jobTitle}
                          onChange={(e) => {
                            updateInput(e, careerItem);
                          }}
                        />
                        <TextField
                          required
                          margin="normal"
                          fullWidth
                          name="company"
                          label="Company Name"
                          autoComplete="given-company"
                          autoFocus
                          value={careerItem.company}
                          onChange={(e) => {
                            updateInput(e, careerItem);
                          }}
                        />
                      </div>
                      <div className="w-[45%]">
                        <div className="inline-flex items-center justify-between w-full">
                          <TextField
                            required
                            label="Start Date"
                            type={"date"}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            name="dateStart"
                            autoComplete="startdate"
                            variant="standard"
                            value={moment(careerItem.dateStart).format(
                              "YYYY-MM-DD"
                            )}
                            className="!w-[47.5%]"
                            onChange={(e) => updateInput(e, careerItem)}
                          />
                          {!careerItem.dateEnd ? (
                            <div className="!w-[47.5%]"> - Present</div>
                          ) : (
                            <TextField
                              required
                              id="enddate"
                              label="End Date"
                              type={"date"}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              name="dateEnd"
                              autoComplete="enddate"
                              variant="standard"
                              value={moment(careerItem.dateEnd).format(
                                "YYYY-MM-DD"
                              )}
                              className="!w-[47.5%]"
                              onChange={(e) => updateInput(e, careerItem)}
                            />
                          )}
                        </div>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!careerItem.dateEnd ? true : false}
                                onChange={() => updateCheck(careerItem, index)}
                              />
                            }
                            label="Current Role"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <span className="text-[31px] flex justify-end items-center w-[10%]">
                      <i
                        className="bx bx-trash cursor_pointer"
                        onClick={() => handleDelete(careerItem.uuid)}
                      ></i>
                    </span>
                  </div>
                )}
                <br></br>
              </React.Fragment>
            ))}
          <div>
            {!updateOneData && (
              <React.Fragment>
                {!showUpdate ? (
                  <React.Fragment>
                    {!showSave && (
                      <Button onClick={handleClick} className="profile_button">
                        Add Role
                      </Button>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button className="profile_button" onClick={handleSave}>
                      Save
                    </Button>
                    <Button
                      className="profile_button"
                      onClick={handleShowUpdate}
                    >
                      Cancel
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      }
      body_2={
        <React.Fragment>
          {showSave && (
            <div className="c_card mb-5">
              <div className="card-body">
                <form className="w-full mt-2">
                  <TextField
                    margin="normal"
                    fullWidth
                    id="jobtitle"
                    label="Job Title"
                    name="jobTitle"
                    autoComplete="given-title"
                    autoFocus
                    value={data.jobTitle}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors.jobTitle && (
                    <p className="text-red-700">* {errors.jobTitle.message}</p>
                  )}
                  <TextField
                    margin="normal"
                    fullWidth
                    name="company"
                    label="Company Name"
                    id="companyname"
                    autoComplete="given-company"
                    value={data.company}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors.company && (
                    <p className="text-red-700">* {errors.company.message}</p>
                  )}
                  <TextField
                    id="startdate"
                    label="Start Date"
                    type={"date"}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="dateStart"
                    autoComplete="startdate"
                    variant="standard"
                    value={data.dateStart}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors.dateStart && (
                    <p className="text-red-700">* {errors.dateStart.message}</p>
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={checked} onChange={handleCheck} />
                      }
                      label="Current Role"
                    />
                  </FormGroup>
                  {!checked && (
                    <React.Fragment>
                      <TextField
                        id="enddate"
                        label="End Date"
                        InputLabelProps={{ shrink: true }}
                        type={"date"}
                        fullWidth
                        name="dateEnd"
                        autoComplete="enddate"
                        variant="standard"
                        value={data.dateEnd}
                        onChange={(e) => handleChange(e)}
                        className="!mb-2"
                      />
                      {errors.dateEnd && (
                        <p className="text-red-700">
                          * {errors.dateEnd.message}
                        </p>
                      )}
                    </React.Fragment>
                  )}
                  <div>
                    <Button className="profile_button" onClick={handleSave}>
                      Save
                    </Button>
                    <Button className="profile_button" onClick={handleClick}>
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </React.Fragment>
      }
    />
  );
};

export default CareerHistory;
