import React, { useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PulseLoader from "react-spinners/PulseLoader";

import AuthService from "../../../services/AuthenticationService";
import UserService from "../../../services/UserService";
import { authAction } from "../../../store/features/authSlice";
import { snackBarAction } from "../../../store/features/snackBarSlice";
import { LocationState } from "../../../types/types";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Grid,
} from "@mui/material";

const schema = yup.object({
  email: yup.string().required("Required field").email("Invalid email address"),
  password: yup.string().required("Required field"),
});

type FormData = yup.InferType<typeof schema>;

const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const state = location.state as LocationState;
  const from = state?.from?.pathname || "/user/dashboard";

  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const [validCredential, setValidCredential] = useState<boolean | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setSignInLoading(true);

    const user = {
      username: data.email,
      password: data.password,
    };

    try {
      const response = await AuthService.signin(user);

      if (response.data) {
        setValidCredential(true);
        localStorage.setItem("token", JSON.stringify(response.data));

        const userData = await UserService.getUserDetails(
          response.data.username
        );
        // Get user data using header instead of usng email
        localStorage.setItem("user", JSON.stringify(userData.data));

        dispatch(
          authAction.login({ token: response.data, user: userData.data })
        );

        dispatch(
          snackBarAction.open({
            message: "Login Successful!",
            severity: "success",
          })
        );

        setTimeout(() => {
          setSignInLoading(false);

          reset();
          navigate(from, { replace: true });
        }, 1000);
      } else {
        setSignInLoading(false);
        setValidCredential(false);
        return;
      }
    } catch (e) {
      setTimeout(() => {
        setSignInLoading(false);
        setValidCredential(false);
      }, 750);
      return;
    }
  });

  return (
    <div className="sm:min-h-[calc(100vh-25rem)] pt-16 pb-24 main-page">
      <div className="xxs:w-5/6 xx:max-w-sm mx-auto">
        <div className="flex flex-col items-center">
          <div className="bg-secondary text-white w-10 h-10 rounded-full flex items-center justify-center mb-2">
            <LockOutlinedIcon />
          </div>
          <h1 className="text-2xl">Coach Login</h1>
          <form onSubmit={onSubmit} className="w-full mt-2">
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              {...register("email")}
              className={`${errors.email && "input-invalid"} `}
            />
            {errors.email && (
              <p className="text-red-700">* {errors.email?.message}</p>
            )}
            <TextField
              type="password"
              margin="normal"
              fullWidth
              id="password"
              label="Password"
              autoComplete="current-password"
              {...register("password")}
              className={`${errors.password && "input-invalid"}`}
            />
            {errors.password && (
              <p className="text-red-700">* {errors.password?.message}</p>
            )}
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />

            {validCredential === false && (
              <div className="border-l-4 border-red-800 rounded-l-md">
                <p className="text-red-800 bg-red-200/75 p-5 rounded-r-md font-[500]">
                  Incorrect email address or password, please check your details
                  and try again.
                </p>
              </div>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {!signInLoading ? (
                "Sign In"
              ) : (
                <PulseLoader
                  color="#fff"
                  size={9}
                  className="padding-spinner"
                />
              )}
            </Button>

            <Grid container>
              <Grid item xs={12} sm={5}>
                <Link to="/forgotpassword">Forgot Password?</Link>
              </Grid>
              <Grid item xs={12} sm={7} className="ss:text-right">
                <Link to="/signup">{"Don't have an account? Sign Up"}</Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
