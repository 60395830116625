import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Box,
  Typography,
} from "@mui/material";

import InputField from "../../../components/Fields/InputField";
import JobService from "../../../services/JobService";
import DashBoardCard from "../../../components/card/DashBoardCard";

const MatchedJobs = () => {
  const [jobs, setJobs] = useState<any>();

  const navigate = useNavigate();

  const onViewClick = (jobId: string) => () => {
    navigate(`/user/viewjob/${jobId}`);
  };

  const getJobs = async () => {
    const response = await JobService.getCoachMatchedJobs();
    setJobs(response.data);
  };

  const searchJobs = async (searchCriteria: any) => {
    const response = await JobService.searchCoachMatchedJobPosts(
      searchCriteria
    );
    setJobs(response.data);
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    jobs && (
      <DashBoardCard
        body={
          <Container maxWidth="lg" className="mt-6">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Card className="w-full">
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "black",
                      height: "10vh",
                    }}
                  >
                    <h1>Matched Jobs</h1>
                  </div>
                  {/* Hero unit */}
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      pt: 8,
                      pb: 6,
                    }}
                  >
                    <Container>
                      <div className="pt-8">
                        <InputField onSearch={searchJobs} />
                      </div>
                    </Container>
                  </Box>

                  <Container sx={{ py: 8 }} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                      {jobs &&
                        jobs.map((item: any) => (
                          <Grid item key={item.uuid} xs={12} sm={6} md={4}>
                            <Card
                              sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <CardContent sx={{ flexGrow: 1 }}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  {item.jobPost.role}
                                </Typography>
                                <Typography>
                                  {item.jobPost.organization.companyName}
                                </Typography>
                                <Typography>
                                  Job Type:{" "}
                                  {item.jobPost.jobPostRoleTypes.map(
                                    (item: any) => (
                                      <React.Fragment key={item.uuid}>
                                        {item.name}
                                      </React.Fragment>
                                    )
                                  )}
                                </Typography>
                                <Typography>
                                  Location: {item.jobPost.city},{" "}
                                  {item.jobPost.state}
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Button
                                  size="small"
                                  onClick={onViewClick(item.jobPost.uuid)}
                                >
                                  View
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </Container>
                </CardContent>
              </Card>
            </Grid>
          </Container>
        }
      ></DashBoardCard>
    )
  );
};

export default MatchedJobs;
