import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import JobService from "../../../services/JobService";

const Job = () => {
  const { jobId } = useParams();
  const [jobData, setJobData] = useState<any>();
  // const [jobDesc, setJobDesc] = useState<any>();

  const getJobData = async () => {
    const jobs = await JobService.getJobById(jobId);
    setJobData(jobs.data[0]);
  };

  useEffect(() => {
    getJobData();
  }, []);

  return (
    jobData && (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Job Post Summary
        </Typography>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card__body">
                <p className="text-3xl font-semibold">{jobData.role}</p>
                <p className="text-lg font-medium text-[#349eff] mt-2">
                  {jobData.organization.companyName}
                </p>
                <p className="mt-1">
                  {jobData.city} {jobData.state}
                </p>
                <p className="mt-1">
                  {jobData.jobPostRoleTypes.map((item: any) => (
                    <React.Fragment key={item.uuid}>{item.name}</React.Fragment>
                  ))}
                </p>
                <p className="mt-1">
                  A${jobData.salaryMin} - A$
                  {jobData.salaryMax} per{" "}
                  {jobData.salaryType === "HOURLY"
                    ? "hour"
                    : jobData.salaryType === "WEEKLY"
                    ? "week"
                    : jobData.salaryType === "MONTHLY"
                    ? "month"
                    : jobData.salaryType === "ANNUAL" && "annum"}
                </p>
                <hr className="my-6" />
                <p className="text-xl font-semibold">Job Details</p>
                <p className="font-semibold mt-3">Job Type</p>
                <p className="text-sm mt-1">
                  {jobData.jobPostRoleTypes.map((item: any) => (
                    <React.Fragment key={item.uuid}>{item.name}</React.Fragment>
                  ))}
                </p>
                <p className="font-semibold mt-3">Schedule</p>
                {jobData.sessionDays.map((item: any) => (
                  <p className="text-sm mt-1" key={item.uuid}>
                    {item.dayOfWeek}: {item.timeOfDay}
                  </p>
                ))}
                <p className="font-semibold mt-3">Expected Start Date</p>
                <p className="text-sm mt-1">
                  {moment(jobData.dateStart).format("DD/MM/YYYY")}
                </p>
                <p className="font-semibold mt-3">About the role</p>
                <p className="text-sm mt-1">{jobData.roleAdditionalInfo}</p>
                <hr className="my-6" />
                <p className="text-xl font-semibold">Qualifications</p>
                <p className="mt-3 text-sm font-semibold">
                  At least {jobData.experienceYears} years of experience
                </p>
                <ul className="list-disc list-inside text-sm mt-1">
                  {jobData.minimumQualification}
                </ul>
                <hr className="my-6" />
                <p className="text-xl font-semibold">Hiring</p>
                <p className="font-semibold mt-3">Job Type</p>
                <p className="text-sm mt-1">
                  Hiring {jobData.candidates} candidates for this role
                </p>
                <p className="font-semibold mt-3">Job Activity</p>
                <p className="text-sm mt-1">
                  Posted {moment(jobData.dateFilled).fromNow()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default Job;
