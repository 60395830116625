import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import RequireAuth from "./components/RequireAuth";
import AuthService from "./services/AuthenticationService";
import { RootState } from "./store/index";
import { authAction } from "./store/features/authSlice";
import { profileCompletionAction } from "./store/features/profileCompletionSlice";
import { profilePictureAction } from "./store/features/profilePictureSlice";
import { modeAction } from "./store/features/modeSlice";

import MainLayout from "./layout/Main/MainLayout";
import DashboardLayout from "./layout/Dashboard/DashboardLayout";
import Unauthorized from "./pages/Unauthorized";
import NotFound from "./pages/NotFound";
import Forbidden from "./pages/Forbidden";

import {
  orgRoutes,
  superAdminRoutes,
  coachRoutes,
  passwordResetRoutes,
  dashboardRoutes,
  orgDashboardRoutes,
  superAdminDashboardRoutes,
} from "./routes/routes";

import "./App.css";
import CoachSuccess from "./pages/Main/Coach/CoachSuccess";
import OrgSuccess from "./pages/Main/Organisation/OrgSuccess";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const token = useSelector((state: RootState) => state.auth.token);
  const user = useSelector((state: RootState) => state.auth.user);
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  //Check token expiration
  const checkToken = async () => {
    if (token) {
      const jwtPayload = JSON.parse(
        window.atob(token.accessToken.split(".")[1])
      );
      const now = parseInt(String(Date.now() / 1000));
      if (jwtPayload.exp - now < 0) {
        dispatch(authAction.logout());
        dispatch(profileCompletionAction.set(null));
        dispatch(profilePictureAction.set(null));
        dispatch(modeAction.setColor(null));
        AuthService.signout();
      }
    } else {
      dispatch(profileCompletionAction.set(null));
      dispatch(profilePictureAction.set(null));
      dispatch(modeAction.setColor(null));
    }
  };

  useEffect(() => {
    checkToken();
  }, [location.pathname]);

  const coachRole = ["ROLE_COACH"];
  const orgRole = ["ROLE_ORGUSER", "ROLE_ORGADMIN"];
  const superAdminRole = ["ROLE_SUPER_ADMIN"];

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route key="home-route" path="/" element={<Navigate to="/signin" />} />
        {orgRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<route.component />}
          ></Route>
        ))}
        {superAdminRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<route.component />}
          ></Route>
        ))}

        {coachRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<route.component />}
          ></Route>
        ))}

        {passwordResetRoutes.map((route) => (
          <Route
            key={route.key}
            path={route.path}
            element={<route.component />}
          ></Route>
        ))}

        <Route path="*" element={<NotFound />} />
        <Route path="/401" element={<Unauthorized />} />
        <Route path="/403" element={<Forbidden />} />
      </Route>

      <Route
        element={
          <RequireAuth
            isAuthenticated={isAuthenticated}
            role={coachRole}
            user={user}
            path="/signin"
          />
        }
      >
        <Route path="/user" element={<DashboardLayout />}>
          {dashboardRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
      </Route>

      <Route
        element={
          <RequireAuth
            isAuthenticated={isAuthenticated}
            role={orgRole}
            user={user}
            path="/org/signin"
          />
        }
      >
        <Route path="/org" element={<DashboardLayout />}>
          {orgDashboardRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
      </Route>

      <Route
        element={
          <RequireAuth
            isAuthenticated={isAuthenticated}
            role={superAdminRole}
            user={user}
            path="/admin/signin"
          />
        }
      >
        <Route path="/admin" element={<DashboardLayout />}>
          {superAdminDashboardRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={<route.component />}
            />
          ))}
        </Route>
      </Route>

      <Route path="/success" element={<CoachSuccess />} />
      <Route path="/orgsuccess" element={<OrgSuccess />} />
    </Routes>
  );
};

export default App;
