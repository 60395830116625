import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import InputField from "../../../components/Fields/InputField";
import JobService from "../../../services/JobService";
import SportService from "../../../services/SportService";
import { customStyles } from "../SuperAdmin/ActiveJob";

export default function Album() {
  const [jobs, setJobs] = useState<any>();
  const [sportOptions, setSportOptions] = useState<any>([]);
  const [selectedSport, setSelectedSport] = useState<any>("");

  const getActiveJobsBySport = async (sportName: string) => {
    const response = await JobService.getAllActiveJobBySport(sportName);
    setJobs(response.data);
  };

  const typeOfSportHandler = (e: any) => {
    setSelectedSport(e);

    if (e.value === "All") {
      getJobData();
      return;
    }
    getActiveJobsBySport(e.name);
  };

  const getJobData = async () => {
    const response = await JobService.getAllActiveByOrg();
    setJobs(response.data);
  };

  const searchJobData = async (searchCriteria: any) => {
    const response = await JobService.searchActiveJobsByOrg(searchCriteria);
    setJobs(response.data);
  };

  const getSportOptions = async () => {
    const response = await SportService.getActiveSports();
    setSportOptions([
      { value: "All", label: "All" },
      ...response.data.map((option: any, index: number) => ({
        ...response.data[index],
        value: option.name,
        label: option.name,
      })),
    ]);
  };

  useEffect(() => {
    getJobData();
    getSportOptions();
  }, []);

  return (
    jobs && (
      <div>
        <main>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "bold",
              color: "black",
              height: "10vh",
            }}
          >
            <h1>Active Job Lists</h1>
          </div>

          {/* Hero unit */}
          <Box
            sx={{
              bgcolor: "background.paper",
              pt: 8,
              pb: 6,
            }}
          >
            <Container>
              <div className="pt-8">
                <InputField onSearch={searchJobData} />
              </div>
            </Container>
          </Box>
          <Container>
            <Grid item xs={12} md={3}>
              <Typography>Type of Sport</Typography>
            </Grid>
            <Grid item xs={12} md={3} className="!pt-2 z-20">
              <Select
                options={sportOptions}
                styles={customStyles}
                placeholder="Please Select"
                value={selectedSport}
                onChange={typeOfSportHandler}
                // menuIsOpen={true}
              />
            </Grid>
          </Container>

          <Container sx={{ py: 8 }} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {jobs &&
                jobs.map((job: any) => (
                  <Grid item key={job.uuid} xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="h2">
                          {job.role}
                        </Typography>
                        <Typography>{job.organization.companyName}</Typography>
                        <Typography>
                          Job Type:{" "}
                          {job.jobPostRoleTypes.map((item: any) => (
                            <React.Fragment key={item.uuid}>
                              {item.name}
                            </React.Fragment>
                          ))}
                          {/* {job.jobPostRoleTypes.name
                            .toString()
                            .split(",")
                            .join(", ")} */}
                        </Typography>
                        <Typography>Location: {job.city}</Typography>
                      </CardContent>
                      <CardActions>
                        <Link to={"/org/joblist/" + job.uuid}>
                          <Button size="small">View</Button>
                        </Link>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Container>
        </main>
      </div>
    )
  );
}
