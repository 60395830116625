import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import Table from "../../../components/table/Table";
import { TableHead, TableBody } from "../../../types/types";
import Badge from "../../../components/badge/Badge";
import CoachService from "../../../services/CoachService";

const appliedJobTableHead = ["Title", "Organization", "Date Applied", "Status"];

const applicationStatus = {
  APPLIED: "primary",
  DECLINED: "warning",
  OFFERED: "success",
  ACCEPTED: "primary",
  ONBOARDING: "success",
  UNSUCCESSFUL: "danger",
};

const renderHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const AppliedJobs = () => {
  const navigate = useNavigate();

  const [appliedJobs, setAppliedJobs] = useState<any>();

  // Navitage to Applied View Job Page and pass applied job id
  const clickHandler = (appliedJobId: string | number | undefined) => {
    navigate(`/user/appliedviewjob/${appliedJobId}`);
  };

  const renderBody = (item: TableBody, index: number) => (
    <tr key={index} onClick={() => clickHandler(item.uuid)}>
      <td>{item.jobPost.role}</td>
      <td>{item.jobPost.organization.companyName}</td>
      <td>{moment(item.dateApplied).format("DD/MM/YYYY")}</td>
      <td>
        <Badge
          type={
            item.jobApplyStatus !== undefined &&
            applicationStatus[
              item.jobApplyStatus as keyof typeof applicationStatus
            ]
          }
          content={item.jobApplyStatus}
        />
      </td>
    </tr>
  );

  const getAppiedJobs = async () => {
    const response = await CoachService.getAllAppliedJobPost();
    setAppliedJobs(response.data);
  };

  useEffect(() => {
    getAppiedJobs();
  }, []);

  return (
    <div>
      <h2 className="page-header">
        <b>Applied Jobs</b>
      </h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              {appliedJobs && (
                <Table
                  limit="10"
                  headData={appliedJobTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={appliedJobs}
                  renderBody={(item, index) => renderBody(item, index)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedJobs;
