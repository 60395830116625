import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../../../store/index";

import SidebarItem from "./SidebarItem";
import sidebar_items from "../../../assets/JsonData/sidebar_routes.json";
import sidebar_items_org from "../../../assets/JsonData/sidebar_routes_org.json";
import sidebar_items_org_admin from "../../../assets/JsonData/sidebar_routes_org_admin.json";
import sidebar_items_org_superadm_user from "../../../assets/JsonData/sidebar_routes_superadm_user.json";

import logo from "../../../assets/images/logo.png";

import "./sidebar.css";

const Sidebar = () => {
  const location = useLocation();
  const user = useSelector((state: RootState) => state.auth.user);

  const [sidebarItems, setSidebarItem] = useState<any>([]);
  const [activeItem, setActiveItem] = useState<any>();
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const setSidebar = async () => {
    if (user.role === "ROLE_COACH") {
      setSidebarItem(sidebar_items);
    } else if (user.role === "ROLE_ORGUSER") {
      setSidebarItem(sidebar_items_org);
    } else if (user.role === "ROLE_ORGADMIN") {
      setSidebarItem(sidebar_items_org_admin);
    } else if (user.role === "ROLE_SUPER_ADMIN") {
      setSidebarItem(sidebar_items_org_superadm_user);
    }
  };

  const setActive = () => {
    const active = sidebarItems.findIndex(
      (item: any) => item.route === location.pathname
    );
    setActiveItem(active);
  };

  const expandSetting = () => {
    setIsExpand(!isExpand);
  };

  useEffect(() => {
    setSidebar();
  }, []);

  useEffect(() => {
    setActive();
  }, [sidebarItems, location.pathname]);

  const coachRole = ["ROLE_COACH"];
  const orgRole = ["ROLE_ORGUSER", "ROLE_ORGADMIN"];
  const superadmRole = ["ROLE_SUPER_ADMIN"];

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <Link
          to={
            coachRole.includes(user.role as string)
              ? "/user/dashboard"
              : orgRole.includes(user.role as string)
              ? "/org/dashboard"
              : superadmRole.includes(user.role as string)
              ? "/admin/dashboard"
              : "/"
          }
          className="logo-img"
        >
          <img src={logo} alt="SmartSportHR" />
        </Link>
      </div>
      {sidebarItems.map((item: any, index: number) => (
        <React.Fragment key={index}>
          {item.display_name === "System Settings" ? (
            <React.Fragment>
              <div
                className="cursor-pointer select-none"
                onClick={expandSetting}
              >
                <SidebarItem
                  title={item.display_name}
                  icon={item.icon}
                  textActive={location.pathname.includes(item.route)}
                />
              </div>
              {isExpand &&
                item.routes.map((item2: any, index: number) => (
                  <Link to={item2.route} key={index}>
                    <div className="pl-8">
                      <SidebarItem
                        title={item2.display_name}
                        icon={item2.icon}
                        active={item2.route === location.pathname}
                      />
                    </div>
                  </Link>
                ))}
            </React.Fragment>
          ) : (
            <Link to={item.route}>
              <SidebarItem
                title={item.display_name}
                icon={item.icon}
                active={index === activeItem}
              />
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Sidebar;
