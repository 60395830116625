import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { customStyles } from "../SuperAdmin/ActiveJob";

import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Box,
  Typography,
} from "@mui/material";

import InputField from "../../../components/Fields/InputField";
import JobService from "../../../services/JobService";
import SportService from "../../../services/SportService";
import DashBoardCard from "../../../components/card/DashBoardCard";

const SearchJobs = () => {
  const [jobs, setJobs] = useState<any>();
  const [sportOptions, setSportOptions] = useState<any>([]);
  const [selectedSport, setSelectedSport] = useState<any>("");

  const navigate = useNavigate();

  const onViewClick = (jobId: string) => () => {
    navigate(`/user/viewjob/${jobId}`);
  };

  const getActiveJobsBySport = async (sportName: string) => {
    const response = await JobService.getAllActiveJobBySport(sportName);
    setJobs(response.data);
  };

  const searchJobs = async (searchCriteria: any) => {
    const response = await JobService.searchJobs(searchCriteria);
    setJobs(response.data);
  };

  const typeOfSportHandler = (e: any) => {
    setSelectedSport(e);

    if (e.value === "All") {
      getJobs();
      return;
    }
    getActiveJobsBySport(e.name);
  };

  const getJobs = async () => {
    const response = await JobService.getJob();
    setJobs(response.data);
  };

  const getSportOptions = async () => {
    const response = await SportService.getActiveSports();
    setSportOptions([
      { value: "All", label: "All" },
      ...response.data.map((option: any, index: number) => ({
        ...response.data[index],
        value: option.name,
        label: option.name,
      })),
    ]);
  };

  useEffect(() => {
    getJobs();
    getSportOptions();
  }, []);

  return (
    jobs && (
      <DashBoardCard
        body={
          <Container maxWidth="lg" className="mt-6">
            {/* <CardHeader title="Search for New Job" className="!pt-0 !pb-10" /> */}
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Card className="!overflow-visible w-full">
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      color: "black",
                      height: "10vh",
                    }}
                  >
                    <h1>Search for New Job</h1>
                  </div>
                  {/* Hero unit */}
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      pt: 8,
                      pb: 6,
                    }}
                  >
                    <Container>
                      <div className="pt-8">
                        <InputField onSearch={searchJobs} />
                      </div>
                    </Container>
                  </Box>
                  <Container>
                    <Grid item xs={12} md={3}>
                      <Typography>Type of Sport</Typography>
                    </Grid>
                    <Grid item xs={12} md={3} className="!pt-2 z-20">
                      <Select
                        options={sportOptions}
                        styles={customStyles}
                        placeholder="Please Select"
                        value={selectedSport}
                        onChange={typeOfSportHandler}
                        // menuIsOpen={true}
                      />
                    </Grid>
                  </Container>

                  <Container sx={{ py: 8 }} maxWidth="md">
                    {/* End hero unit */}
                    <Grid container spacing={4}>
                      {jobs &&
                        jobs.map((item: any) => (
                          <Grid item key={item.uuid} xs={12} sm={6} md={4}>
                            <Card
                              sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <CardContent sx={{ flexGrow: 1 }}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  {item.role}
                                </Typography>
                                <Typography className="!mb-4">
                                  {item.organization.companyName}
                                </Typography>
                                <Typography>
                                  Job Type:{" "}
                                  {item.jobPostRoleTypes.map((item: any) => (
                                    <React.Fragment key={item.uuid}>
                                      {item.name}
                                    </React.Fragment>
                                  ))}
                                </Typography>
                                <Typography>
                                  Location: {item.city}, {item.state}
                                </Typography>
                              </CardContent>
                              <CardActions>
                                <Button
                                  size="small"
                                  onClick={onViewClick(item.uuid)}
                                >
                                  View
                                </Button>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </Container>
                </CardContent>
              </Card>
            </Grid>
          </Container>
        }
      ></DashBoardCard>
    )
  );
};

export default SearchJobs;
