import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PulseLoader from "react-spinners/PulseLoader";

import { RootState } from "../../../store/index";
import Dropdown from "../../../components/dropdown/Dropdown";
import user_menu from "../../../assets/JsonData/user_menus.json";
import org_menu from "../../../assets/JsonData/org_menus.json";
import admin_menu from "../../../assets/JsonData/admin_menus.json";
import { Item } from "../../../types/types";
import OrganizationService from "../../../services/OrganizationService";
import CoachService from "../../../services/CoachService";

import "./topnav.css";

type OrgUser = {
  companyName: string;
  orgUser: any;
};

type Coach = {
  coachUser: any;
  // image: string;
};

type Admin = {
  firstName: string;
  lastName: string;
};

type CurrentUser = Coach & OrgUser & Admin;

const Topnav = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const profilePicture = useSelector(
    (state: RootState) => state.profilePicture.profilePicture
  );
  const [userData, setUserData] = useState<any>();
  const [menu, setMenu] = useState<any>();
  const [signOutLoading, setSignOutLoading] = useState<boolean>(false);

  const renderUserToggle = (item: CurrentUser) => (
    <div className="topnav__right-user">
      <div className="topnav__right-user__image">
        <img src={profilePicture} alt="" />
      </div>
      <div className="topnav__right-user__name">
        {user.role === "ROLE_ORGUSER" || user.role === "ROLE_ORGADMIN"
          ? `${item.orgUser[0].firstName} ${item.orgUser[0].lastName} (${item.companyName})`
          : user.role === "ROLE_COACH"
          ? `${item.coachUser[0].firstName} ${item.coachUser[0].lastName}`
          : user.role === "ROLE_SUPER_ADMIN" &&
            `${item.firstName} ${item.lastName}`}
      </div>
    </div>
  );

  const renderUserMenu = (item: Item, index: number) => (
    <div key={index}>
      {item.content === "Logout" ? (
        <button>
          {signOutLoading ? (
            <div className="notification-item justify-center">
              <PulseLoader
                color="#349eff"
                size={9}
                className="padding-spinner"
              />
            </div>
          ) : (
            <div className="notification-item">
              <i className={`${item.icon} pointer-events-none`}></i>
              <span className="pointer-events-none">{item.content}</span>
            </div>
          )}
        </button>
      ) : (
        <button>
          <div className="notification-item">
            <i className={`${item.icon} pointer-events-none`}></i>
            <span className="pointer-events-none">{item.content}</span>
          </div>
        </button>
      )}
    </div>
  );

  const getOrgUser = async () => {
    const response = await OrganizationService.getLoggedInOrgProfile();
    setUserData(response.data);
  };

  const getCoach = async () => {
    const response = await CoachService.getLoggedInCoachProfile();
    setUserData(response.data);
  };

  useEffect(() => {
    if (user.role === "ROLE_COACH") {
      getCoach();
      setMenu(user_menu);
    } else if (user.role === "ROLE_ORGUSER" || user.role === "ROLE_ORGADMIN") {
      getOrgUser();
      setMenu(org_menu);
    } else if (user.role === "ROLE_SUPER_ADMIN") {
      setUserData(user);
      setMenu(admin_menu);
    }
  }, [user]);

  useEffect(() => {
    if (user.role === "ROLE_ORGUSER" || user.role === "ROLE_ORGADMIN") {
      getOrgUser();
    } else if (user.role === "ROLE_COACH") {
      getCoach();
    } else if (user.role === "ROLE_SUPER_ADMIN") {
      setUserData(user);
    }
  }, []);

  return (
    userData && (
      <div className="topnav flex justify-end">
        <div className="topnav__right">
          <div className="topnav__right-item">
            {/* dropdown here */}
            <Dropdown
              customToggle={() => renderUserToggle(userData)}
              contentData={menu}
              renderItems={(item: Item, index: number) =>
                renderUserMenu(item, index)
              }
              setSignOutLoading={setSignOutLoading}
            />
          </div>
          {/* <div className="topnav__right-item">
          <Dropdown
            icon="bx bx-bell"
            badge="12"
            contentData={notifications}
            renderItems={(item: Item, index: number) =>
              renderNotificationItem(item, index)
            }
            renderFooter={() => <Link to="/">View All</Link>}
          /> */}
          {/* dropdown here */}
          {/* </div> */}
          {/* <div className="topnav__right-item">
          <ThemeMenu />
        </div> */}
        </div>
      </div>
    )
  );
};

export default Topnav;
