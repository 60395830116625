import React from "react";
import { Link } from "react-router-dom";

import { orgRoutes, coachRoutes } from "../../../routes/routes";
import FacebookIcon from "../../../assets/images/FacebookIcon.svg";
import LinkedinIcon from "../../../assets/images/LinkedinIcon.svg";
import TwitterIcon from "../../../assets/images/TwitterIcon.svg";
import InstagramIcon from "../../../assets/images/InstagramIcon.svg";

import "./Footer.css";

export default function Footer() {
  return (
    <footer>
      <div className="loginfooter-container  py-12 sm:px-32 xx:px-16 xxs:px-8 flex justify-center">
        <div className="grid md:grid-cols-3 xxs:grid-cols-2 gap-y-8 w-full ">
          <div className="xxs:order-2 md:order-none text-white">
            <p>Organizations</p>
            <ul>
              {orgRoutes.map((route) => (
                <li key={route.key}>
                  <Link to={route.path}>{route.title}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="xxs:order-6 md:order-none text-white">
            <p>Coaches</p>
            <ul>
              {coachRoutes.map((route) => (
                <li key={route.key}>
                  <Link to={route.path}>{route.title}</Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="xxs:order-1 md:order-none text-white">
            <p>Information &amp; Legal</p>
            <ul>
              <li>
                <a href="https://www.smartsporthr.com/privacy-policy/">
                  Privacy Policy
                </a>
              </li>

              <li>
                <a href="/">Terms and Conditions</a>
              </li>
            </ul>
          </div>

          <div className="xxs:order-3 md:order-none text-white">
            <p>Help &amp; Support</p>

            <ul>
              <li>
                <a href="/">FAQ</a>
              </li>

              <li>
                <a href="https://www.smartsporthr.com/contact-us/">
                  Contact Us
                </a>
              </li>

              <li>
                <a href="/">Support</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="bottom-loginfooter-container xs:px-32 xxs:flex-col md:flex-row">
        <p className="xxs:order-2 md:order-1">
          Copyright © {new Date().getFullYear()}. All Rights Reserved
        </p>
        <div className="loginfooter-social-media-section xxs:order-1 md:order-2">
          <img src={FacebookIcon} alt="social media icon does not exist" />
          <img src={LinkedinIcon} alt="social media icon does not exist" />
          <img src={TwitterIcon} alt="social media icon does not exist" />
          <img src={InstagramIcon} alt="social media icon does not exist" />
        </div>
      </div>
    </footer>
  );
}
