import Api from "./Api";
import JsonApi from "./JsonApi";
import authHeader from "./AuthHeader";
import { TierData } from "../pages/Dashboard/SuperAdmin/Tier";

const registerTier = (tier: TierData) => {
  return Api().post("/api/jobPostPlan/register", tier, {
    headers: authHeader(),
  });
};

const getAllTiers = () => {
  return Api().get("/api/jobPostPlan/getAll", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getActiveTiers = () => {
  return Api().get("/api/jobPostPlan/getActive", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const updateTier = (tier: TierData) => {
  return Api().put(`/api/jobPostPlan/update/${tier.uuid}`, tier, {
    headers: authHeader(),
  });
};

const deleteTier = (id: string) => {
  return Api().delete(`/api/jobPostPlan/delete/${id}`, {
    headers: authHeader(),
  });
};

const restoreTier = (id: string) => {
  return Api().put(`/api/jobPostPlan/restore/${id}`, null, {
    headers: authHeader(),
  });
};

const TierService = {
  registerTier,
  getAllTiers,
  getActiveTiers,
  updateTier,
  deleteTier,
  restoreTier,
};

export default TierService;
