import CoachAccountDetails from "./CoachAccountDetails";
import ProfileLayout from "../../../../layout/Profile/ProfileLayout";

const CoachAccount = () => {
  return (
    <ProfileLayout>
      <CoachAccountDetails />
    </ProfileLayout>
  );
};

export default CoachAccount;
