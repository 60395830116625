import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { snackBarAction } from "../../../store/features/snackBarSlice";

import UserService from "../../../services/UserService";
import logo from "../../../assets/images/logo.png";
import DashBoardCard from "../../../components/card/DashBoardCard";
import UserDetailSection from "../../../components/card/UserDetailSection";

const ViewOrganization = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();

  const [orgUser, setOrgUser] = useState<any>();

  const getOrgUser = async () => {
    try {
      const response = await UserService.getOrgUserhById(organizationId);
      setOrgUser(response.data);
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  useEffect(() => {
    getOrgUser();
  }, []);

  const aa =
    "70% of children quit sport by age 13 globally, with poor coaching cited as one of the main reasons. Many don’t have coaching role models they can look up to, identify or feel alignment with. For sports that are losing money, this is a problem that can be fixed, by focusing on retention rather than acquisition of both participants and coaching staff. \n\n We are changing this by helping sporting organisations make quicker & better coaching hires using smart but simple recruitment software that aligns and democratises the coaching recruitment process” to get the right long term fit for your sporting organisation. \n\n  Where we are different from existing options is our technology enables the ability to democratise coaching and match applicants with jobs that align with philosophy, goals and culture meaning that shortlisted coaches are more diverse in gender, ethnicity and background. We then assist the selection panel with best practice recommendations to ensure a better fit, longer term successful coaching appointments and finally assisting applicants break glass ceilings through matched mentoring and soft skill micro credentialing which have been identified by diversity and inclusion experts in sport as the 2 main areas lacking in sport coach develoment.";
  return (
    orgUser && (
      <React.Fragment>
        <h2 className="page-header">Organization Directory</h2>
        <div className="row">
          <div className="col-12">
            <DashBoardCard
              body={
                <React.Fragment>
                  <div className="relative overflow-hidden w-[350px] h-[125px] mb-8">
                    <img
                      src={logo}
                      alt=""
                      // width="400px"
                      className="absolute translate-y-[-50%] translate-x-[-50%] top-[50%] left-[50%]"
                    />
                  </div>
                  <div className="text-2xl font-semibold">
                    {orgUser.companyName}
                  </div>
                  {/* <div className="text-lg font-medium">About</div> */}
                </React.Fragment>
              }
            />

            <DashBoardCard
              body={
                <UserDetailSection header="Organization Overview">
                  <div className="grid grid-cols-4 gap-2 mb-6">
                    <div className="font-semibold">Website</div>
                    <div className="col-span-3">
                      <a
                        href="https://www.smartsporthr.com/"
                        className="text-[#fb0b12] hover:underline
                    "
                      >
                        https://www.smartsporthr.com/
                      </a>
                    </div>
                    <div className="font-semibold">Organization Type</div>
                    <div className="col-span-3">
                      {orgUser.orgType === "PRIVATE"
                        ? "Private Company"
                        : orgUser.orgType === "PUBLIC"
                        ? "Public Company"
                        : orgUser.orgType === "VOLUNTEER" && "Volunteer"}
                    </div>
                    <div className="font-semibold">Organization Size</div>
                    <div className="col-span-3">11-50 employees</div>
                    <div className="font-semibold">Location</div>
                    <div className="col-span-3">Melbourne, Australia</div>
                  </div>

                  <div>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc in augue non magna malesuada lobortis sit amet sit amet
                    neque. Nam ut pellentesque urna, id imperdiet ante. Ut
                    varius dui nec nunc lobortis, ac ornare eros blandit. Nulla
                    lacinia nibh quis tellus aliquam, at vehicula magna
                    efficitur.
                  </div>
                </UserDetailSection>
              }
            />

            <DashBoardCard
              body={
                <UserDetailSection header="Our mission statement">
                  <div className="whitespace-pre-line">{aa}</div>
                </UserDetailSection>
              }
            />
          </div>
        </div>
      </React.Fragment>
    )
  );
};

export default ViewOrganization;
