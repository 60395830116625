import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "@mui/material";
import moment from "moment";

import TextField from "../../../../components/Fields/TextField";
import CoachService from "../../../../services/CoachService";
import { snackBarAction } from "../../../../store/features/snackBarSlice";
import UserProfileCard from "../../../../components/card/UserProfileCard";

const schema = yup.object({
  name: yup.string().required("Required field"),
  expiryDate: yup.date().typeError("Invalid date").required("Required field"),
});

type FormData = yup.InferType<typeof schema>;

const Certifications = () => {
  const dispatch = useDispatch();
  const [showSave, setShowSave] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [allData, setAllData] = useState<any>();
  const [updateOneData, setUpdateOneData] = useState<any>();
  const [data, setData] = useState({
    name: "",
    expiryDate: "",
  });

  const {
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema as any),
  });

  const handleClick = () => {
    if (showUpdate) {
      setShowSave(false);
    } else {
      setShowSave(!showSave);
    }

    setData({
      name: "",
      expiryDate: "",
    });
    getAllData();
    reset();
  };

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setValue(e.target.name, e.target.value);
    trigger();
  };

  const handleSave = async () => {
    if (!showUpdate) {
      const result = await trigger(["name", "expiryDate"]);
      if (!result) {
        return;
      }

      try {
        await CoachService.addCertifications(data);
        getAllData();
        handleClick();
        dispatch(
          snackBarAction.open({
            message: "Certification has been successfully added",
            severity: "success",
          })
        );
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            snackBarAction.open({
              message: e.message,
              severity: "error",
            })
          );
        }
      }
    } else if (showUpdate) {
      let updateData = allData.map((obj: any) => {
        return {
          name: obj.name,
          expiryDate: obj.expiryDate,
        };
      });
      try {
        await CoachService.updateCertifications(updateData);
        getAllData();
        setShowUpdate(false);
        dispatch(
          snackBarAction.open({
            message: "Certifications has been successfully updated",
            severity: "success",
          })
        );
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            snackBarAction.open({
              message: e.message,
              severity: "error",
            })
          );
        }
      }
    }
  };

  const handleShowUpdate = () => {
    setShowSave(false);
    setShowUpdate(!showUpdate);
    setUpdateOneData(null);
    getAllData();
  };

  const updateInput = (e: any, certItem: any) => {
    const newData = allData.map((obj: any) => {
      if (obj.uuid === certItem.uuid) {
        return {
          ...obj,
          [e.target.name]: e.target.value,
        };
      }
      return obj;
    });
    setAllData(newData);
    if (updateOneData) {
      setValue(e.target.name, e.target.value);
      trigger();
    }
  };

  const handleUpdate = (item: any, index: number) => {
    setShowSave(false);
    setUpdateOneData(item.uuid);
    getAllData();
    reset();
    setValue("name", item.name);
    setValue("expiryDate", new Date(item.expiryDate));
  };

  const handleCanncelUpdateOne = () => {
    setUpdateOneData(null);
    getAllData();
    reset();
  };

  const handleSaveUpdateOne = async (item: any) => {
    const result = await trigger(["name", "expiryDate"]);
    if (!result) {
      return;
    }

    try {
      await CoachService.updateCertification(item.uuid, item);
      getAllData();
      setUpdateOneData(null);
      dispatch(
        snackBarAction.open({
          message: "Certification has been successfully updated",
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const handleDelete = async (certificationId: string) => {
    try {
      await CoachService.deleteCertification(certificationId);
      getAllData();
      dispatch(
        snackBarAction.open({
          message: "Certification has been successfully deleted",
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const getAllData = async () => {
    const response = await CoachService.getCertifications();
    setAllData(response.data);
  };

  useEffect(() => {
    getAllData();
  }, []);

  return (
    <UserProfileCard
      title={
        <React.Fragment>
          <span className="card-heading">Certifications</span>
          {/* <Button onClick={handleShowUpdate}>
            <i className="bx bxs-edit"></i>
          </Button> */}
        </React.Fragment>
      }
      body={
        <React.Fragment>
          {allData && allData.length > 0 ? (
            <div className="!mb-2">
              {allData.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  {!showUpdate ? (
                    <React.Fragment>
                      {updateOneData === item.uuid ? (
                        <div className="flex">
                          <div className="w-[95%] flex justify-between">
                            <div className="w-[78.5%] flex justify-between">
                              <div className="!w-[48.5%]">
                                <TextField
                                  required
                                  margin="normal"
                                  fullWidth
                                  label="Licence name"
                                  name="name"
                                  autoComplete="given-license"
                                  autoFocus
                                  value={item.name}
                                  onChange={(e) => updateInput(e, item)}
                                />
                                {errors.name && (
                                  <p className="text-red-700">
                                    * {errors.name.message}
                                  </p>
                                )}
                              </div>
                              <div className="!w-[48.5%]">
                                <TextField
                                  required
                                  label="Expiry Date"
                                  type={"date"}
                                  InputLabelProps={{ shrink: true }}
                                  fullWidth
                                  name="expiryDate"
                                  autoComplete="expirydate"
                                  variant="standard"
                                  value={moment(item.expiryDate).format(
                                    "YYYY-MM-DD"
                                  )}
                                  onChange={(e) => updateInput(e, item)}
                                />
                                {errors.expiryDate && (
                                  <p className="text-red-700">
                                    * {errors.expiryDate.message}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="w-[21.5%] flex justify-end items-start pt-[22.5px]">
                              <Button
                                className="profile_button"
                                onClick={() => handleSaveUpdateOne(item)}
                              >
                                Save
                              </Button>
                              <Button
                                className="profile_button"
                                onClick={handleCanncelUpdateOne}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                          <span className="text-[31px] flex justify-end w-[5%] pt-[28px]">
                            <i
                              className="bx bx-trash cursor_pointer"
                              onClick={() => handleDelete(item.uuid)}
                            ></i>
                          </span>
                        </div>
                      ) : (
                        <div className="flex justify-between">
                          <div className="w-[65.5%]">
                            <p>{item.name}</p>
                          </div>
                          <div className="w-[27.5%]">
                            <span>
                              {item.expiryDate
                                ? moment(item.expiryDate).format("DD/MM/YYYY")
                                : "No Expiry"}
                            </span>
                          </div>
                          <span className="text-[17.5px] flex justify-end w-[3.5%] pt-[2px] items-start">
                            <i
                              className="bx bxs-pencil cursor_pointer"
                              onClick={() => handleUpdate(item, index)}
                            ></i>
                          </span>
                          <span className="text-[20px] flex justify-end w-[3.5%]">
                            <i
                              className="bx bx-x cursor_pointer"
                              onClick={() => handleDelete(item.uuid)}
                            ></i>
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    <div className="flex justify-between items-center">
                      <div className="w-[90%] flex justify-between">
                        <TextField
                          required
                          margin="normal"
                          fullWidth
                          label="Licence name"
                          name="name"
                          autoComplete="given-license"
                          autoFocus
                          value={item.name}
                          onChange={(e) => updateInput(e, item)}
                          className="!w-[45%]"
                        />
                        <TextField
                          required
                          label="Expiry Date"
                          type={"date"}
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="expiryDate"
                          autoComplete="expirydate"
                          variant="standard"
                          value={moment(item.expiryDate).format("YYYY-MM-DD")}
                          onChange={(e) => updateInput(e, item)}
                          className="!w-[45%]"
                        />
                      </div>
                      <span className="text-[31px] flex justify-end w-[10%]">
                        <i
                          className="bx bx-trash cursor_pointer"
                          onClick={() => handleDelete(item.uuid)}
                        ></i>
                      </span>
                    </div>
                  )}
                  <br />
                </React.Fragment>
              ))}
            </div>
          ) : (
            <p className="mb-6 mt-4">Showcase your professional credentials.</p>
          )}

          {showUpdate ? (
            <React.Fragment>
              <Button className="profile_button" onClick={handleSave}>
                Save
              </Button>
              <Button className="profile_button" onClick={handleShowUpdate}>
                Cancel
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {!updateOneData && (
                <React.Fragment>
                  {!showSave && (
                    <Button onClick={handleClick} className="profile_button">
                      Add Certifications
                    </Button>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      }
      body_2={
        <React.Fragment>
          {showSave && (
            <div className="c_card mb-5">
              <div className="card-body">
                <form className="w-full mt-2">
                  <TextField
                    margin="normal"
                    fullWidth
                    id="certification"
                    label="Licence name"
                    name="name"
                    autoComplete="given-license"
                    autoFocus
                    value={data.name}
                    onChange={(e) => handleChange(e)}
                  />
                  {errors.name && (
                    <p className="text-red-700">* {errors.name.message}</p>
                  )}
                  <TextField
                    required
                    id="expiryDate"
                    label="Expiry Date"
                    type={"date"}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    name="expiryDate"
                    autoComplete="expirydate"
                    variant="standard"
                    value={data.expiryDate}
                    onChange={(e) => handleChange(e)}
                    className="!mb-2"
                  />
                  {errors.expiryDate && (
                    <p className="text-red-700">
                      * {errors.expiryDate.message}
                    </p>
                  )}
                  <div>
                    <Button className="profile_button" onClick={handleSave}>
                      Save
                    </Button>
                    <Button className="profile_button" onClick={handleClick}>
                      Cancel
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </React.Fragment>
      }
    />
  );
};

export default Certifications;
