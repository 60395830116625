import { createSlice } from "@reduxjs/toolkit";
const { v4: uuidv4 } = require("uuid");

const initialSnackBarState = {
  snackbardata: [] as any,
};

const snackBarSlice = createSlice({
  name: "snackBar",
  initialState: initialSnackBarState,
  reducers: {
    open(state, action) {
      state.snackbardata.push({
        id: uuidv4(),
        open: true,
        message: action.payload.message,
        severity: action.payload.severity,
        hide: action.payload.hide === false ? false : true,
      });
    },
    close(state, action) {
      state.snackbardata = state.snackbardata.filter(
        (item: any) => item.id !== action.payload
      );
    },
  },
});

export const snackBarAction = snackBarSlice.actions;

export default snackBarSlice.reducer;
