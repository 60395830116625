import { useState } from "react";
import { TextField, InputLabel } from "@mui/material";
import Tag from "./Tag";

import "./TagsInput.css";

const TagsInput = (props: any) => {
  const { data, setData, register, setValue, errors, dataKey, trigger } = props;

  const [inputStyle, setInputStyle] = useState<boolean>(false);

  const handleKeyDown = (e: any) => {
    const value = e.target.value;

    if (!value.trim()) {
      e.target.value = "";
      return;
    }
    if (e.key === "Enter" || e.code === "Space") {
      setData({
        ...data,
        [dataKey]: [...data[dataKey], value],
      });
      setValue(dataKey, [...data[dataKey], value]);
      e.target.value = "";
      trigger(dataKey);
    }
  };

  const focusHandler = () => {
    setInputStyle(true);
  };

  const blurHandler = (e: any) => {
    setInputStyle(false);

    const value = e.target.value;
    if (!value.trim()) {
      e.target.value = "";
      setValue(dataKey, [...data[dataKey]]);
      trigger(dataKey);
      return;
    } else {
      setData({
        ...data,
        [dataKey]: [...data[dataKey], value],
      });
      setValue(dataKey, [...data[dataKey], value]);
      e.target.value = "";
      trigger(dataKey);
    }
  };

  const clickHandler = (index: number) => {
    setData({
      ...data,
      [dataKey]: data[dataKey].filter((tag: string, i: number) => i !== index),
    });

    setValue(
      dataKey,
      data[dataKey].filter((tag: string, i: number) => i !== index)
    );

    trigger(dataKey);
  };

  return (
    <div>
      <InputLabel className={`!text-xs ${inputStyle && "!text-[#1976d2]"}`}>
        {dataKey.charAt(0).toUpperCase() + dataKey.slice(1)} *
      </InputLabel>
      <div
        className={`tags-input-container border-b-[1px]  ${
          data[dataKey].length > 0 && "py-2"
        } ${inputStyle ? "value-focus border-b-[2px]" : "value-hover"}`}
      >
        {data[dataKey] &&
          data[dataKey].map((tag: string, index: number) => (
            <Tag
              key={index}
              clickHandler={clickHandler}
              tag={tag}
              index={index}
            />
          ))}
        <input
          id="test"
          type="text"
          className={`tags-input `}
          onKeyDown={handleKeyDown}
          onFocus={focusHandler}
          onBlur={blurHandler}
          placeholder={`Enter ${dataKey}...`}
        />
      </div>
      <TextField
        id={dataKey}
        label={dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}
        fullWidth
        required
        autoComplete={dataKey}
        variant="standard"
        {...register(dataKey)}
        className="!hidden"
      />
      {errors[dataKey] && (
        <p className="text-red-700">* {errors[dataKey].message}</p>
      )}
    </div>
  );
};

export default TagsInput;
