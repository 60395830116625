import React from "react";
import { Link } from "react-router-dom";

import "./statuscard.css";

type Props = {
  icon: string;
  count: string;
  title: string;
  link: string;
};

const StatusCard = (props: Props) => {
  return (
    <Link to={props.link}>
      <div className="status-card h-full">
        <div className="h-full flex items-center justify-between">
          <div className="status-card__icon">
            <i className={props.icon}></i>
          </div>
          <div className="status-card__info">
            <p>{props.count}</p>
            <span>{props.title}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default StatusCard;
