import React, { forwardRef, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert, AlertProps } from "@mui/material/";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/index";
import { snackBarAction } from "../../store/features/snackBarSlice";

const SnackBar = () => {
  const dispatch = useDispatch();

  const [snackBar, setSnackBar] = useState<any>();

  const { snackbardata } = useSelector((state: RootState) => state.snackBar);

  const handleClose = (id: any) => {
    dispatch(snackBarAction.close(id));
  };

  const SnackbarAlert = forwardRef<HTMLDivElement, AlertProps>(
    function SnackbarAlert(props, ref) {
      return <Alert elevation={6} ref={ref} variant="filled" {...props} />;
    }
  );

  useEffect(() => {
    setSnackBar(snackbardata);
  }, [snackbardata]);

  return (
    <React.Fragment>
      {snackBar &&
        snackBar.length > 0 &&
        snackBar.map((item: any, index: number) => (
          <Snackbar
            key={item.id}
            style={{
              marginBottom: `${3.5 * (snackBar.length - index - 1)}rem`,
              order: index,
            }}
            open={item.open}
            autoHideDuration={5000}
            onClose={(event: any, reason: any) => {
              if (reason === "clickaway") {
                return;
              }
              if (item.hide) handleClose(item.id);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <SnackbarAlert
              onClose={() => handleClose(item.id)}
              severity={item.severity as any}
            >
              {item.message}
            </SnackbarAlert>
          </Snackbar>
        ))}
    </React.Fragment>
  );
};
export default SnackBar;
