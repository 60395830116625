import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Button } from "@mui/material";
import camelCase from "camelcase";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import TextField from "../../../../components/Fields/TextField";
import { snackBarAction } from "../../../../store/features/snackBarSlice";
import UserProfileCard from "../../../../components/card/UserProfileCard";

const ProfileDetailCard = (props: any) => {
  const {
    sectionName,
    add,
    get,
    update,
    updateOne,
    remove,
    getProfileCompletionPercent,
  } = props;

  const schema = yup.object({
    name: yup.string().required("Required field"),
  });

  type FormData = yup.InferType<typeof schema>;

  const {
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema as any),
  });

  const dispatch = useDispatch();

  const [showSave, setShowSave] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [allData, setAllData] = useState<any>();
  const [updateOneData, setUpdateOneData] = useState<any>();
  const [data, setData] = useState({
    name: "",
  });

  const handleClick = () => {
    setShowUpdate(false);
    setShowSave(!showSave);
    getAllData();
    reset();
  };

  const handleClose = () => {
    setShowSave(false);
    setShowUpdate(false);
    setData({
      name: "",
    });
    getAllData();
    reset();
  };

  const handleShowUpdate = () => {
    setShowSave(false);
    setShowUpdate(!showUpdate);
    setUpdateOneData(null);
    getAllData();
    reset();
  };

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setValue("name", e.target.value);
    trigger();
  };

  const handleSave = async () => {
    if (showSave) {
      if (data.name === "") {
        setValue("name", "");
      }
      const result = await trigger(["name"]);
      if (!result) {
        return;
      }

      try {
        await add(data);
        getAllData();
        handleClose();
        getProfileCompletionPercent();
        dispatch(
          snackBarAction.open({
            message: `${camelCase(sectionName, {
              pascalCase: true,
            })} has been successfully added`,
            severity: "success",
          })
        );
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            snackBarAction.open({
              message: e.message,
              severity: "error",
            })
          );
        }
      }
    } else if (showUpdate) {
      let updateData = allData.map((obj: any) => {
        return {
          name: obj.name,
        };
      });

      try {
        await update(updateData);
        getAllData();
        setShowUpdate(false);
        getProfileCompletionPercent();
        dispatch(
          snackBarAction.open({
            message: `${
              sectionName.slice(-1) === "y"
                ? camelCase(sectionName.replace(/.$/, "ie"), {
                    pascalCase: true,
                  })
                : camelCase(sectionName, {
                    pascalCase: true,
                  })
            }s has been successfully updated`,
            severity: "success",
          })
        );
      } catch (e) {
        if (e instanceof Error) {
          dispatch(
            snackBarAction.open({
              message: e.message,
              severity: "error",
            })
          );
        }
      }
    }
  };

  const handleDelete = async (item: any) => {
    try {
      await remove(item.uuid);
      getAllData();
      getProfileCompletionPercent();
      dispatch(
        snackBarAction.open({
          message: `${camelCase(sectionName, {
            pascalCase: true,
          })} has been successfully deleted`,
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const updateInput = (e: any, item: any) => {
    const newData = allData.map((obj: any) => {
      if (obj.uuid === item.uuid) {
        return {
          ...obj,
          [e.target.name]: e.target.value,
        };
      }
      return obj;
    });
    setAllData(newData);
    if (updateOneData) {
      setValue("name", item.name);
      trigger();
    }
  };

  const handleUpdate = (item: any, index: number) => {
    setShowSave(false);
    setUpdateOneData(item.uuid);
    getAllData();
    reset();
    setValue("name", item.name);
  };

  const handleCanncelUpdateOne = () => {
    setUpdateOneData(null);
    getAllData();
    reset();
  };

  const handleSaveUpdateOne = async (item: any) => {
    if (item.name === "") {
      setValue("name", "");
    }
    const result = await trigger(["name"]);
    if (!result) {
      return;
    }

    try {
      await updateOne(item.uuid, item);
      getAllData();
      setUpdateOneData(null);
      getProfileCompletionPercent();
      dispatch(
        snackBarAction.open({
          message: `${camelCase(sectionName, {
            pascalCase: true,
          })} has been successfully updated`,
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const getAllData = async () => {
    const response = await get();
    setAllData(response.data);
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <UserProfileCard
      title={
        <React.Fragment>
          <span className="card-heading">
            {sectionName.slice(-1) === "y"
              ? camelCase(sectionName.replace(/.$/, "ie"), {
                  pascalCase: true,
                })
              : camelCase(sectionName, {
                  pascalCase: true,
                })}
            s
          </span>
          {/* <Button onClick={handleShowUpdate}>
    <i className="bx bxs-edit"></i>
  </Button> */}
        </React.Fragment>
      }
      body={
        <React.Fragment>
          <div>
            {allData && allData.length ? (
              allData.map((item: any, index: number) => (
                <React.Fragment key={index}>
                  {showUpdate ? (
                    <div className="flex justify-between items-center">
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        label={camelCase(sectionName, {
                          pascalCase: true,
                        })}
                        name="name"
                        autoComplete={sectionName}
                        autoFocus
                        value={item.name}
                        onChange={(e) => updateInput(e, item)}
                        className="w-[90%]"
                      />
                      <span className="text-[31px] flex justify-end w-[10%]">
                        <i
                          className="bx bx-trash cursor_pointer"
                          onClick={() => handleDelete(item)}
                        ></i>
                      </span>
                    </div>
                  ) : (
                    <React.Fragment>
                      {updateOneData === item.uuid ? (
                        <React.Fragment>
                          <div className="flex justify-between items-center">
                            <div className="w-[95%] flex justify-between items-center">
                              <TextField
                                required
                                margin="normal"
                                fullWidth
                                label={camelCase(sectionName, {
                                  pascalCase: true,
                                })}
                                name="name"
                                autoComplete={sectionName}
                                autoFocus
                                value={item.name}
                                onChange={(e) => updateInput(e, item)}
                                className="!w-[80%]"
                              />
                              <div className="w-[20%] flex justify-end pt-2">
                                <Button
                                  className="profile_button"
                                  onClick={() => handleSaveUpdateOne(item)}
                                >
                                  Save
                                </Button>
                                <Button
                                  className="profile_button"
                                  onClick={handleCanncelUpdateOne}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                            <span className="text-[31px] flex justify-end w-[5%] pt-1">
                              <i
                                className="bx bx-trash cursor_pointer"
                                onClick={() => handleDelete(item)}
                              ></i>
                            </span>
                          </div>
                          {errors.name && (
                            <p className="text-red-700">
                              * {errors.name.message}
                            </p>
                          )}
                        </React.Fragment>
                      ) : (
                        <ul className="list-group d-flex">
                          <li
                            key={index}
                            className="file_list cursir_pointer color_daark flex items-center"
                          >
                            {item.name}
                            <i
                              className="bx bxs-pencil cursor_pointer ml-4"
                              onClick={() => handleUpdate(item, index)}
                            ></i>
                            <i
                              className="bx bx-x cursor_pointer ml-1"
                              onClick={() => handleDelete(item)}
                            ></i>
                          </li>
                        </ul>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              ))
            ) : (
              <p className="mb-2 mt-2">
                Showcase your{" "}
                {sectionName.slice(-1) === "y"
                  ? sectionName.replace(/.$/, "ie")
                  : sectionName}
                s.
              </p>
            )}
          </div>
          <div>
            {!updateOneData && (
              <React.Fragment>
                {!showSave && !showUpdate && (
                  <Button className="profile_button" onClick={handleClick}>
                    {`Add ${sectionName}`}
                  </Button>
                )}
              </React.Fragment>
            )}

            {showSave && (
              <React.Fragment>
                <TextField
                  required
                  margin="normal"
                  fullWidth
                  label={camelCase(sectionName, {
                    pascalCase: true,
                  })}
                  name="name"
                  autoComplete={sectionName}
                  autoFocus
                  value={data.name}
                  onChange={(e) => handleChange(e)}
                />
                {errors.name && (
                  <p className="text-red-700">* {errors.name.message}</p>
                )}
              </React.Fragment>
            )}

            {(showSave || showUpdate) && (
              <React.Fragment>
                <Button className="profile_button" onClick={handleSave}>
                  Save
                </Button>
                <Button className="profile_button" onClick={handleClose}>
                  Cancel
                </Button>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      }
    />
  );
};

export default ProfileDetailCard;
