import Api from "./Api";
import authHeader from "./AuthHeader";
import axios from "axios";

const getLoggedInOrgProfile = () => {
  return Api().get("/api/org/getLoggedInOrgProfile", {
    headers: authHeader(),
  });
};

const updateOrganizationSummary = (summary: any) => {
  return Api().put("/api/org/updateOrganizationSummary", null, {
    headers: authHeader(),
    params: {
      summary: summary,
    },
  });
};

const addOrgValue = (value: any) => {
  return Api().post("/api/org/addOrgValue", null, {
    headers: authHeader(),
    params: {
      value: value.name,
    },
  });
};

const getAllOrgValues = async () => {
  return Api().get("/api/org/getAllOrgValues", {
    headers: authHeader(),
  });
};

const updateOrgValues = (values: any) => {
  return Api().put("/api/org/updateValues", values, {
    headers: authHeader(),
  });
};

const updateOrgValue = (orgValueId: any, value: any) => {
  return Api().put(`/api/org/updateOrgValue/${orgValueId}`, value, {
    headers: authHeader(),
  });
};

const deleteOrgValue = (orgValueId: string) => {
  return Api().delete(`/api/org/deleteOrgValue/${orgValueId}`, {
    headers: authHeader(),
  });
};

const addOrgPhilosophy = (philosophy: any) => {
  return Api().post("/api/org/addOrgPhilosophy", null, {
    headers: authHeader(),
    params: {
      philosophy: philosophy.name,
    },
  });
};

const getAllOrgPhilosophies = async () => {
  return Api().get("/api/org/getAllOrgPhilosophies", {
    headers: authHeader(),
  });
};

const updateOrfPhilosophies = (philosophies: any) => {
  return Api().put("/api/org/updatePhilosophies", philosophies, {
    headers: authHeader(),
  });
};

const updateOrgPhilosophy = (orgPhilosophyId: any, philosophy: any) => {
  return Api().put(
    `/api/org/updateOrgPhilosophy/${orgPhilosophyId}`,
    philosophy,
    {
      headers: authHeader(),
    }
  );
};

const deleteOrgPhilosophy = (orgPhilosophyId: string) => {
  return Api().delete(`/api/org/deleteOrgPhilosophy/${orgPhilosophyId}`, {
    headers: authHeader(),
  });
};

const uploadLogo = async (orgId: string, file: any, type: string) => {
  const link = await Api().get(
    `/api/generateURLForUpload/${type}/${orgId}/${file.name}`,
    {
      headers: authHeader(),
    }
  );

  await axios.put(link.data, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  return updateLogo(file.name);
};

const getLogoLink = async (orgId: string, fileName: any, type: string) => {
  const link = await Api().get(
    `/api/generateURLForDownload/${type}/${orgId}/${fileName}`,
    {
      headers: authHeader(),
    }
  );
  return link.data;
};

const downloadLogo = async (orgId: string, fileName: any, type: string) => {
  const link = await Api().get(
    `/api/generateURLForDownload/${type}/${orgId}/${fileName}`,
    {
      headers: authHeader(),
    }
  );
  return await axios.get(link.data, { responseType: "blob" });
};

const deleteLogo = async (orgId: string, fileName: any, type: string) => {
  const link = await Api().get(
    `/api/generateURLForDelete/${type}/${orgId}/${fileName}`,
    {
      headers: authHeader(),
    }
  );
  return await axios.delete(link.data);
};

const updateLogo = async (fileName: any) => {
  return Api().put("/api/org/updateLogo", null, {
    headers: authHeader(),
    params: {
      logo: fileName,
    },
  });
};

const getProfileCompletionPercent = () => {
  return Api().get("/api/org/getProfileCompletionPercent", {
    headers: authHeader(),
  });
};

const OrganizationService = {
  getLoggedInOrgProfile,

  //Organization Summary
  updateOrganizationSummary,

  //Value
  addOrgValue,
  getAllOrgValues,
  updateOrgValues,
  updateOrgValue,
  deleteOrgValue,

  //Philosophy
  addOrgPhilosophy,
  getAllOrgPhilosophies,
  updateOrfPhilosophies,
  updateOrgPhilosophy,
  deleteOrgPhilosophy,

  //Upload Logo
  uploadLogo,
  //Get Logo Link
  getLogoLink,
  //Download Logo
  downloadLogo,
  //Delete Logo
  deleteLogo,

  //Update Logo
  updateLogo,

  getProfileCompletionPercent,
};

export default OrganizationService;
