import React, { useState } from "react";

import Select, { StylesConfig } from "react-select";
import TimePicker from "rc-time-picker";
import { Add, HighlightOff } from "@mui/icons-material";
import {
  Fab,
  Typography,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from "@mui/material";

import "rc-time-picker/assets/index.css";
import "./SessionDetails.css";

type Day = {
  value: string;
  label: string;
};

const DAYS = [
  {
    name: "Monday",
  },
  {
    name: "Tuesday",
  },
  {
    name: "Wednesday",
  },
  {
    name: "Thursday",
  },
  {
    name: "Friday",
  },
  {
    name: "Saturday",
  },
  {
    name: "Sunday",
  },
];

const options = DAYS.map((option) => ({
  value: option.name,
  label: option.name,
}));

const customStyles: StylesConfig<Day> = {
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "unset",
    zIndex: "1000",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "unset",
    marginBottom: "0.05rem",
    borderRadius: "unset",
  }),
  option: (provided) => ({
    ...provided,
    padding: "6px 12px",
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "unset",
    minHeight: "38px",
    borderColor: "rgba(0, 0, 0, 0.42)",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#455560",
  }),
};

const SessionDetails = (props: any) => {
  const { session, setSession, register, setValue, errors, trigger } = props;

  const [day, setDay] = useState<any>("");
  const [selectedDay, setSelectedDay] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const [selectedTime, setSelectedTime] = useState<any>(null);
  const [alertDay, setAlertDay] = useState<boolean>(false);
  const [alertTime, setAlertTime] = useState<boolean>(false);

  const dayHandler = (day: any) => {
    setSelectedDay(day);
    setDay(day.value);
    setAlertDay(false);
  };

  const timeHandler = (time: any) => {
    setSelectedTime(time);
    setTime(time.format("h:mm A"));
    setAlertTime(false);
  };

  const handleChange = (e: any) => {
    let value;
    if (
      e.target.name === "workingwithchildren" ||
      e.target.name === "traveldocument"
    ) {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    if (e.target.name === "experienceyears") {
      if (!value.match(/^(\s*|\d+)$/)) {
        return;
      }
    }
    setSession({
      ...session,
      [e.target.name]: value,
    });
    setValue(e.target.name, value);

    trigger(e.target.name);
  };

  const clickHandler = () => {
    if (day === "" && time === "") {
      setAlertDay(true);
      setAlertTime(true);
      return;
    }
    if (day === "") {
      setAlertDay(true);
      setAlertTime(false);
      return;
    }
    if (time === "") {
      setAlertTime(true);
      setAlertDay(false);
      return;
    }
    setAlertDay(false);
    setAlertTime(false);

    const value = day + ": " + time;
    setSession({
      ...session,
      trainingdayandtime: [...session.trainingdayandtime, value],
    });
    setValue("trainingdayandtime", [...session.trainingdayandtime, value]);
    setDay("");
    setSelectedDay("");
    setTime("");
    setSelectedTime(null);
    trigger("trainingdayandtime");
  };

  const deleteHandler = (val: number) => {
    setSession({
      ...session,
      trainingdayandtime: session.trainingdayandtime.filter(
        (item: any, index: number) => index !== val
      ),
    });
    setValue(
      "trainingdayandtime",
      session.trainingdayandtime.filter(
        (item: any, index: number) => index !== val
      )
    );
    trigger("trainingdayandtime");
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="minimumqualification"
            label="Minimum qualification required"
            fullWidth
            required
            autoComplete="minimumqualification"
            variant="standard"
            {...register("minimumqualification")}
            onChange={handleChange}
            value={session.minimumqualification}
          />
          {errors.minimumqualification && (
            <p className="text-red-700">
              * {errors.minimumqualification.message}
            </p>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="experienceyears"
            label="No of years in experience"
            fullWidth
            autoComplete="No of years in experience"
            variant="standard"
            {...register("experienceyears")}
            onChange={handleChange}
            value={session.experienceyears}
          />
          {errors.experienceyears && (
            <p className="text-red-700">* {errors.experienceyears.message}</p>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="travelrequirements"
            label="Travel Requirements"
            fullWidth
            autoComplete="Travel Requirements"
            variant="standard"
            {...register("travelrequirements")}
            onChange={handleChange}
          />
          {errors.travelrequirements && (
            <p className="text-red-700">
              * {errors.travelrequirements.message}
            </p>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={session.traveldocument}
                onChange={handleChange}
                name="traveldocument"
              />
            }
            label="Travel document required?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={session.workingwithchildren}
                onChange={handleChange}
                name="workingwithchildren"
              />
            }
            label="Working with children check required?"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography>Days and Times</Typography>
        </Grid>
        <Grid item xs={12} md={5} className="z-20">
          <Select
            options={options}
            styles={customStyles}
            placeholder="-"
            value={selectedDay}
            onChange={dayHandler}
            // menuIsOpen={true}
          />
          <TextField
            className="!hidden"
            required
            id="daysofweek"
            label="Days of the Week"
            fullWidth
            // type={'date'}
            autoComplete="daysofweek"
            variant="standard"
            value={day}
            onChange={(e) => setDay(e.target.value)}
          />
          {alertDay && <p className="text-red-700">* Required field</p>}
        </Grid>
        <Grid item xs={12} md={5}>
          <TimePicker
            className="w-full"
            placeholder="-- : --"
            showSecond={false}
            use12Hours={true}
            allowEmpty={false}
            minuteStep={30}
            value={selectedTime}
            // onChange={(e) => setTime(e.format("h:mm A"))}
            onChange={timeHandler}
          />
          <TextField
            className="!hidden"
            required
            id="timeofday"
            label="Time of the Day"
            fullWidth
            // type={'date'}
            autoComplete="timeofday"
            variant="standard"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
          {alertTime && <p className="text-red-700">* Required field</p>}
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={clickHandler}
          >
            Add
          </Button>
        </Grid>

        <TextField
          id="trainingdayandtime"
          label="Training Days and Times"
          fullWidth
          required
          autoComplete="trainingdayandtime"
          variant="standard"
          className="!hidden"
          {...register("trainingdayandtime")}
        />
        {errors.trainingdayandtime && (
          <p className="text-red-700 pl-6">
            * {errors.trainingdayandtime.message}
          </p>
        )}

        <Grid item xs={12} md={12} className="z-10">
          <Box sx={{ "& > :not(style)": { m: 1 } }}>
            {session.trainingdayandtime.length !== 0 &&
              session.trainingdayandtime.map((item: any, index: number) => (
                <Fab
                  variant="extended"
                  size="small"
                  color="secondary"
                  aria-label="add"
                  key={index}
                >
                  <HighlightOff
                    onClick={() => deleteHandler(index)}
                    className="mr-1"
                  />

                  {item}
                </Fab>
              ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="mandatorychecks"
            label="Mandatory Checks needed"
            fullWidth
            multiline
            rows={6}
            autoComplete="Mandatory Checks needed"
            variant="standard"
            {...register("mandatorychecks")}
            onChange={handleChange}
            value={session.mandatorychecks}
          />
          {errors.mandatorychecks && (
            <p className="text-red-700">* {errors.mandatorychecks.message}</p>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SessionDetails;
