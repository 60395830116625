import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PulseLoader from "react-spinners/PulseLoader";

import AuthService from "../../services/AuthenticationService";
import { snackBarAction } from "../../store/features/snackBarSlice";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, TextField } from "@mui/material";

const schema = yup.object({
  password: yup
    .string()
    .required("Required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .min(8, "Password length should be at least 8 characters")
    .max(16, "Password cannot exceed more than 12 characters")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});

type FormData = yup.InferType<typeof schema>;

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useParams();
  const [tokenData, setTokenData] = useState<any>();
  const [userEmail, setUserEmail] = useState<any>();

  const getTokenData = async () => {
    try {
      const jobs = await AuthService.validateResetPasswordToken(token);

      if (jobs.data) {
        setTokenData(true);
        setUserEmail(token);
      } else {
        setTokenData(false);
      }
    } catch (e) {
      setTokenData(false);
    }
  };

  useEffect(() => {
    getTokenData();
  }, []);

  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const [validCredential, setValidCredential] = useState<boolean | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onTouched",
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setSignInLoading(true);

    const user = {
      token: userEmail,
      scope: "Forgot Password",
      confirmPassword: data.confirmPassword,
      password: data.password,
    };

    try {
      const response = await AuthService.resetUserPassword(user);

      if (response.data) {
        dispatch(
          snackBarAction.open({
            message: "Password Reset Successfull!",
            severity: "success",
          })
        );

        setTimeout(() => {
          setSignInLoading(false);
          reset();
          navigate("/signin", { replace: true });
        }, 1000);
      } else {
        setSignInLoading(false);
        setValidCredential(false);
        dispatch(
          snackBarAction.open({
            message: "Unable to reset password!",
            severity: "error",
          })
        );
        return;
      }
    } catch (e) {
      setTimeout(() => {
        setSignInLoading(false);
        setValidCredential(false);
      }, 750);
      return;
    }
  });

  return (
    <div className="sm:min-h-[calc(100vh-25rem)] pt-16 pb-24 main-page">
      <div className="xxs:w-5/6 xx:max-w-sm mx-auto">
        <div className="flex flex-col items-center">
          <div className="bg-secondary text-white w-10 h-10 rounded-full flex items-center justify-center mb-2">
            <LockOutlinedIcon />
          </div>
          {tokenData === false && (
            <div className="border-l-4 border-red-800 rounded-l-md">
              <p className="text-red-800 bg-red-200/75 p-5 rounded-r-md font-[500]">
                Invalid/Expired Token
              </p>
            </div>
          )}
          <h1 className="text-2xl">Reset Password</h1>
          <form onSubmit={onSubmit} className="w-full mt-2">
            <TextField
              type="password"
              margin="normal"
              fullWidth
              id="password"
              label="Password"
              autoComplete="current-password"
              {...register("password")}
              className={`${errors.password && "input-invalid"}`}
            />
            {errors.password && (
              <p className="text-red-700">* {errors.password?.message}</p>
            )}

            <TextField
              type="password"
              margin="normal"
              fullWidth
              id="confirmPassword"
              label="Confirm Password"
              autoComplete="confirm-password"
              {...register("confirmPassword")}
              className={`${errors.password && "input-invalid"}`}
            />
            {errors.password && (
              <p className="text-red-700">
                * {errors.confirmPassword?.message}
              </p>
            )}

            {validCredential === false && (
              <div className="border-l-4 border-red-800 rounded-l-md">
                <p className="text-red-800 bg-red-200/75 p-5 rounded-r-md font-[500]">
                  Unable to reset password.
                </p>
              </div>
            )}
            {tokenData === true && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {!signInLoading ? (
                  "Reset Password"
                ) : (
                  <PulseLoader
                    color="#fff"
                    size={9}
                    className="padding-spinner"
                  />
                )}
              </Button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
