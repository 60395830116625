import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../../../store";
import CoachService from "../../../../services/CoachService";
import ProfileInfomation from "../../Components/Account/ProfileInfomation";
import ContactInformation from "../../Components/Account/ContactInformation";
import LoginInformation from "../../Components/Account/LoginInfomation/LoginInformation";
import AccountSetting from "../../Components/Account/AccountSetting";

const CoachAccountDetails = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  const [coach, setCoach] = useState<any>();

  const getCoach = async () => {
    const response = await CoachService.getLoggedInCoachProfile();
    setCoach(response.data.coachUser[0]);
  };

  useEffect(() => {
    getCoach();
  }, []);

  return (
    <React.Fragment>
      <ProfileInfomation user={user} data={coach} getData={getCoach} />
      <ContactInformation user={user} data={coach} getData={getCoach} />
      <LoginInformation data={coach} />
      <AccountSetting />
    </React.Fragment>
  );
};

export default CoachAccountDetails;
