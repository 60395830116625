import React from "react";

import "./badge.css";

type Props = {
  type: string | false;
  content: string | undefined;
};

const Badge = (props: Props) => {
  return <span className={`badge badge-${props.type}`}>{props.content}</span>;
};

export default Badge;
