import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import { RootState } from "../../store/index";
import { modeAction } from "../../store/features/modeSlice";
import Sidebar from "../Dashboard/sidebar/Sidebar";
import TopNav from "../Dashboard/topnav/TopNav";
import SnackBar from "../../components/snackbar/SnackBar";
import CoachService from "../../services/CoachService";
import OrganizationService from "../../services/OrganizationService";
import { profileCompletionAction } from "../../store/features/profileCompletionSlice";
import { profilePictureAction } from "../../store/features/profilePictureSlice";
import user_image from "../../assets/images/user.png";
import "./DashboardLayout.css";

const DashboardLayout = () => {
  const dispatch = useDispatch();

  const mode = useSelector((state: RootState) => state.mode);
  const user = useSelector((state: RootState) => state.auth.user);

  const setColorMode = () => {
    // Get token from local storage

    if (user.role === "ROLE_COACH") {
      dispatch(modeAction.setColor("theme-color-blue"));
    }

    if (user.role === "ROLE_ORGUSER" || user.role === "ROLE_ORGADMIN") {
      dispatch(modeAction.setColor("theme-color-cyan"));
    }

    if (user.role === "ROLE_SUPER_ADMIN") {
      dispatch(modeAction.setColor("theme-color-red"));
    }
  };

  const getProfileCompletionPercent = async () => {
    let response: any;
    if (user.role === "ROLE_COACH") {
      response = await CoachService.getProfileCompletionPercent();
    } else if (user.role === "ROLE_ORGUSER" || user.role === "ROLE_ORGADMIN") {
      response = await OrganizationService.getProfileCompletionPercent();
    }
    dispatch(profileCompletionAction.set(response.data));
    localStorage.setItem("profileCompletion", JSON.stringify(response.data));
  };

  const setProfilePicture = async () => {
    if (user.role === "ROLE_ORGUSER" || user.role === "ROLE_ORGADMIN") {
      const organization = await OrganizationService.getLoggedInOrgProfile();
      const org = organization.data;
      if (org.logo) {
        const response = await OrganizationService.getLogoLink(
          org.uuid,
          org.logo,
          "logo"
        );
        dispatch(profilePictureAction.set(response));
        localStorage.setItem("profilePicture", JSON.stringify(response));
      } else {
        dispatch(profilePictureAction.set(user_image));
        localStorage.setItem("profilePicture", JSON.stringify(null));
      }
    } else if (user.role === "ROLE_COACH") {
      const coachUser = await CoachService.getLoggedInCoachProfile();
      const coach = coachUser.data;
      if (coach.profilePicture) {
        const response = await CoachService.getDocumentLink(
          coach.uuid,
          coach.profilePicture,
          "profile_picture"
        );
        dispatch(profilePictureAction.set(response));
        localStorage.setItem("profilePicture", JSON.stringify(response));
      } else {
        dispatch(profilePictureAction.set(user_image));
        localStorage.setItem("profilePicture", JSON.stringify(null));
      }
    } else {
      dispatch(profilePictureAction.set(user_image));
      localStorage.setItem("profilePicture", JSON.stringify(null));
    }
  };

  useEffect(() => {
    setColorMode();
    if (
      user.role === "ROLE_COACH" ||
      user.role === "ROLE_ORGUSER" ||
      user.role === "ROLE_ORGADMIN"
    ) {
      getProfileCompletionPercent();
    }
    setProfilePicture();
  }, [dispatch]);

  return (
    <div>
      <div className={`layout ${mode.theme} ${mode.color}`}>
        <Sidebar />
        <div className="layout__content">
          <TopNav />
          <div className="layout__content-main">
            <Outlet />
          </div>
        </div>
        <SnackBar />
      </div>
    </div>
  );
};

export default DashboardLayout;
