import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import SnackBar from "../../components/snackbar/SnackBar";
import { snackBarAction } from "../../store/features/snackBarSlice";
import { RootState } from "../../store";

import "react-toastify/dist/ReactToastify.css";
import "../../pages/Main/MainPage.css";

const MainLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { snackbardata } = useSelector((state: RootState) => state.snackBar);

  useEffect(() => {
    if (
      location.pathname === "/signin" ||
      location.pathname === "/signup" ||
      location.pathname === "/org/signup" ||
      location.pathname === "/org/signin"
    ) {
      if (
        !snackbardata.some(
          (item: any) =>
            item.message ===
            "By using our site, you acknowledge that you have read and understand our privacy policy and our Terms of Service"
        )
      ) {
        dispatch(
          snackBarAction.open({
            message:
              "By using our site, you acknowledge that you have read and understand our privacy policy and our Terms of Service",
            severity: "info",
            hide: false,
          })
        );
      }
    }
  }, [location.pathname]);

  return (
    <div className="app-container">
      <Header />
      <Outlet />
      <Footer />
      <SnackBar />
    </div>
  );
};

export default MainLayout;
