import React from "react";
import Table from "../../../components/table/Table";
import customerList from "../../../assets/JsonData/matchedapplicants-list.json";
import { TableHead, TableBody } from "../../../types/types";

const customerTableHead = [
  "",
  "name",
  "email",
  "role",
  "location",
];

const renderHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const renderBody = (item: TableBody, index: number) => (
  <tr key={index}>
    <td>{item.id}</td>
    <td>{item.name}</td>
    <td>{item.email}</td>
    <td>{item.role}</td>
    <td>{item.location}</td>
  </tr>
);

const Customers = () => {
  return (
    <div>
      <h2 className="page-header">matched applicants</h2>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card__body">
              <Table
                limit="10"
                headData={customerTableHead}
                renderHead={(item, index) => renderHead(item, index)}
                bodyData={customerList}
                renderBody={(item, index) => renderBody(item, index)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
