import React from "react";
import { Link } from "react-router-dom";

import { Button } from "@mui/material";

const OrgSuccess = () => {
  return (
    <>
      <div className="successBlock">
        <span className="successIcon">
          <i className="bx bx-check-circle"></i>
        </span>
        <h1 className="successTitle">Organization Registration Successful!</h1>
        <p className="confirmationTitle">
          A Confirmation email will be sent to the registered email.
        </p>
        <div className="redirectContainer">
          <Link to="/org/signin">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, width: "10rem" }}
              className="xxs:text-xl  xs:text-2xl"
            >
              BACK TO SIGNIN
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default OrgSuccess;
