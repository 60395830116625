import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  Box,
  Typography,
} from "@mui/material";

import companyLogo from "../../../assets/images/example-logo.png";
import "./AppliedViewJob.css";
import CoachService from "../../../services/CoachService";
import DashBoardCard from "../../../components/card/DashBoardCard";
const AppliedViewJob = () => {
  const { applyJobId } = useParams();
  const [appliedJob, setAppliedJob] = useState<any>();

  const getAppliedJobData = async () => {
    const response = await CoachService.getAppliedJobPost(applyJobId);
    setAppliedJob(response.data[0]);
  };

  useEffect(() => {
    getAppliedJobData();
  }, []);

  return (
    appliedJob && (
      <DashBoardCard
        body={
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Container maxWidth="lg" className="content-header">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  {appliedJob.jobPost.role}
                </Typography>
                <Link to="/" className="org-name-header">
                  <Typography>
                    {appliedJob.jobPost.organization.companyName}
                  </Typography>
                </Link>
                <Typography>
                  {appliedJob.jobPost.jobPostRoleTypes.map((item: any) => (
                    <React.Fragment key={item.uuid}>{item.name}</React.Fragment>
                  ))}
                </Typography>
                <div className="button-header">
                  <Button variant="contained" size="large" disabled>
                    Applied on{" "}
                    {moment(appliedJob.dateApplied).format("DD/MM/YYYY")}
                  </Button>

                  <Button
                    sx={{ ml: 2, height: "100%" }}
                    variant="outlined"
                    size="large"
                  >
                    <i className="bx bx-heart"></i>
                    Like
                  </Button>
                </div>
              </Container>
              <Container maxWidth="lg">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  Job details
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>Job Type</Typography>
                <Typography>
                  {appliedJob.jobPost.jobPostRoleTypes.map((item: any) => (
                    <React.Fragment key={item.uuid}>{item.name}</React.Fragment>
                  ))}
                </Typography>
              </Container>
              <Divider sx={{ my: 2 }} />
              <Container maxWidth="lg">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  Full Job Description
                </Typography>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Job Description
                </Typography>
                <Typography sx={{ my: 1 }}>
                  Job type:{" "}
                  {appliedJob.jobPost.jobPostRoleTypes.map((item: any) => (
                    <React.Fragment key={item.uuid}>{item.name}</React.Fragment>
                  ))}
                </Typography>
                <Typography sx={{ my: 1 }}>
                  Organisation: {appliedJob.jobPost.organization.companyName}
                </Typography>
                <Typography sx={{ my: 1 }}>Salary: -</Typography>
                <Typography sx={{ my: 1 }}>Occupation: -</Typography>
                <Typography sx={{ my: 1 }}>Reference: -</Typography>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Selection Criteria
                </Typography>
                <Typography sx={{ my: 1 }}>SC1</Typography>
                <Typography sx={{ my: 1 }}>SC2</Typography>
                <Typography sx={{ my: 1 }}>SC3</Typography>
                <Typography sx={{ my: 1 }}>SC4</Typography>
                <Typography sx={{ my: 1 }}>SC5</Typography>
                <Typography sx={{ my: 1 }}>SC6</Typography>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Role
                </Typography>
                <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1 }}>
                  Role and Responsibilities
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={4}>
              <Container maxWidth="lg" sx={{ pt: 2 }}>
                <Card>
                  <CardHeader title="Company Info" />
                  <Divider />
                  <CardContent className="company-info-content">
                    <img src={companyLogo} alt="" />
                    <Button variant="outlined" size="small" sx={{ mt: 2 }}>
                      Follow
                    </Button>
                    <Typography sx={{ mt: 2 }}>
                      Get job updates from{" "}
                      {appliedJob.jobPost.organization.companyName}
                    </Typography>
                  </CardContent>
                </Card>
              </Container>
            </Grid>
          </Grid>
        }
      ></DashBoardCard>
    )
  );
};

export default AppliedViewJob;
