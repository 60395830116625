import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import TextField from "../../../../components/Fields/TextField";
import EmailService from "../../../../services/EmailService";
import "./CKEditor.css";

type Props = {};

const EditEmailTemplate = (props: Props) => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [isShown, setIsShown] = useState<boolean>(false);
  const [emailTemplate, setEmailTemplate] = useState<any>();

  const handleClick = () => {
    setIsShown(!isShown);
  };

  const handleSave = async (e: any) => {
    e.preventDefault();
    const response = await EmailService.updateEmailTemplate(emailTemplate);
    console.log(response);
    navigate("/admin/systemsettings/emailtemplate");
  };

  const handleEdit = (e: any) => {
    setEmailTemplate({ ...emailTemplate, [e.target.name]: e.target.value });
  };

  const handleChange = (e: any, editor: any) => {
    var data = editor.getData();
    data = data.replace("<p>", "");
    data = data.replace("</p>", "");
    setEmailTemplate({ ...emailTemplate, body: data });
  };

  const getData = async () => {
    const response = await EmailService.getEmailTemplateById(templateId);
    setEmailTemplate(response.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    emailTemplate && (
      <div className="c_card mb-5">
        <div className="card-title profile_title">
          <span className="card-heading">Email Template</span>
        </div>
        <div className="card-body">
          {<p className="mb-2 mt-2">Add a new email template</p>}
          <div className="mb-2">
            <form className="w-full mt-2" onSubmit={(e: any) => handleSave(e)}>
              <TextField
                required
                margin="normal"
                fullWidth
                label="title"
                name="title"
                autoComplete="given-title"
                autoFocus
                defaultValue={emailTemplate.title}
                onChange={(e) => handleEdit(e)}
              />

              <TextField
                required
                margin="normal"
                fullWidth
                label="subject"
                name="subject"
                autoComplete="given-title"
                autoFocus
                onChange={(e) => handleEdit(e)}
              />

              <TextField
                required
                id="dateModified"
                label="Modified Date"
                type={"date"}
                InputLabelProps={{ shrink: true }}
                fullWidth
                name="dateModified"
                autoComplete="dateModified"
                variant="standard"
                onChange={(e) => handleEdit(e)}
              />

              <p className="mb-3 mt-3">Add the body for email template</p>
              <CKEditor
                editor={ClassicEditor}
                name="Email Template Body"
                initData={" "}
                className="h-10"
                onChange={(e: any, editor: any) => handleChange(e, editor)}
              />
              <div>
                {
                  <React.Fragment>
                    <Button type="submit" className="profile_button">
                      Save
                    </Button>
                    <Button onClick={handleClick} className="profile_button">
                      <Link to="/admin/systemsettings/emailtemplate">
                        {" "}
                        Cancle{" "}
                      </Link>
                    </Button>
                  </React.Fragment>
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default EditEmailTemplate;
