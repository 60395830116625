import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PulseLoader from "react-spinners/PulseLoader";

import AuthServices from "../../../services/AuthenticationService";
import UserService from "../../../services/UserService";
import { authAction } from "../../../store/features/authSlice";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { snackBarAction } from "../../../store/features/snackBarSlice";

const phoneRegExp = /^\d{10}$/;

const schema = yup.object({
  orgName: yup.string().required("Required field"),
  abn: yup
    .string()
    .required("Required field")
    .matches(/^[0-9]+$/, "Invalid ABN Number")
    .min(11, "Invalid ABN Number")
    .max(11, "Invalid ABN Number"),
  orgType: yup.string().required("Required field"),
  email: yup.string().required("Required field").email("Invalid email address"),
  address: yup.string().required("Required field"),
  contactNumber: yup
    .string()
    .required("Required field")
    .matches(phoneRegExp, "Invalid phone number"),
  primaryContactFirstName: yup.string().required("Required field"),
  primaryContactLastName: yup.string().required("Required field"),
  password: yup
    .string()
    .required("Required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Required field")
    .oneOf([yup.ref("password")], "Passwords must match"),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], "You need to accept the terms and conditions"),
});

type FormData = yup.InferType<typeof schema>;

const OrganisationSignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [signUpLoading, setSignUpLoading] = useState<boolean>(false);
  const [error, setError] = useState();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setSignUpLoading(true);

    const user = {
      orgName: data.orgName,
      abn: data.abn,
      orgType: data.orgType,
      email: data.email,
      address: data.address,
      contactNumber: data.contactNumber,
      primaryContactFirstName: data.primaryContactFirstName,
      primaryContactLastName: data.primaryContactLastName,
      password: data.password,
    };

    try {
      const response = await AuthServices.orgSignup(user);
      if (response.data) {
        localStorage.setItem("token", JSON.stringify(response.data));

        const userData = await UserService.getUserDetails(
          response.data.username
        );
        localStorage.setItem("user", JSON.stringify(userData.data));

        dispatch(
          authAction.login({ token: response.data, user: userData.data })
        );

        setTimeout(() => {
          setSignUpLoading(false);
          reset();
          navigate("/orgsuccess");
        }, 1000);
      } else {
        setSignUpLoading(false);
        return;
      }
    } catch (e: any) {
      console.log(e);
      setTimeout(() => {
        setSignUpLoading(false);
        if (e.response.data.status === 500) {
          dispatch(
            snackBarAction.open({
              message: `${
                JSON.parse(e.config.data).email
              } is already taken. Please login to skip this step or use another email.`,
              severity: "error",
            })
          );
        } else {
          setError(e.response.data.message);
        }
      }, 750);
      return;
    }
  });

  return (
    <div className="main-page pb-24">
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register as an Organization
          </Typography>
          <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="organisation"
                  fullWidth
                  id="orgName"
                  label="Company Name"
                  autoFocus
                  className={`${errors.orgName && "input-invalid"}`}
                  {...register("orgName")}
                />
                {errors.orgName && (
                  <p className="text-red-700">* {errors.orgName?.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="abn"
                  label="ABN"
                  autoComplete="abn"
                  className={`${errors.abn && "input-invalid"}`}
                  {...register("abn")}
                />
                {errors.abn && (
                  <p className="text-red-700">* {errors.abn?.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Business Type
                  </InputLabel>
                  <Select
                    labelId="orgType-label"
                    id="orgType"
                    label="Business Type"
                    defaultValue=""
                    className={`${errors.orgType && "input-invalid"}`}
                    {...register("orgType")}
                  >
                    <MenuItem value="Private Company">Private Company</MenuItem>
                    <MenuItem value="Public Company">Public Company</MenuItem>
                    <MenuItem value="Volunteer">Volunteer</MenuItem>
                  </Select>
                </FormControl>
                {errors.orgType && (
                  <p className="text-red-700">* {errors.orgType?.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  autoComplete="email"
                  className={`${errors.email && "input-invalid"}`}
                  {...register("email")}
                />
                {errors.email && (
                  <p className="text-red-700">* {errors.email?.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="address"
                  label="Address"
                  autoComplete="address"
                  className={`${errors.address && "input-invalid"}`}
                  {...register("address")}
                />
                {errors.address && (
                  <p className="text-red-700">* {errors.address?.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="contactNumber"
                  label="Contact number"
                  autoComplete="phone"
                  className={`${errors.contactNumber && "input-invalid"}`}
                  {...register("contactNumber")}
                />
                {errors.contactNumber && (
                  <p className="text-red-700">
                    * {errors.contactNumber?.message}
                  </p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="primaryContactFirstName"
                  label="Primary Contact First Name"
                  autoComplete="primaryContactFirstName"
                  className={`${
                    errors.primaryContactFirstName && "input-invalid"
                  }`}
                  {...register("primaryContactFirstName")}
                />
                {errors.primaryContactFirstName && (
                  <p className="text-red-700">
                    * {errors.primaryContactFirstName?.message}
                  </p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="primaryContactLastName"
                  label="Primary Contact Last Name"
                  autoComplete="primaryContactLastName"
                  className={`${
                    errors.primaryContactLastName && "input-invalid"
                  }`}
                  {...register("primaryContactLastName")}
                />
                {errors.primaryContactLastName && (
                  <p className="text-red-700">
                    * {errors.primaryContactLastName?.message}
                  </p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type={"password"}
                  id="password"
                  label="Password"
                  className={`${errors.password && "input-invalid"}`}
                  {...register("password")}
                />
                {errors.password && (
                  <p className="text-red-700">* {errors.password?.message}</p>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type={"password"}
                  id="confirmpassword"
                  label="Confirm Password"
                  className={`${errors.confirmPassword && "input-invalid"}`}
                  {...register("confirmPassword")}
                />
                {errors.confirmPassword && (
                  <p className="text-red-700">
                    * {errors.confirmPassword?.message}
                  </p>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="termsAndConditions"
                      color="primary"
                      className={`${
                        errors.termsAndConditions && "input-invalid"
                      }`}
                      {...register("termsAndConditions")}
                    />
                  }
                  label="I agree to the terms and conditions set forth by SmartSportHR."
                />
                {errors.termsAndConditions && (
                  <p className="text-red-700">
                    * {errors.termsAndConditions?.message}
                  </p>
                )}
              </Grid>
            </Grid>
            {error && (
              <div className="border-l-4 border-red-800 rounded-l-md mt-6">
                <p className="text-red-800 bg-red-200/75 p-5 rounded-r-md font-[500]">
                  {error}
                </p>
              </div>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {!signUpLoading ? (
                "Sign Up"
              ) : (
                <PulseLoader
                  color="#fff"
                  size={9}
                  className="padding-spinner"
                />
              )}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin">Already have an account? Sign in</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default OrganisationSignUp;
