import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import UserProfileCard from "../../../../components/card/UserProfileCard";
import { snackBarAction } from "../../../../store/features/snackBarSlice";
import { profilePictureAction } from "../../../../store/features/profilePictureSlice";
import {
  isValidImageFileUploaded,
  isValidImageFileSize,
} from "../../../../components/file/FileValidation";

import { Button } from "@mui/material";
import Dropzone from "react-dropzone";

const ProfilePicture = (props: any) => {
  const {
    sectionName,
    data,
    subData,
    getData,
    getProfileCompletionPercent,
    fileType,
    uploadPicture,
    downloadPicture,
    deletePicture,
    getPictureLink,
  } = props;

  const [isTool, setIsTool] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [isUpload, setIsUpload] = useState(true);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const dispatch = useDispatch();

  const handleFileInput = (e: any) => {
    setSelectedFile(e[0]);
  };

  const ShowTool = () => {
    setIsTool(!isTool);
    setIsSave(true);
    setIsUpload(!isUpload);
  };

  const Cancel = () => {
    setIsUpload(!isUpload);
    setIsTool(!isTool);
    setIsSave(false);
    setSelectedFile(null);
  };

  const uploadFile = async (file: any) => {
    if (!selectedFile) {
      dispatch(
        snackBarAction.open({
          message: "Please select file to upload",
          severity: "error",
        })
      );
      return;
    }

    if (!isValidImageFileUploaded(file)) {
      dispatch(
        snackBarAction.open({
          message: "Invalid File Format",
          severity: "error",
        })
      );
      return;
    }

    if (!isValidImageFileSize(file)) {
      dispatch(
        snackBarAction.open({
          message: "File size should not be greater than 5MB",
          severity: "error",
        })
      );
      return;
    }

    if (data[subData]) {
      deletePicture(data.uuid, data[subData], fileType);
    }

    try {
      await uploadPicture(data.uuid, file, fileType);
      setIsUpload(!isUpload);
      setIsTool(!isTool);
      setIsSave(false);
      setSelectedFile(null);
      getData();
      getProfileCompletionPercent();
      dispatch(
        snackBarAction.open({
          message: `${sectionName} has been successfully added`,
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e,
            severity: "error",
          })
        );
      }
    }
  };

  const handleDownload = async (fileName: string) => {
    const response = await downloadPicture(data.uuid, fileName, fileType);

    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const getPicture = async () => {
    const response = await getPictureLink(data.uuid, data[subData], fileType);

    dispatch(profilePictureAction.set(response));
    localStorage.setItem("profilePicture", JSON.stringify(response));
  };

  useEffect(() => {
    if (data[subData]) {
      getPicture();
    }
  }, [data]);

  return (
    <UserProfileCard
      title={
        <React.Fragment>
          <span className="card-heading">{sectionName}</span>
        </React.Fragment>
      }
      body={
        <React.Fragment>
          <div>
            {data[subData] && (
              <ul className="list-group">
                <li className="list-group-item grid grid-cols-12 gap-4">
                  <div className="col-span-4 pl-0">
                    <div
                      className="file_list cursor_pointer self-center text-white bg-[#1976d2]"
                      onClick={() => handleDownload(data[subData])}
                    >
                      {data[subData]}
                    </div>
                  </div>
                </li>
              </ul>
            )}
            {/* Drag Drop Tool  */}
            {isTool && (
              <Dropzone onDrop={handleFileInput}>
                {({ getRootProps, getInputProps }) => (
                  <div className="drop_tool">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input className="input-zone" {...getInputProps()} />
                      <div className="text-center">
                        {selectedFile && selectedFile.name ? (
                          <p className="dropzone-content">
                            {selectedFile && selectedFile.name}
                          </p>
                        ) : (
                          <p className="dropzone-content">
                            Drag and drop some files here, or click to select
                            files
                          </p>
                        )}
                        <Button className="browse_button">Browse</Button>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            )}
            {/* Add Picture Button  */}
            {isUpload && (
              <Button className="profile_button" onClick={ShowTool}>
                {`${data[subData] ? "Change " : "Add "}`}
                {sectionName}
              </Button>
            )}
            {isSave && (
              <React.Fragment>
                <Button
                  variant="contained"
                  component="label"
                  className="profile_button"
                  onClick={() => uploadFile(selectedFile)}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  component="label"
                  className="profile_button"
                  onClick={() => Cancel()}
                >
                  Cancel
                </Button>
                {/* <LinearProgress
                variant="determinate"
                className="mt-2"
                value={progress}
              /> */}
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      }
    />
  );
};

export default ProfilePicture;
