import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PulseLoader from "react-spinners/PulseLoader";

import AuthService from "../../../services/AuthenticationService";

import { Typography, Button, TextField } from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";

const schema = yup.object({
  email: yup.string().required("Required field").email("Invalid email address"),
});

type FormData = yup.InferType<typeof schema>;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [validCredential, setValidCredential] = useState<boolean | null>(null);
  const [signInLoading, setSignInLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    setSignInLoading(true);

    try {
      await AuthService.forgotPassword(data);
      setValidCredential(true);
      navigate("/email-reset-received");
    } catch (e) {
      setSignInLoading(false);
      setValidCredential(false);
    }
  });

  return (
    <div className="sm:min-h-[calc(100vh-25rem)] pt-16 pb-24 main-page">
      <div className="xxs:w-5/6 xx:max-w-sm mx-auto">
        <div className="flex flex-col items-center">
          <div className="bg-secondary text-white w-10 h-10 rounded-full flex items-center justify-center mb-2">
            <LockResetIcon />
          </div>
          <Typography component="h1" variant="h5">
            Reset Your Password
          </Typography>
          <form onSubmit={onSubmit} className="w-full mt-2">
            <TextField
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              autoComplete="email"
              autoFocus
              {...register("email")}
              className={`${errors.email && "input-invalid"} `}
            />
            {errors.email && (
              <p className="text-red-700">* {errors.email?.message}</p>
            )}

            {validCredential === false && (
              <div className="border-l-4 border-red-800 rounded-l-md">
                <p className="text-red-800 bg-red-200/75 p-5 rounded-r-md font-[500]">
                  Incorrect email address, please check your details and try
                  again.
                </p>
              </div>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {!signInLoading ? (
                "Send Link"
              ) : (
                <PulseLoader
                  color="#fff"
                  size={9}
                  className="padding-spinner"
                />
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
