export const APP_TITLE =
  process.env.REACT_APP_APP_NAME === ""
    ? "SmartSportHR"
    : process.env.REACT_APP_APP_NAME;
export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_API_URL === ""
    ? ""
    : process.env.REACT_APP_BACKEND_API_URL;
export const APP_URL =
  process.env.REACT_APP_FRONTEND_WEBSITE === ""
    ? ""
    : process.env.REACT_APP_FRONTEND_WEBSITE;
export const JSONSERVER_URL = process.env.REACT_APP_BACKEND_JSONSERVER_URL === "" ? "" : process.env.REACT_APP_BACKEND_JSONSERVER_URL;

/* AWS S3 config options */
/* Highly recommended to declare the config object in an external file import it when needed */
export const S3_BUCKET_NAME =
  process.env.REACT_APP_AWS_BUCKET_NAME === ""
    ? ""
    : process.env.REACT_APP_AWS_BUCKET_NAME;
export const S3_BUCKET_REGION =
  process.env.REACT_APP_AWS_BUCKET_REGION === ""
    ? ""
    : process.env.REACT_APP_AWS_BUCKET_REGION;
export const S3_ACCESS_KEY_ID =
  process.env.REACT_APP_AWS_ACCESS_KEY_ID === ""
    ? ""
    : process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const S3_ACCESS_SECRET_KEY =
  process.env.REACT_APP_AWS_ACCESS_SECRET_KEY === ""
    ? ""
    : process.env.REACT_APP_AWS_ACCESS_SECRET_KEY;
