import React from "react";

const UserDetailSection = (props: any) => {
  return (
    <React.Fragment>
      <div className="font-semibold text-xl mb-6">{props.header}</div>
      <div>{props.children}</div>
    </React.Fragment>
  );
};

export default UserDetailSection;
