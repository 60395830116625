import Api from "./Api";

type SignUpCredential = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  password: string;
};

type SignInCredential = {
  username: string;
  password: string;
};

type OrgSignUpCredential = {
  orgName: string;
  abn: string;
  orgType: string;
  email: string;
  address: string;
  contactNumber: string;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  password: string;
};

type resetPassword = {
  token: string;
  scope: string;
  password: string;
  confirmPassword: string | undefined;
};

type Email = {
  email: string;
};

//Coach Signup
const signup = (credential: SignUpCredential) => {
  return Api().post("api/coach/signUp", credential);
};

//Org Signup
const orgSignup = (credential: OrgSignUpCredential) => {
  return Api().post("api/org/signUp", credential);
};

//Signin
const signin = (credential: SignInCredential) => {
  return Api().post("api/login", credential);
};

//Signout
const signout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("profileCompletion");
  localStorage.removeItem("profilePicture");
};

//Validate Reset Password Token
const validateResetPasswordToken = (token: string | undefined) => {
  return Api().post("api/user/validateUserToken", {
    token: token,
    scope: "Forgot Password",
  });
};

//Validate Confirm Account Token
const validateConfirmAccountToken = (token: string | undefined) => {
  return Api().post("api/user/validateUserToken", {
    token: token,
    scope: "Confirm Account",
  });
};

//Forgot Password
const forgotPassword = (email: Email) => {
  return Api().post("/api/user/forgotPassword", { username: email.email });
};

//Reset Password
const resetUserPassword = (resetPassword: resetPassword) => {
  return Api().post("api/user/resetPassword", resetPassword);
};

const authenticationService = {
  signup,
  orgSignup,
  signin,
  signout,
  validateResetPasswordToken,
  validateConfirmAccountToken,
  resetUserPassword,
  forgotPassword,
};

export default authenticationService;
