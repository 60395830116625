import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = (props: any) => {
  const location = useLocation();

  return props.isAuthenticated &&
    props.role.includes(props.user.role as string) ? (
    <Outlet />
  ) : props.isAuthenticated &&
    !props.role.includes(props.user.role as string) ? (
    <Navigate to="/403" state={{ from: location }} replace />
  ) : (
    <Navigate to={props.path} state={{ from: location }} replace />
  );
};

export default RequireAuth;
