import axios from "axios";
import { BACKEND_URL } from "../config/constants";

//Create connection to backend
const api = () => {
  return axios.create({
    baseURL:
    BACKEND_URL,
  });
};

export default api;
