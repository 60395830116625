import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import UserService from "../../../../services/UserService";
import { snackBarAction } from "../../../../store/features/snackBarSlice";
import { authAction } from "../../../../store/features/authSlice";
import UserProfileCard from "../../../../components/card/UserProfileCard";

import { Button, TextField } from "@mui/material";

const schema = yup.object({
  firstName: yup.string().trim().required("Required field"),
  lastName: yup.string().trim().required("Required field"),
});

type FormData = yup.InferType<typeof schema>;

const ProfileInfomation = (props: any) => {
  const { user, data, getData } = props;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const [isShown, setIsShown] = useState(false);

  const handleClick = () => {
    setIsShown(!isShown);
    reset();
  };

  const handleUpdateUserName = handleSubmit(async (data) => {
    try {
      await UserService.updateUserName({
        firstName: data.firstName,
        lastName: data.lastName,
      });
      getData();
      handleClick();
      dispatch(
        snackBarAction.open({
          message: "Profile infomation has been successfully updated",
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  });

  useEffect(() => {
    if (data) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          firstName: data.firstName,
          lastName: data.lastName,
        })
      );
      dispatch(
        authAction.update({
          user: {
            ...user,
            firstName: data.firstName,
            lastName: data.lastName,
          },
        })
      );
    }
  }, [data]);

  return (
    data && (
      <UserProfileCard
        title={
          <React.Fragment>
            <span className="card-heading">Profile Information</span>
            <Button onClick={handleClick}>
              <i className="bx bxs-edit"></i>
            </Button>
          </React.Fragment>
        }
        body={
          <React.Fragment>
            <p>{`${data.firstName} ${data.lastName}`}</p>
          </React.Fragment>
        }
        body_2={
          <React.Fragment>
            {isShown && (
              <div className="c_card mb-5">
                <div className="card-body">
                  <form className="w-full mt-2" onSubmit={handleUpdateUserName}>
                    <TextField
                      autoComplete="given-name"
                      fullWidth
                      margin="normal"
                      id="firstName"
                      label="First Name"
                      defaultValue={data.firstName}
                      {...register("firstName")}
                    />
                    {errors.firstName && (
                      <p className="text-red-700 mb-2">
                        * {errors.firstName?.message}
                      </p>
                    )}
                    <TextField
                      fullWidth
                      id="lastName"
                      margin="normal"
                      label="Last Name"
                      autoComplete="family-name"
                      defaultValue={data.lastName}
                      {...register("lastName")}
                    />
                    {errors.lastName && (
                      <p className="text-red-700 mb-2">
                        * {errors.lastName?.message}
                      </p>
                    )}
                    <div>
                      <Button type="submit" className="profile_button">
                        Save
                      </Button>
                      <Button onClick={handleClick} className="profile_button">
                        Cancel
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </React.Fragment>
        }
      />
    )
  );
};

export default ProfileInfomation;
