import React from "react";

type Props = {
  title: string;
  icon: string;
  active?: boolean;
  textActive?: boolean;
};

const SidebarItem = (props: Props) => {
  const active = props.active ? "active" : "";
  const textActive = props.textActive && "textActive";

  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active} ${textActive}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

export default SidebarItem;
