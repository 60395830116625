import React from "react";

const UserProfileCard = (props: any) => {
  const { title, body, body_2 } = props;
  return (
    <React.Fragment>
      <div className="c_card mb-5">
        <div className="card-title profile_title">{title}</div>
        <div className="card-body">{body}</div>
      </div>
      {body_2 && <React.Fragment>{body_2}</React.Fragment>}
    </React.Fragment>
  );
};

export default UserProfileCard;
