import Api from "./Api";
import authHeader from "./AuthHeader";
import axios from "axios";

const getLoggedInCoachProfile = () => {
  return Api().get("/api/coach/getLoggedInCoachProfile", {
    headers: authHeader(),
  });
};

const getAllCoaches = () => {
  return Api().get("/api/coach/getAll", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getCoachById = (userId: string | undefined) => {
  return Api().get(`/api/coach/profile/${userId}`, {
    headers: authHeader(),
  });
};

//Get Coach Profile
const getCoachProfile = (id: string) => {
  return Api().get(`/api/coach/profile/${id}`, {
    headers: authHeader(),
  });
};

//Updates personal summary
const updatePersonalSummary = (summary: string | undefined) => {
  return Api().put("/api/coach/updatePersonalSummary", summary, {
    headers: authHeader(),
  });
};

//Add Goals
const addGoals = (careerGoals: any) => {
  return Api().post("/api/coach/addGoal", careerGoals, {
    headers: authHeader(),
  });
};

const getCoachGoals = () => {
  return Api().get(`/api/coach/getAllCoachGoals`, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Update Goals
const updateGoals = (careerGoals: any) => {
  return Api().put("/api/coach/updateGoals", careerGoals, {
    headers: authHeader(),
  });
};

//Update Goal
const updateGoal = (goalId: any, careerGoal: any) => {
  return Api().put(`/api/coach/updateGoal/${goalId}`, careerGoal, {
    headers: authHeader(),
  });
};

//Delete Goal
const deleteGoal = (id: string) => {
  return Api().delete(`/api/coach/deleteGoal/${id}`, {
    headers: authHeader(),
  });
};

//Add Values
const addValues = (careerValues: any) => {
  return Api().post("/api/coach/addValue", careerValues, {
    headers: authHeader(),
  });
};

const getCoachValues = () => {
  return Api().get(`/api/coach/getAllCoachValues`, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Update Values
const updateValues = (careerValues: any) => {
  return Api().put("/api/coach/updateValues", careerValues, {
    headers: authHeader(),
  });
};

//Update Value
const updateValue = (valueId: any, careerValue: any) => {
  return Api().put(`/api/coach/updateValue/${valueId}`, careerValue, {
    headers: authHeader(),
  });
};

//Delete Value
const deleteValue = (id: string) => {
  return Api().delete(`/api/coach/deleteValue/${id}`, {
    headers: authHeader(),
  });
};

//Add Career History
const addCareerHistory = (careerHistory: any) => {
  return Api().post("/api/coach/addCareerHistory", careerHistory, {
    headers: authHeader(),
  });
};

//Get All Career History
const getAllCareerHistory = () => {
  return Api().get("/api/coach/getAllCareerHistory", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Updates career histories
//Pass the following object
//   [
//     {
//         jobTitle: "",
//         company: "",
//     },
//     {
//         jobTitle: "",
//         company: "",
//     },
//   ]
const updateCareerHistories = (careerHistories: any) => {
  return Api().put("/api/coach/updateCareerHistories", careerHistories, {
    headers: authHeader(),
  });
};

const updateCareerHistory = (careerHistoryId: number, careerHistory: any) => {
  return Api().put(
    `/api/coach/updateCareerHistory/${careerHistoryId}`,
    careerHistory,
    {
      headers: authHeader(),
    }
  );
};

//Delete Career History
const deleteCareerHistory = (careerHistoryId: string) => {
  return Api().delete(`/api/coach/deleteCareerHistory/${careerHistoryId}`, {
    headers: authHeader(),
  });
};

//Add Certification
const addCertifications = (certifications: any) => {
  return Api().post("/api/coach/addCertification", certifications, {
    headers: authHeader(),
  });
};

//Get All Certifications
const getCertifications = () => {
  return Api().get("/api/coach/getAllCoachCertifications", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Update Certifications
//Pass the following object
//   [
//     {
//         name: ""
//     },
//     {
//         name: ""
//     },
//   ]
const updateCertifications = (careerCertifications: any) => {
  return Api().put("/api/coach/updateCertifications", careerCertifications, {
    headers: authHeader(),
  });
};

const updateCertification = (
  certificationId: any,
  careerCertification: any
) => {
  return Api().put(
    `/api/coach/updateCertification/${certificationId}`,
    careerCertification,
    {
      headers: authHeader(),
    }
  );
};

//Delete Certification
const deleteCertification = (certificationId: string) => {
  return Api().delete(`/api/coach/deleteCertification/${certificationId}`, {
    headers: authHeader(),
  });
};

//Updates skills
//Pass the following object
//     {
//         name: ""
//     }
const addSkills = (careerSkills: any) => {
  return Api().post("/api/coach/addSkill", careerSkills, {
    headers: authHeader(),
  });
};

const getCoachSkills = () => {
  return Api().get(`/api/coach/getAllCoachSkills`, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

//Updates skills
//Pass the following object
//   [
//     {
//         name: ""
//     },
//     {
//         name: ""
//     },
//   ]
const updateSkills = (careerSkills: any) => {
  return Api().put("/api/coach/updateSkills", careerSkills, {
    headers: authHeader(),
  });
};

//Update Skill
const updateSkill = (skillId: any, careerSkill: any) => {
  return Api().put(`/api/coach/updateSkill/${skillId}`, careerSkill, {
    headers: authHeader(),
  });
};

//Delete skill
const deleteSkill = (id: string) => {
  return Api().delete(`/api/coach/deleteSkill/${id}`, {
    headers: authHeader(),
  });
};

const addLanguage = (language: any) => {
  return Api().post("/api/coach/addCoachLanguage", language, {
    headers: authHeader(),
  });
};

const getCoachLanguages = () => {
  return Api().get("/api/coach/getCoachLanguages", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const updateLanguages = (language: any) => {
  return Api().put("/api/coach/updateCoachLanguages", language, {
    headers: authHeader(),
  });
};

//Update Language
const updateLanguage = (languageId: any, careerLanguage: any) => {
  return Api().put(
    `/api/coach/updateCoachLanguage/${languageId}`,
    careerLanguage,
    {
      headers: authHeader(),
    }
  );
};

const deleteLanguage = (languageId: any) => {
  return Api().delete(`/api/coach/deleteCoachLanguages/${languageId}`, {
    headers: authHeader(),
  });
};

const uploadDocument = async (coachId: string, file: any, type: string) => {
  const link = await Api().get(
    `/api/generateURLForUpload/${type}/${coachId}/${file.name}`,
    {
      headers: authHeader(),
    }
  );

  await axios.put(link.data, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  if (type === "resume") {
    return saveResume({ docName: file.name, isDefault: false });
  }

  if (type === "cover_letter") {
    return saveCoverLetter({ docName: file.name, isDefault: false });
  }

  if (type === "profile_picture") {
    return updateProfilePicture(file.name);
  }
};

const getDocumentLink = async (
  coachId: string,
  fileName: any,
  type: string
) => {
  const link = await Api().get(
    `/api/generateURLForDownload/${type}/${coachId}/${fileName}`,
    {
      headers: authHeader(),
    }
  );
  return link.data;
};

const downloadDocument = async (
  coachId: string,
  fileName: any,
  type: string
) => {
  const link = await Api().get(
    `/api/generateURLForDownload/${type}/${coachId}/${fileName}`,
    {
      headers: authHeader(),
    }
  );
  return await axios.get(link.data, { responseType: "blob" });
};

const deleteDocument = async (coachId: string, fileName: any, type: string) => {
  const link = await Api().get(
    `/api/generateURLForDelete/${type}/${coachId}/${fileName}`,
    {
      headers: authHeader(),
    }
  );
  return await axios.delete(link.data);
};

const updateProfilePicture = async (fileName: any) => {
  return Api().put("/api/coach/updateProfilePicture", fileName, {
    headers: authHeader(),
  });
};

//Saves resume
//Pass the following object
//     {
//         docName: "",
//         isDefault: "",
//     }
const saveResume = (resume: any) => {
  return Api().post("/api/coachResume/register", resume, {
    headers: authHeader(),
  });
};

const getAllResume = () => {
  return Api().get("/api/coachResume/getAll", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getResumeForCoach = (coachId: string) => {
  return Api().get(`/api/coachResume/getResume/${coachId}`, {
    headers: authHeader(),
  });
};

//Updates resume
//Pass the following object
//     {
//         docName: "",
//         isDefault: "",
//     }
const updateResume = (resume: any, resumeId: string | undefined) => {
  return Api().put("/api/coachResume/update/" + resumeId, resume, {
    headers: authHeader(),
  });
};

//Makes resume default
const makeDefaultResume = (resumeId: string | undefined) => {
  return Api().put(`/api/coachResume/makeDefault/${resumeId}`, null, {
    headers: authHeader(),
  });
};

//Deletes resume
const deleteResume = (resumeId: string | undefined) => {
  return Api().delete(`/api/coachResume/delete/${resumeId}`, {
    headers: authHeader(),
  });
};

//Saves cover letter
//Pass the following object
//     {
//         docName: "",
//         isDefault: "",
//     }
const saveCoverLetter = (coverLetter: any) => {
  return Api().post("/api/coachCoverLetter/register", coverLetter, {
    headers: authHeader(),
  });
};

const getAllCoverLetter = () => {
  return Api().get("/api/coachCoverLetter/getAll", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getCoverLetterForCoach = (coachId: string) => {
  return Api().get(`/api/coachCoverLetter/getCoverLetter/${coachId}`, {
    headers: authHeader(),
  });
};

//Updates cover letter
//Pass the following object
//     {
//         docName: "",
//         isDefault: "",
//     }
const updateCoverLetter = (
  coverLetter: any,
  coverLetterId: string | undefined
) => {
  return Api().put(
    "/api/coachCoverLetter/update/" + coverLetterId,
    coverLetter,
    {
      headers: authHeader(),
    }
  );
};

//Makes cover letter default
const makeDefaultCoverLetter = (coverLetterId: string | undefined) => {
  return Api().put(`/api/coachCoverLetter/makeDefault/${coverLetterId}`, null, {
    headers: authHeader(),
  });
};

//Deletes cover letter
const deleteCoverLetter = (coverLetterId: string | undefined) => {
  return Api().delete("/api/coachCoverLetter/delete/" + coverLetterId, {
    headers: authHeader(),
  });
};

const getAllAppliedJobPost = () => {
  return Api().get("/api/coach/getAllJobPosts", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getAppliedJobPost = (id: string | undefined) => {
  return Api().get(`/api/coach/getJobPost/${id}`, {
    headers: authHeader(),
  });
};

const getProfileCompletionPercent = () => {
  return Api().get("/api/coach/getProfileCompletionPercent", {
    headers: authHeader(),
  });
};

const CoachService = {
  getLoggedInCoachProfile,
  getAllCoaches,
  getCoachById,
  getCoachProfile,

  //Personal Summary
  updatePersonalSummary,

  //Goal
  addGoals,
  getCoachGoals,
  updateGoals,
  updateGoal,
  deleteGoal,

  //Value
  addValues,
  getCoachValues,
  updateValues,
  updateValue,
  deleteValue,

  //Career History
  addCareerHistory,
  getAllCareerHistory,
  updateCareerHistories,
  updateCareerHistory,
  deleteCareerHistory,

  //Certification
  addCertifications,
  getCertifications,
  updateCertifications,
  updateCertification,
  deleteCertification,

  //Skill
  addSkills,
  getCoachSkills,
  updateSkills,
  updateSkill,
  deleteSkill,

  //Language
  addLanguage,
  getCoachLanguages,
  updateLanguages,
  updateLanguage,
  deleteLanguage,

  //Upload Document
  uploadDocument,
  //Get Document Link
  getDocumentLink,
  //Download Document
  downloadDocument,
  //Delete Document
  deleteDocument,

  //Update Profile Picture
  updateProfilePicture,

  //Resume
  saveResume,
  getAllResume,
  getResumeForCoach,
  updateResume,
  makeDefaultResume,
  deleteResume,

  //Cover Letter
  saveCoverLetter,
  getAllCoverLetter,
  getCoverLetterForCoach,
  updateCoverLetter,
  makeDefaultCoverLetter,
  deleteCoverLetter,

  getAllAppliedJobPost,
  getAppliedJobPost,

  getProfileCompletionPercent,
};

export default CoachService;
