import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

const Unauthorized = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-[calc(100vh-364px)] flex justify-center items-center py-16">
      <div className="text-center">
        <p className="text-9xl font-[900] tracking-widest text-zinc-400">401</p>
        <p className="text-3xl font-[500] mt-8">Unauthorized - Access denied</p>
        <p className="text-lg font-[400] mt-6">
          Please login to access this page
        </p>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, width: "10rem" }}
          className="xxs:text-xl  xs:text-2xl"
          onClick={() => navigate(-1)}
        >
          BACK
        </Button>
      </div>
    </div>
  );
};

export default Unauthorized;
