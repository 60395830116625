import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";

import StatusCard from "../../../components/status-card/StatusCard";
import Table from "../../../components/table/Table";
import statusCards from "../../../assets/JsonData/coach-status-card-data.json";
import { TableHead, TableBody } from "../../../types/types";
import { RootState } from "../../../store/index";
import JobService from "../../../services/JobService";
import DashBoardCard from "../../../components/card/DashBoardCard";

import "react-circular-progressbar/dist/styles.css";

const jobStatus = {
  Recruiting: "primary",
  Shortlisting: "warning",
  Recruited: "success",
  Nothired: "danger",
};

const JobTableHead = ["role", "Organization", "start date", "end date"];

const renderJobTableHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const Dashboard = () => {
  const themeReducer = useSelector((state: RootState) => state.mode.theme);

  const profileCompletion = useSelector(
    (state: RootState) => state.profileCompletion.profileCompletion
  );

  const [latestJobs, setLatestJobs] = useState<any>();

  const renderJobBody = (item: TableBody, index: number) => (
    <tr key={index}>
      <td>{item.role}</td>
      <td>{item.orgName}</td>
      <td>{moment(item.dateStart).format("DD/MM/YYYY")}</td>
      <td>{moment(item.dateEnd).format("DD/MM/YYYY")}</td>
    </tr>
  );

  const getLatestJobs = async () => {
    const allLatestJobs = await JobService.getLatestJobsForCoach();
    setLatestJobs(allLatestJobs.data);
  };

  useEffect(() => {
    getLatestJobs();
  }, []);

  return (
    <div>
      {/* <h2 className="page-header">Dashboard</h2> */}
      <div className="row flex items-stretch">
        {statusCards.map((item, index) => (
          <div className="col-3 mb-7" key={index}>
            <StatusCard
              icon={item.icon}
              count={item.count}
              title={item.title}
              link={item.link}
            />
          </div>
        ))}
        <div className="col-3 mb-7">
          <Link to="/user/coachprofile">
            <div className="status-card h-full">
              <div className="h-full flex items-center justify-between">
                <div className="status-card__icon">
                  <CircularProgressbar
                    value={profileCompletion}
                    text={`${profileCompletion}%`}
                    strokeWidth={10}
                  ></CircularProgressbar>
                </div>
                <div className="status-card__info">
                  {/* <p>{`${String(profileCompletion)}%`}</p> */}
                  <span>Profile Completion</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <DashBoardCard
        body={
          <React.Fragment>
            <div className="card__header">
              <h3>latest jobs</h3>
            </div>
            <div className="card__body mt-6">
              <Table
                headData={JobTableHead}
                renderHead={(item: TableHead, index: number) =>
                  renderJobTableHead(item, index)
                }
                bodyData={latestJobs}
                renderBody={(item: TableBody, index: number) =>
                  renderJobBody(item, index)
                }
              />
            </div>
            <div className="card__footer">
              <Link to="/user/searchjobs">view all</Link>
            </div>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default Dashboard;
