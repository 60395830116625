import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { snackBarAction } from "../../../store/features/snackBarSlice";
import Table from "../../../components/table/Table";
import { TableHead, TableBody } from "../../../types/types";
import UserService from "../../../services/UserService";
import DashBoardCard from "../../../components/card/DashBoardCard";
import TransitionsModal from "../../../components/modal/TransitionsModal";
import TierService from "../../../services/TierService";
import jobPostPlanService from "../../../services/JobPostPlanService";

import camelCase from "camelcase";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  TextField,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";

import "./Organizations.css";

const OrganizationTableHead = [
  "name",
  "ABN",
  "type",
  "plan",
  "status",
  "remaining post",
];
const attrTableHead = ["companyName", "abn", "orgType"];

const renderHead = (item: TableHead, index: number) => (
  <th key={index}>{item}</th>
);

const Organizations = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchBarValue, setSearchBarValue] = useState<string>("");
  const [orgChosenTableHead, setOrgChosenTableHead] =
    useState<string>("companyName");
  const [allOrganizations, setAllOrganizations] = useState<any>();
  const [organizations, setOrganizations] = useState<any>();
  const [element, setElement] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [org, setOrg] = useState<any>();
  const [tier, setTier] = useState<string>("");
  const [tierOptions, setTierOptions] = useState<any>("");
  const [plan, setPlan] = useState<any>("");
  const [openPlan, setOpenPlan] = useState<boolean>(false);
  const [orgJobPostPlans, setOrgJobPostPlans] = useState<any>();

  const handleOpen = (org: any) => {
    setOrg(org);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setTier(event.target.value);
  };

  const handleOpenPlan = (org: any) => {
    setOrg(org);
    setPlan(
      org.orgJobPostPlans.find(
        (item: any) => item.orgJobPostPlanStatus === "APPROVED"
      ).jobPostPlan.uuid
    );
    setOpenPlan(true);
  };
  const handleClosePlan = () => {
    setOpenPlan(false);
  };

  const handleChangePlan = (event: SelectChangeEvent) => {
    setPlan(event.target.value);
  };

  // Navitage to View Organization Page and pass organization id
  const clickHandler = (organizationId: string | number | undefined) => {
    if (element.textContent === "Add") {
      return;
    }
    if (element.classList.contains("edit-left-post")) {
      return;
    }

    if (element.classList.contains("edit-plan")) {
      return;
    }

    navigate(`/admin/organization/${organizationId}`);
  };

  const addHandler = async () => {
    if (tier === "") {
      return;
    }

    const plan = {
      jobPostPlanID: tier,
      orgID: org.uuid,
      orgJobPostPlanStatus: "APPROVED",
    };

    try {
      await jobPostPlanService.addOrgJobPostPlan(plan);

      getOrganizations();
      handleClose();
      dispatch(
        snackBarAction.open({
          message: "Plan has successfully added",
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const savePlanHandler = async () => {
    if (plan === "") {
      return;
    }

    try {
      await jobPostPlanService.changeOrgJobPostPlan(org.uuid, plan);

      getOrganizations();
      OrganizationsAndJobPostPlans();
      handleClosePlan();
      dispatch(
        snackBarAction.open({
          message: "Plan has successfully changed",
          severity: "success",
        })
      );
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  const renderBody = (item: TableBody, index: number) => (
    <tr
      key={index}
      onClick={() => clickHandler(item.uuid)}
      className={`${item.companyName && "tr-organization"}`}
    >
      <td>{item.companyName}</td>
      <td>{item.abn}</td>
      <td>
        {item.orgType === "PRIVATE"
          ? "Private Company"
          : item.orgType === "PUBLIC"
          ? "Public Company"
          : item.orgType === "VOLUNTEER" && "Volunteer"}
      </td>
      <td>
        {item.orgJobPostPlans.length > 0 ? (
          <div className="flex justify-between items-center">
            {item.orgJobPostPlans[0].jobPostPlan.type}
            <button
              className="flex justify-center items-center border rounded-full drop-shadow hover:drop-shadow-lg edit-plan"
              onClick={() => handleOpenPlan(item)}
            >
              <i className="bx bxs-pencil pointer-events-none"></i>
            </button>
          </div>
        ) : (
          <button
            className="pl-1 pr-2 rounded-lg flex items-center drop-shadow hover:drop-shadow-lg add-plan"
            onClick={() => handleOpen(item)}
          >
            <i className="bx bx-plus pointer-events-none"></i>Add
          </button>
        )}
      </td>
      {item.orgJobPostPlans.length > 0 ? (
        <td>{camelCase(item.orgJobPostPlans[0].orgJobPostPlanStatus)}</td>
      ) : (
        <td>N/A</td>
      )}

      <td className="pl-10">
        {orgJobPostPlans.find(
          (orgWithplan: any) => orgWithplan.organization.uuid === item.uuid
        )
          ? orgJobPostPlans.find(
              (orgWithplan: any) => orgWithplan.organization.uuid === item.uuid
            ).remainingPost
          : "0"}
      </td>
    </tr>
  );

  const handleChangeChosenTableHead = (event: SelectChangeEvent) => {
    setOrgChosenTableHead(event.target.value);
  };

  const handleOnChangeSearchBar = (event: any) => {
    setSearchBarValue(event.target.value);
  };

  const handleOnClickSearch = () => {
    setOrganizations(
      allOrganizations.filter((org: any) =>
        org[orgChosenTableHead].includes(searchBarValue)
      )
    );
  };

  const getOrganizations = async () => {
    const response = await UserService.getAllOrgUsers();
    setOrganizations(response.data);
    setAllOrganizations(response.data);
    setOrgChosenTableHead("companyName");
  };

  const getTierOptions = async () => {
    const response = await TierService.getActiveTiers();
    setTierOptions(response.data);
  };

  const OrganizationsAndJobPostPlans = async () => {
    const response = await UserService.getAllOrgJobPostPlans();

    setOrgJobPostPlans(response.data);
  };

  useEffect(() => {
    getOrganizations();
    getTierOptions();
    OrganizationsAndJobPostPlans();
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      setElement(e.target);
    });
  });

  return (
    organizations &&
    orgJobPostPlans && (
      <div>
        <h2 className="page-header">Organizations</h2>
        <div className="row">
          <div className="col-12">
            <DashBoardCard
              body={
                <div>
                  <div className="search">
                    <TextField
                      autoFocus
                      id="outlined-search"
                      label="Search field"
                      type="search"
                      sx={{ border: 0, width: "50%" }}
                      onChange={handleOnChangeSearchBar}
                    />
                    <FormControl sx={{ width: "25%" }}>
                      <InputLabel id="search-by-label">Search By</InputLabel>
                      <Select
                        labelId="search-by-label"
                        id="search-by"
                        value={orgChosenTableHead}
                        label="Search By"
                        onChange={handleChangeChosenTableHead}
                      >
                        {attrTableHead.map((attr, index) => (
                          <MenuItem value={attr} key={index}>
                            {attr}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button variant="contained" onClick={handleOnClickSearch}>
                      Search
                    </Button>
                  </div>
                </div>
              }
            />
            <DashBoardCard
              body={
                <Table
                  limit="10"
                  headData={OrganizationTableHead}
                  renderHead={(item, index) => renderHead(item, index)}
                  bodyData={organizations}
                  renderBody={(item, index) => renderBody(item, index)}
                />
              }
            />
          </div>
        </div>
        {org && open ? (
          <TransitionsModal
            open={open}
            handleClose={handleClose}
            children={
              <React.Fragment>
                <Typography
                  id="transition-modal-select-tier"
                  variant="h6"
                  component="h2"
                >
                  {org.companyName}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 1 }}>
                  Please select a tier for the organization
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="select-tier-label">Tier</InputLabel>
                  <Select
                    labelId="select-tier-label"
                    id="select-tier"
                    value={tier}
                    label="Tier"
                    placeholder="Please"
                    onChange={handleChange}
                    className="mb-4"
                  >
                    {tierOptions.map((tierOption: any, index: number) => (
                      <MenuItem key={index} value={tierOption.uuid}>
                        {tierOption.type}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button
                    variant="contained"
                    onClick={addHandler}
                    className="set-tier-button"
                  >
                    Add
                  </Button>
                </FormControl>
              </React.Fragment>
            }
          ></TransitionsModal>
        ) : org && openPlan ? (
          <TransitionsModal
            open={openPlan}
            handleClose={handleClosePlan}
            children={
              <React.Fragment>
                <Typography
                  id="transition-modal-select-tier"
                  variant="h6"
                  component="h2"
                >
                  {org.companyName}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2, mb: 1 }}>
                  Please select a tier for the organization
                </Typography>
                <FormControl fullWidth>
                  <InputLabel id="select-tier-label">Tier</InputLabel>
                  <Select
                    labelId="select-tier-label"
                    id="select-tier"
                    value={plan}
                    label="Tier"
                    placeholder="Please"
                    onChange={handleChangePlan}
                    className="mb-4"
                  >
                    {tierOptions.map((tierOption: any, index: number) => (
                      <MenuItem key={index} value={tierOption.uuid}>
                        {tierOption.type}
                      </MenuItem>
                    ))}
                  </Select>
                  <Button
                    variant="contained"
                    onClick={savePlanHandler}
                    className="set-tier-button"
                  >
                    Save
                  </Button>
                </FormControl>
              </React.Fragment>
            }
          ></TransitionsModal>
        ) : (
          ""
        )}
      </div>
    )
  );
};

export default Organizations;
