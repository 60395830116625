import React, { useState } from "react";

import UserProfileCard from "../../../../../components/card/UserProfileCard";
import ChangePassword from "../../../Components/Account/LoginInfomation/ChangePassword";
import ChangeEmail from "../../../Components/Account/LoginInfomation/ChangeEmail";

import { Button } from "@mui/material";

const LoginInformation = (props: any) => {
  const { data } = props;

  const [isShown, setIsShown] = useState(false);

  const handleClick = () => {
    setIsShown(!isShown);
  };

  return (
    <UserProfileCard
      title={
        <React.Fragment>
          <span className="card-heading">Login Information</span>
          <Button type="submit" onClick={handleClick}>
            <i className="bx bxs-edit"></i>
          </Button>
        </React.Fragment>
      }
      body={
        <React.Fragment>
          <div className="flex">
            <i className="bx bxs-key login_key"></i>
            <p>Change Password</p>
          </div>
          <div className="flex mt-2">
            <i className="bx bxs-key login_key"></i>
            <p>Change Email Address</p>
          </div>
        </React.Fragment>
      }
      body_2={
        <React.Fragment>
          {isShown && (
            <div className="c_card mb-5">
              <div className="card-body">
                <ChangePassword user={data} handleClick={handleClick} />
                <hr className="mt-6" />
                <ChangeEmail handleClick={handleClick} />
              </div>
            </div>
          )}
        </React.Fragment>
      }
    />
  );
};

export default LoginInformation;
