import React from "react";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import UserService from "../../../../../services/UserService";
import { snackBarAction } from "../../../../../store/features/snackBarSlice";

import { Button, TextField } from "@mui/material";

const schema = yup.object({
  oldPassword: yup.string().required("Required field"),
  newPassword: yup
    .string()
    .required("Required field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirmNewPassword: yup
    .string()
    .required("Required field")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

type FormData = yup.InferType<typeof schema>;

const ChangePassword = (props: any) => {
  const { user, handleClick } = props;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const handleHide = () => {
    handleClick();
    reset();
  };

  const handleUpdateUserPassword = handleSubmit(async (data) => {
    try {
      await UserService.updateUserPassword(user.uuid, {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      reset();
      handleHide();
      dispatch(
        snackBarAction.open({
          message: "Login infomation has been successfully updated",
          severity: "success",
        })
      );
    } catch (e: any) {
      if (e.response.status === 403) {
        // const message = Object.values(e.response.data)[0] as string;
        const message = "Current password does not match";
        dispatch(
          snackBarAction.open({
            // message: message.substring(message.indexOf(":") + 1),
            message: message,
            severity: "error",
          })
        );
      } else {
        if (e instanceof Error) {
          dispatch(
            snackBarAction.open({
              message: e.message,
              severity: "error",
            })
          );
        }
      }
    }
  });

  return (
    <React.Fragment>
      <div className="text-lg font-semibold">Change Password</div>
      <form
        className="w-full"
        onSubmit={(e: any) => handleUpdateUserPassword(e)}
      >
        <TextField
          type="password"
          margin="normal"
          fullWidth
          id="current-password"
          label="Current Password"
          autoComplete="off"
          {...register("oldPassword")}
        />
        {errors.oldPassword && (
          <p className="text-red-700 mb-2">* {errors.oldPassword?.message}</p>
        )}
        <TextField
          type="password"
          margin="normal"
          fullWidth
          id="new-password"
          label="New Password"
          autoComplete="off"
          {...register("newPassword")}
        />
        {errors.newPassword && (
          <p className="text-red-700 mb-2">* {errors.newPassword?.message}</p>
        )}
        <TextField
          type="password"
          margin="normal"
          fullWidth
          id="confirm-new-password"
          label="Confirm New Password"
          autoComplete="off"
          className="!mt-0"
          {...register("confirmNewPassword")}
        />
        {errors.confirmNewPassword && (
          <p className="text-red-700 mb-2">
            * {errors.confirmNewPassword?.message}
          </p>
        )}
        <div>
          <Button type="submit" className="profile_button">
            Save
          </Button>
          <Button onClick={handleHide} className="profile_button">
            Cancel
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default ChangePassword;
