import React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import "./TransitionsModal.css";

type Props = {
  open: boolean;
  handleClose: () => void;
  children: any;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 24,
  px: 4,
  py: 3,
};

const TransitionsModal = (props: Props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-select-tier"
      aria-describedby="transition-modal-description-select-tier"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box sx={style}>{props.children}</Box>
      </Fade>
    </Modal>
  );
};

export default TransitionsModal;
