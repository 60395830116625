import React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

export default function Review(props: any) {
  const products = [
    {
      name: "Role",
      desc: props.role.role,
    },
    {
      name: "Work Type",
      desc: props.role.roletype.toString().split(",").join(", "),
    },
    {
      name: "Minimum qualification",
      desc: props.session.minimumqualification,
    },
    {
      name: "Session days",
      desc: props.session.trainingDayandTime,
    },
    {
      name: "Position fill date",
      desc: props.postDate.filldate,
    },
    {
      name: "Position go live date",
      desc: props.postDate.startdate,
    },
  ];
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Job Post Summary
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={product.name} secondary={product.desc} />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
}
