import CloseIcon from "@mui/icons-material/Close";

const Tag = (props: any) => {
  const { clickHandler, tag, index } = props;
  return (
    <div className="tag-item">
      <span className="text">{tag}</span>
      <span className="close" onClick={() => clickHandler(index)}>
        <CloseIcon sx={{ fontSize: "12px" }} />
      </span>
    </div>
  );
};

export default Tag;
