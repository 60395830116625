import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import CoachService from "../../../../services/CoachService";
import { snackBarAction } from "../../../../store/features/snackBarSlice";
import UserProfileCard from "../../../../components/card/UserProfileCard";

const schema = yup.object({
  personalSummary: yup.string().trim().required("Required field"),
});

type FormData = yup.InferType<typeof schema>;

const PersonalSummary = (props: any) => {
  const dispatch = useDispatch();

  const { coach, getCoach, getProfileCompletionPercent } = props;

  const {
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  // show or hide summary textfield
  const [isShown, setIsShown] = useState<boolean>(false);
  const [personalSummary, setPersonalSummary] = useState<string>(
    coach.profileSummary
  );

  const handleClick = () => {
    setIsShown(!isShown);
    setPersonalSummary(coach.profileSummary);
    reset();
    setValue("personalSummary", coach.profileSummary ? coach.profileSummary : "");
  };

  const handleChange = (e: any, editor: any) => {
    var data = editor.getData();
    setPersonalSummary(data);
    setValue("personalSummary", data);
    trigger();
  };

  const handleSaveSummary = async () => {
    const result = await trigger(["personalSummary"]);
    if (!result) {
      return;
    }

    try {
      await CoachService.updatePersonalSummary(
        personalSummary.replace(/<[^>]+>/g, "")
      );
      getCoach();
      handleClick();
      if (coach.profileSummary) {
        dispatch(
          snackBarAction.open({
            message: "Personal Summary has been successfully updated",
            severity: "success",
          })
        );
      } else {
        dispatch(
          snackBarAction.open({
            message: "Personal Summary has been successfully added",
            severity: "success",
          })
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        dispatch(
          snackBarAction.open({
            message: e.message,
            severity: "error",
          })
        );
      }
    }
  };

  useEffect(() => {
    setPersonalSummary(coach.profileSummary);
    getProfileCompletionPercent();
  }, [coach]);

  return (
    <UserProfileCard
      title={
        <React.Fragment>
          <span className="card-heading">Personal Summary</span>
          {personalSummary && (
            <Button onClick={handleClick}>
              <i className="bx bxs-edit"></i>
            </Button>
          )}
        </React.Fragment>
      }
      body={
        <React.Fragment>
          {!personalSummary && (
            <p className="mb-2 mt-2">
              Add a personal summary to your profile as a way to introduce who
              you are
            </p>
          )}
          {personalSummary && (
            <p className="mb-2 mt-2">
              {personalSummary.replace(/<[^>]+>/g, "")}
            </p>
          )}
          <div className="mb-2">
            {isShown && (
              <React.Fragment>
                <CKEditor
                  editor={ClassicEditor}
                  name="personalSummary"
                  data={personalSummary}
                  onChange={(e: any, editor: any) => handleChange(e, editor)}
                />
                {errors.personalSummary && (
                  <p className="text-red-700">
                    * {errors.personalSummary.message}
                  </p>
                )}
              </React.Fragment>
            )}
          </div>
          <div>
            {!isShown ? (
              <React.Fragment>
                {!personalSummary && (
                  <Button onClick={handleClick} className="profile_button">
                    Add Summary
                  </Button>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button className="profile_button" onClick={handleSaveSummary}>
                  Save
                </Button>
                <Button onClick={handleClick} className="profile_button">
                  Cancel
                </Button>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      }
    />
  );
};

export default PersonalSummary;
