import React, { useEffect, useState } from "react";

import PersonalSummary from "./PersonalSummary";
import CareerHistory from "./CareerHistory";
import Certifications from "./Certifications";
import CoachService from "../../../../services/CoachService";
import ProfilePicture from "../../Components/Profile/ProfilePicture";
import ProfileDetailCard from "../../Components/Profile/ProfileDetailCard";
import ProfileDocument from "../../Components/Profile/ProfileDocument";

const CoachDetails = (props: any) => {
  const { getProfileCompletionPercent } = props;

  const [coach, setCoach] = useState<any>();

  const getCoach = async () => {
    const response = await CoachService.getLoggedInCoachProfile();
    setCoach(response.data);
  };

  useEffect(() => {
    getCoach();
  }, []);

  return (
    coach && (
      <React.Fragment>
        <PersonalSummary
          coach={coach}
          getCoach={getCoach}
          getProfileCompletionPercent={getProfileCompletionPercent}
        />
        <ProfilePicture
          sectionName="Profile Picture"
          data={coach}
          subData="profilePicture"
          getData={getCoach}
          getProfileCompletionPercent={getProfileCompletionPercent}
          fileType="profile_picture"
          uploadPicture={CoachService.uploadDocument}
          downloadPicture={CoachService.downloadDocument}
          deletePicture={CoachService.deleteDocument}
          getPictureLink={CoachService.getDocumentLink}
        />
        <ProfileDetailCard
          sectionName="goal"
          add={CoachService.addGoals}
          get={CoachService.getCoachGoals}
          update={CoachService.updateGoals}
          updateOne={CoachService.updateGoal}
          remove={CoachService.deleteGoal}
          getProfileCompletionPercent={getProfileCompletionPercent}
        />
        <ProfileDetailCard
          sectionName="value"
          add={CoachService.addValues}
          get={CoachService.getCoachValues}
          update={CoachService.updateValues}
          updateOne={CoachService.updateValue}
          remove={CoachService.deleteValue}
          getProfileCompletionPercent={getProfileCompletionPercent}
        />
        <CareerHistory />
        <Certifications />
        <ProfileDetailCard
          sectionName="skill"
          add={CoachService.addSkills}
          get={CoachService.getCoachSkills}
          update={CoachService.updateSkills}
          updateOne={CoachService.updateSkill}
          remove={CoachService.deleteSkill}
          getProfileCompletionPercent={getProfileCompletionPercent}
        />
        <ProfileDetailCard
          sectionName="language"
          add={CoachService.addLanguage}
          get={CoachService.getCoachLanguages}
          update={CoachService.updateLanguages}
          updateOne={CoachService.updateLanguage}
          remove={CoachService.deleteLanguage}
          getProfileCompletionPercent={getProfileCompletionPercent}
        />
        <ProfileDocument
          sectionName="Resume"
          data={coach}
          getDocument={CoachService.getResumeForCoach}
          getProfileCompletionPercent={getProfileCompletionPercent}
          fileType="resume"
          uploadDocument={CoachService.uploadDocument}
          downloadDocument={CoachService.downloadDocument}
          deleteFile={CoachService.deleteResume}
          deleteDocument={CoachService.deleteDocument}
          makeDefaultDocument={CoachService.makeDefaultResume}
        />
        <ProfileDocument
          sectionName="Cover Letter"
          data={coach}
          getDocument={CoachService.getCoverLetterForCoach}
          getProfileCompletionPercent={getProfileCompletionPercent}
          fileType="cover_letter"
          uploadDocument={CoachService.uploadDocument}
          downloadDocument={CoachService.downloadDocument}
          deleteFile={CoachService.deleteCoverLetter}
          deleteDocument={CoachService.deleteDocument}
          makeDefaultDocument={CoachService.makeDefaultCoverLetter}
        />
      </React.Fragment>
    )
  );
};

export default CoachDetails;
