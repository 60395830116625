import Api from "./Api";
import authHeader from "./AuthHeader";

type Email = {
  email: string;
};

const getUserDetails = (email: Email) => {
  return Api().post(
    "/api/user/getDetailsByEmail",
    { username: email },
    {
      headers: authHeader(),
    }
  );
};

const getAllOrgUsers = () => {
  return Api().get(`/api/org/getAll`, {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const getOrgUserhById = (organizationId: string | undefined) => {
  return Api().get(`/api/org/profile/${organizationId}`, {
    headers: authHeader(),
  });
};

const getAllOrgJobPostPlans = () => {
  return Api().get("/api/org/getAllOrgJobPostPlans", {
    headers: authHeader(),
    params: {
      page: 0,
      size: 10,
    },
  });
};

const updateUserName = (userFullName: any) => {
  return Api().put(`/api/user/updateName`, userFullName, {
    headers: authHeader(),
  });
};

const updateUserPassword = (userId: any, userPassword: any) => {
  return Api().post(`/api/user/updatePassword/${userId}`, userPassword, {
    headers: authHeader(),
  });
};
const updateUserEmail = (userEmail: any) => {
  return Api().put(`/api/user/updateEmail`, userEmail, {
    headers: authHeader(),
  });
};

const updateUserMobileNumber = (userMobileNumber: any) => {
  return Api().put(`/api/user/updateMobileNumber`, userMobileNumber, {
    headers: authHeader(),
  });
};

const UserService = {
  getUserDetails,
  getAllOrgUsers,
  getOrgUserhById,
  getAllOrgJobPostPlans,
  updateUserName,
  updateUserPassword,
  updateUserEmail,
  updateUserMobileNumber,
};

export default UserService;
