import React, { useEffect, useState } from "react";

import { Add, Check } from "@mui/icons-material";
import {
  Fab,
  InputLabel,
  Grid,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { Box } from "@mui/system";

import SportService from "../../../../services/SportService";
import TagsInput from "../../../../components/TagsInput";

import "./RoleDescription.css";

type Role = {
  role: string;
  values: string;
  philisophies: string;
  roletype: string[];
  salaryrange: string;
  city: string;
  state: string;
  moreinfo: string;
};

type Props = {
  setRole: React.Dispatch<React.SetStateAction<Role>>;
  role: Role;
  register: any;
  errors: any;
};

type Sport = {
  value: string;
  label: string;
};

const salaryTypeOptions = [
  { name: "Hourly", value: "HOURLY" },
  { name: "Weekly", value: "WEEKLY" },
  { name: "Monthly", value: "MONTHLY" },
  { name: "Annual", value: "ANNUAL" },
];

const RoleDescription = (props: any) => {
  const { role, setRole, register, setValue, errors, trigger } = props;

  const [sportOptions, setSportOptions] = useState<any>([]);

  const handleChange = (e: any) => {
    const value = e.target.value;

    if (e.target.name === "salarymin") {
      if (value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
        setRole({
          ...role,
          salarymin: value,
        });
        setValue(e.target.name, value);
      }
    } else if (e.target.name === "salarymax") {
      if (value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
        setRole({
          ...role,
          salarymax: value,
        });
        setValue(e.target.name, value);
      }
    } else {
      setRole({
        ...role,
        [e.target.name]: value,
      });
      setValue(e.target.name, value);
    }
    trigger(e.target.name);
  };

  const blurHandler = (e: any) => {
    let value = e.target.value;

    if (e.target.name === "salarymin") {
      if (role.salarymax === "" || role.salarymax === "0.00") {
        setRole({
          ...role,
          salarymin: value !== "" ? parseFloat(value).toFixed(2) : "0.00",
          salarymax: value !== "" ? parseFloat(value).toFixed(2) : "0.00",
        });
        setValue(
          "salarymax",
          value !== "" ? parseFloat(value).toFixed(2) : "0.00"
        );
        setValue(
          "salarymin",
          value !== "" ? parseFloat(value).toFixed(2) : "0.00"
        );
        trigger(["salarymin", "salarymax"]);

        return;
      }

      if (Number(value) > Number(role.salarymax)) {
        setRole({
          ...role,
          salarymin: role.salarymax,
        });
        setValue("salarymin", role.salarymax);
        return;
      }

      setRole({
        ...role,
        salarymin: value !== "" ? parseFloat(value).toFixed(2) : 0.0,
      });
      setValue("salarymin", value !== "" ? parseFloat(value).toFixed(2) : 0.0);
    } else if (e.target.name === "salarymax") {
      if (role.salarymin === "") {
        setRole({
          ...role,
          salarymin: "0.00",
          salarymax: value !== "" ? parseFloat(value).toFixed(2) : "0.00",
        });
        setValue("salarymin", "0.00");
        setValue(
          "salarymax",
          value !== "" ? parseFloat(value).toFixed(2) : "0.00"
        );
        trigger(["salarymin", "salarymax"]);
        return;
      }

      if (Number(value) < Number(role.salarymin)) {
        setRole({
          ...role,
          salarymax: role.salarymin,
        });
        setValue("salarymax", role.salarymin);
        return;
      }

      setRole({
        ...role,
        salarymax: value !== "" ? parseFloat(value).toFixed(2) : "0.00",
      });
      setValue(
        "salarymax",
        value !== "" ? parseFloat(value).toFixed(2) : "0.00"
      );
    }
  };

  const clickHandler = (e: any) => {
    const value = e.target.value;

    if (role.roletype === value) {
      setRole({
        ...role,
        roletype: "",
      });
      setValue("roletype", "");
      trigger("roletype");
      return;
    }

    setRole({
      ...role,
      roletype: value,
    });
    setValue("roletype", value);
    trigger("roletype");
  };

  const getSportOptions = async () => {
    const response = await SportService.getActiveSports();
    setSportOptions(response.data);
  };

  useEffect(() => {
    getSportOptions();
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id="role"
            label="Role"
            fullWidth
            autoComplete="role"
            variant="standard"
            {...register("role")}
            onChange={handleChange}
          />
          {errors.role && (
            <p className="text-red-700">* {errors.role.message}</p>
          )}
        </Grid>
        <Grid item xs={12}>
          <TagsInput
            data={role}
            setData={setRole}
            register={register}
            setValue={setValue}
            errors={errors}
            dataKey="values"
            trigger={trigger}
          />
        </Grid>
        <Grid item xs={12}>
          <TagsInput
            data={role}
            setData={setRole}
            register={register}
            setValue={setValue}
            errors={errors}
            dataKey="philisophies"
            trigger={trigger}
          />
        </Grid>
        <Grid item xs={12} md={5} className="!pt-6 z-20">
          <FormControl variant="standard" className="w-full">
            <InputLabel id="type-of-sport-label">Type of Sport *</InputLabel>
            {sportOptions.length > 0 && (
              <Select
                labelId="type-of-sport-label"
                id="type-of-sport"
                value={role.sport}
                {...register("sport")}
                onChange={handleChange}
              >
                {sportOptions.map((item: any, index: any) => (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          {errors.sport && (
            <p className="text-red-700">* {errors.sport.message}</p>
          )}
        </Grid>

        <Grid item xs={12} md={12} className="z-10">
          <Typography>Role Type</Typography>

          <Box sx={{ "& > :not(style)": { m: 1 } }} className="roletype-tag ">
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              value="Full Time"
              name="fullTime"
              onClick={clickHandler}
              className={`!ml-0 ${
                role.roletype === "Full Time" &&
                "bg-gradient-to-r from-[#10d4d2] to-[#2ae9e6]"
              } ${
                role.roletype.length > 0 && role.roletype !== "Full Time"
                  ? "tag-disabled"
                  : ""
              }
              `}
            >
              {role.roletype === "Full Time" ? (
                <Check className="pointer-events-none" />
              ) : (
                <Add className="pointer-events-none" />
              )}
              Full Time
            </Fab>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              value="Part Time"
              name="partTime"
              onClick={clickHandler}
              className={`${
                role.roletype === "Part Time" &&
                "bg-gradient-to-r from-[#10d4d2] to-[#2ae9e6]"
              } ${
                role.roletype.length > 0 && role.roletype !== "Part Time"
                  ? "tag-disabled"
                  : ""
              }
              `}
            >
              {role.roletype === "Part Time" ? (
                <Check className="pointer-events-none" />
              ) : (
                <Add className="pointer-events-none" />
              )}
              Part Time
            </Fab>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              value="Casual"
              name="casual"
              onClick={clickHandler}
              className={`${
                role.roletype === "Casual" &&
                "bg-gradient-to-r from-[#10d4d2] to-[#2ae9e6]"
              } ${
                role.roletype.length > 0 && role.roletype !== "Casual"
                  ? "tag-disabled"
                  : ""
              }
              `}
            >
              {role.roletype === "Casual" ? (
                <Check className="pointer-events-none" />
              ) : (
                <Add className="pointer-events-none" />
              )}
              Casual
            </Fab>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              value="Contract"
              name="contract"
              onClick={clickHandler}
              className={`${
                role.roletype === "Contract" &&
                "bg-gradient-to-r from-[#10d4d2] to-[#2ae9e6]"
              } ${
                role.roletype.length > 0 && role.roletype !== "Contract"
                  ? "tag-disabled"
                  : ""
              }
              `}
            >
              {role.roletype === "Contract" ? (
                <Check className="pointer-events-none" />
              ) : (
                <Add className="pointer-events-none" />
              )}
              Contract
            </Fab>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              value="Volunteer"
              name="volunteer"
              onClick={clickHandler}
              className={`!ml-0 ${
                role.roletype === "Volunteer" &&
                "bg-gradient-to-r from-[#10d4d2] to-[#2ae9e6]"
              } ${
                role.roletype.length > 0 && role.roletype !== "Volunteer"
                  ? "tag-disabled"
                  : ""
              }
              `}
            >
              {role.roletype === "Volunteer" ? (
                <Check className="pointer-events-none" />
              ) : (
                <Add className="pointer-events-none" />
              )}
              Volunteer
            </Fab>
          </Box>
          <TextField
            id="roletype"
            label="Role Type"
            fullWidth
            required
            autoComplete="roletype"
            variant="standard"
            {...register("roletype")}
            className="!hidden"
          />
          {errors.roletype && (
            <p className="text-red-700">* {errors.roletype.message}</p>
          )}
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Salary Range *</InputLabel>
        </Grid>
        <div className={`grid  pl-[9px] w-full grid-cols-11`}>
          <div className="col-span-4">
            <TextField
              required
              id="salarymin"
              label="Minimum"
              fullWidth
              autoComplete="salarymin"
              variant="standard"
              {...register("salarymin")}
              onChange={handleChange}
              onBlur={blurHandler}
              value={role.salarymin}
            />
            {errors.salarymin && (
              <p className="text-red-700">* {errors.salarymin.message}</p>
            )}
          </div>
          <div className="col-span-4">
            <TextField
              required
              id="salarymax"
              label="Maximum"
              fullWidth
              autoComplete="salarymax"
              variant="standard"
              {...register("salarymax")}
              onChange={handleChange}
              onBlur={blurHandler}
              value={role.salarymax}
            />
            {errors.salarymax && (
              <p className="text-red-700">* {errors.salarymax.message}</p>
            )}
          </div>
          <div className="col-span-3">
            <FormControl variant="standard" className="w-full">
              <InputLabel id="salary-type-label">Salary Type *</InputLabel>
              <Select
                labelId="salary-type-label"
                id="salary-type"
                value={role.salarytype}
                {...register("salarytype")}
                onChange={handleChange}
              >
                {salaryTypeOptions.map((item: any, index: any) => (
                  <MenuItem key={index} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.salarytype && (
              <p className="text-red-700">* {errors.salarytype.message}</p>
            )}
          </div>
        </div>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            label="City/Suburb"
            fullWidth
            autoComplete="city"
            variant="standard"
            {...register("city")}
            onChange={handleChange}
          />
          {errors.city && (
            <p className="text-red-700">* {errors.city.message}</p>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            required
            variant="standard"
            {...register("state")}
            onChange={handleChange}
          />
          {errors.state && (
            <p className="text-red-700">* {errors.state.message}</p>
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="moreinfo"
            label="More information about the role"
            fullWidth
            multiline
            rows={6}
            autoComplete="moreinfo"
            variant="standard"
            {...register("moreinfo")}
            onChange={handleChange}
          />
          {errors.moreinfo && (
            <p className="text-red-700">* {errors.moreinfo.message}</p>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default RoleDescription;
