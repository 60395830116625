import { createSlice } from "@reduxjs/toolkit";

const profileCompletion = JSON.parse(
  localStorage.getItem("profileCompletion") as string
);

const initialProfileCompletionState = {
  profileCompletion: profileCompletion ? profileCompletion : 0,
};

const profileCompletionSlice = createSlice({
  name: "profileCompletion",
  initialState: initialProfileCompletionState,
  reducers: {
    set(state, action) {
      state.profileCompletion = action.payload;
    },
  },
});

export const profileCompletionAction = profileCompletionSlice.actions;

export default profileCompletionSlice.reducer;
