type AxiosRequestHeaders = Record<string, string | number | boolean>;

//get token from local storage
const authHeader = (): AxiosRequestHeaders => {
  const user = JSON.parse(localStorage.getItem("token") as string);

  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
  } else {
    return {};
  }
};

export default authHeader;
