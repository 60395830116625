import SuperAdminAccountDetails from "./SuperAdminAccountDetails";
import ProfileLayout from "../../../../layout/Profile/ProfileLayout";

const SuperAdminAccount = () => {
  return (
    <ProfileLayout>
      <SuperAdminAccountDetails />
    </ProfileLayout>
  );
};

export default SuperAdminAccount;
