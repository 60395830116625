export function isValidDocumentFileUploaded(file: any) {
  var status = false;
  if(file.type.indexOf("word") !== -1 || file.type.indexOf("pdf") !== -1) {
    status = true;
  }
  return status;
}

export function isValidImageFileUploaded(file: any) {
  const validExtensions = ["png", "jpeg", "jpg"];
  const fileExtension = file.type.split("/")[1];
  return validExtensions.includes(fileExtension);
}

export function isValidDocumentFileSize(file: any) {
  const fileSize = file.size;
  return fileSize < 5000000;
}

export function isValidImageFileSize(file: any) {
  const fileSize = file.size;
  return fileSize < 5000000;
}