import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RootState } from "../../store/index";
import { authAction } from "../../store/features/authSlice";
import { Item } from "../../types/types";
import AuthService from "../../services/AuthenticationService";

import "./dropdown.css";

type Props = {
  contentData: {
    icon: string;
    content: string;
  }[];
  renderItems: (item: Item, index: number) => JSX.Element;
  icon?: string;
  customToggle?: () => JSX.Element;
  badge?: string;
  renderFooter?: () => JSX.Element;
  setSignOutLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Dropdown = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.user);

  const dropdown_content_el = useRef<HTMLDivElement>(null);
  const dropdown_toggle_el = useRef<HTMLButtonElement>(null);

  const signOut = () => {
    if (props.setSignOutLoading) props.setSignOutLoading(true);
    setTimeout(() => {
      AuthService.signout();
      dispatch(authAction.logout());
      if (props.setSignOutLoading) props.setSignOutLoading(false);

      if (user.role === "ROLE_COACH") {
        navigate("/signin");
      } else if (
        user.role === "ROLE_ORGUSER" ||
        user.role === "ROLE_ORGADMIN"
      ) {
        navigate("/org/signin");
      } else if (user.role === "ROLE_SUPER_ADMIN") {
        navigate("/admin/signin");
      }
    }, 750);
  };

  const [element, setElement] = useState<any>();

  useEffect(() => {
    if (element) {
      if (
        dropdown_toggle_el.current &&
        dropdown_toggle_el.current.contains(element as HTMLButtonElement)
      ) {
        if (dropdown_content_el.current) {
          if (dropdown_content_el.current.classList.contains("active")) {
            dropdown_content_el.current.classList.remove("active");
          } else {
            dropdown_content_el.current.classList.toggle("active");
          }
        }
      } else {
        if (dropdown_content_el.current) {
          if (element.textContent === "Logout") {
            signOut();
            setTimeout(() => {
              if (dropdown_content_el.current)
                dropdown_content_el.current.classList.remove("active");
            }, 750);
            return;
          }

          if (user.role === "ROLE_COACH") {
            if (element.textContent === "My Profile") {
              navigate("/user/coachprofile");
            }
            if (element.textContent === "Account Settings") {
              navigate("/user/accountsettings");
            }
          }

          if (user.role === "ROLE_ORGUSER" || user.role === "ROLE_ORGADMIN") {
            if (element.textContent === "Org Profile") {
              navigate("/org/profile");
            }
            if (element.textContent === "Account Settings") {
              navigate("/org/accountsettings");
            }
          }

          if (user.role === "ROLE_SUPER_ADMIN") {
            // if (element.textContent === "Org Profile") {
            //   navigate("/org/profile");
            // }
            if (element.textContent === "Account Settings") {
              navigate("/admin/accountsettings");
            }
          }

          dropdown_content_el.current.classList.remove("active");
        }
      }
    }
  }, [element]);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      setElement(e.target);
    });
  });

  return (
    <div className="dropdown">
      <button
        className="dropdown__toggle"
        // onClick={toggleHandler}
        ref={dropdown_toggle_el}
      >
        {props.icon ? <i className={props.icon}></i> : ""}
        {props.badge ? (
          <span className="dropdown__toggle-badge">{props.badge}</span>
        ) : (
          ""
        )}
        {props.customToggle ? props.customToggle() : ""}
      </button>
      <div className="dropdown__content" ref={dropdown_content_el}>
        {props.contentData && props.renderItems
          ? props.contentData.map((item, index) =>
              props.renderItems(item, index)
            )
          : ""}
        {props.renderFooter ? (
          <div className="dropdown__footer">{props.renderFooter()}</div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Dropdown;
