import { createSlice } from "@reduxjs/toolkit";

// Get token from local storage
const token = JSON.parse(localStorage.getItem("token") as string);
const user = JSON.parse(localStorage.getItem("user") as string);

const initialAuthState = {
  token: token ? token : null,
  user: user ? user : null,
  isAuthenticated: token && user ? true : false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      const { token, user } = action.payload;
      state.token = token;
      state.user = user;
      state.isAuthenticated = true;
    },
    logout(state) {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
    },
    update(state, action) {
      const { user } = action.payload;
      state.user = user;
    },
  },
});

export const authAction = authSlice.actions;

export default authSlice.reducer;
